import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { FaWpforms } from "react-icons/fa";
import { BiError } from "react-icons/bi";
import GenerateInvoiceModal from "./GenerateInvoiceModal";
import ClientsForm from "./ClientsForm";
import SingleEventFormV1 from "./SingleEventFormV1";
import useCEARegPost from "../../../hooks/useCEARegPost";
import CustomMessageModal from "../../../components/shared-comp/CustomMessageModal";
import AddAnotherFormBtn from "../../../components/shared-comp/AddAnotherFormBtn";
import SubmitFormBtn from "../../../components/shared-comp/SubmitFormBtn";
import CashAdvanceCommentsForm from "./CashAdvanceCommentsForm";

export default function AddEventModal({ isFromClient, isEventModalOpen, setEventModalOpen }) {
  const navigate = useNavigate();

  const [eventForm, setEventForm] = useState([
    {
      event_type: null,
      client: null,
      package: null,
      additions: [],
      leads: [],
      date: "",
      venue: "",
      discount: 0,
      video_package: "",
    },
  ]);

  const [addonAndPackagePrice, setAddonAndPackagePrice] = useState([
    {
      package: null,
      additions: [],
    },
  ]);

  const [clientFormData, setClientFormData] = useState();
  const [isClientSelected, setClientSelected] = useState(false);
  const [cash_inflow, setCashInflow] = useState(0);
  const [comment, setComment] = useState("");
  const [ceaReqErr, setCeaReqErr] = useState([]);
  const [ceaReqSuccess, setCeaReqSuccess] = useState([]);
  const [view, setView] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChangeClient = async (name, value) => {
    setClientFormData({ ...clientFormData, [name]: value });
    setView(value.length > 0 ? false : true);
  };

  const handleAddClick = () => {
    const values = [...eventForm];
    values.push({
      event_type: null,
      client: null,
      package: null,
      additions: [],
      leads: [],
      date: "",
      venue: "",
      discount: 0,
      video_package: "",
    });
    setEventForm(values);

    const valuesOfAddonAndPackagePrice = [...addonAndPackagePrice];
    valuesOfAddonAndPackagePrice.push({
      package: 0,
      additions: 0,
    });

    setAddonAndPackagePrice(valuesOfAddonAndPackagePrice);
  };

  const handleDeleteClick = (index) => {
    const values = [...eventForm];
    values.splice(index, 1);
    setEventForm(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...eventForm];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setEventForm(values);
  };

  const handleListsSelect = (index, key, data, selectedAddOns) => {
    const values = [...eventForm];
    const valuesOfAddonAndPackagePrice = [...addonAndPackagePrice];
    key === "client" && (values[index][key] = data ? data.id : null);
    key === "event_type" && (values[index][key] = Object.values(data)[0]);
    if (key === "package") {
      values[index][key] = data ? data.id : null;
      valuesOfAddonAndPackagePrice[index][key] = Number(data?.budget) || 0;
    }
    if (key === "video_package") {
      values[index][key] = data ? data.id : null;
      valuesOfAddonAndPackagePrice[index][key] = Number(data?.budget) || 0;
    }
    if (key === "additions") {
      values[index][key] = data ? data : [];
      valuesOfAddonAndPackagePrice[index][key] = selectedAddOns.reduce((accumulator, item) => accumulator + Number(item.price), 0);
    }
    key === "leads" && (values[index][key] = data ? data : []);
    setEventForm(values);
    setAddonAndPackagePrice(valuesOfAddonAndPackagePrice);
  };

  function getFormattedData(clientFormData, eventForm) {
    let clientData = null;
    let eventData;
    if (eventForm[0]?.client && (clientFormData === undefined || clientFormData.full_name === "" || clientFormData.phone_number === "")) {
      clientData = eventForm[0].client;
      const formatedFormData = {
        client: clientData,
        events: eventForm,
        cash_inflow,
        comment,
      };
      return formatedFormData;
    } else if (clientFormData !== undefined && eventForm[0]?.client === null) {
      clientData = clientFormData.full_name && clientFormData.phone_number ? clientFormData : null;

      const formatedFormData = {
        client: clientData,
        events: eventForm,
        cash_inflow,
        comment,
      };
      return formatedFormData;
    } else if (eventForm[0]?.client !== null && clientFormData !== undefined) {
      clientData = clientFormData.full_name && clientFormData.phone_number ? clientFormData : null;
      eventData = eventForm?.map((event) => {
        return {
          ...event,
          client: null,
        };
      });
      const formatedFormData = {
        client: clientData,
        events: eventData,
        cash_inflow,
        comment,
      };
      return formatedFormData;
    }
  }

  // CEA registration means CLIENT EVENT ADD-ON
  const ceaPostReqResponse = useCEARegPost();

  function isValidForm(clientsFormData, eventsFormData) {
    const hasValidClient =
      (clientsFormData?.full_name?.length > 1 && clientsFormData?.phone_number?.length > 1) || (eventsFormData.length > 0 && eventsFormData[0]?.client !== null);

    return hasValidClient;
  }

  async function handleSaveEvents() {
    if (isValidForm(clientFormData, eventForm)) {
      try {
        const formatedData = getFormattedData(clientFormData, eventForm);
        console.log(formatedData, "formatedData");
        setLoading(true);
        const response = await ceaPostReqResponse(formatedData);
        if (response[1] === "success") {
          setCeaReqSuccess(response);
          setLoading(false);
          setModalOpen(true);
        } else {
          setCeaReqErr(response);
          setLoading(false);
          if (response[0].response?.data === "") {
            setCustomModalMsg("Please fill the clients 'Full Name' or 'Phone Number' properly!");
            setCustomModalOpen(true);
          } else {
            console.log(response);
            setCustomModalMsg(response?.[2]);
            setCustomModalOpen(true);
          }
        }
      } catch (error) {
        console.error(error, "Error during making requests");
        if (error.code === "ERR_NETWORK") {
          setCustomModalMsg("Network Error: Unable to connect to the server");
          setCustomModalOpen(true);
        } else {
          setCustomModalMsg("An error occurred while submitting form. Please try again.");
          setCustomModalOpen(true);
        }
      }
    } else {
      setCustomModalMsg("Client's Full Name or Phone Number missing!");
      setCustomModalOpen(true);
    }
  }

  const refreshPage = () => {
    navigate(0);
  };

  function closeModal() {
    setModalOpen(false);
    refreshPage();
  }

  function customModalClose() {
    setCustomModalOpen(false);
  }


  return (
    <div
      data-te-modal-init
      className={`fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
      id="exampleModalXl"
      tabIndex={-1}
      aria-labelledby="exampleModalXlLabel"
      aria-modal="true"
      role="dialog"
      // ref={lgModalRef}
    >
      <div
        data-te-modal-dialog-ref
        className="flex items-center pointer-events-none relative h-[calc(100%-1rem)] w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
      >
        <div className="pointer-events-auto relative flex max-h-[100%] overflow-hidden w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none ">
          <div className="h-16 shadow px-10 flex justify-between flex-shrink-0 items-center rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <div className="flex items-center">
              <h5 className="text-lg lg:text-xl font-bold leading-normal text-neutral-800" id="exampleModalXlLabel">
                Add your Events
              </h5>
              {eventForm?.length > 1 && (
                <div className="relative ml-2 scale-90">
                  <FaWpforms size={18} />
                  <span className="absolute -top-4 left-2 p-1 px-2 bg-amber-500 rounded-full text-xs">{eventForm?.length}</span>
                </div>
              )}
            </div>

            <button
              onClick={()=>setEventModalOpen(false)}
              type="button"
              className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              data-te-modal-dismiss
              aria-label="Close"
            >
              <AiOutlineClose size={24} />
            </button>
          </div>
          <div className={`relative overflow-y-auto p-4 px-10 pb-10`}>
            {isFromClient && !isClientSelected && <ClientsForm handleInputChangeClient={handleInputChangeClient} />}
            {eventForm?.length > 0 && (
              <>
                {eventForm?.map((fields, index) => (
                  <SingleEventFormV1
                    key={index}
                    ceaReqErr={ceaReqErr}
                    fields={fields}
                    index={index}
                    view={view}
                    handleDeleteClick={handleDeleteClick}
                    handleInputChange={handleInputChange}
                    handleListsSelect={handleListsSelect}
                    addonAndPackagePrice={addonAndPackagePrice}
                    length={eventForm?.length}
                    isClientSelected={isClientSelected}
                    setClientSelected={setClientSelected}
                    isFromClient={isFromClient}
                    isEventModalOpen={isEventModalOpen}
                  />
                ))}
              </>
            )}
            <CashAdvanceCommentsForm setCashInflow={setCashInflow} setComment={setComment} />
          </div>
          <div className="shadow flex flex-shrink-0 flex-wrap gap-3 items-center justify-end px-10 rounded-b-md border-t-2 border-neutral-200 border-opacity-100 p-4 dark:border-opacity-50">
            <AddAnotherFormBtn handleBtnClick={handleAddClick} name="Add Another Event" />
            <SubmitFormBtn handleSubmitBtn={handleSaveEvents} loading={loading} name={"Save Events"} />
            <GenerateInvoiceModal successRes={ceaReqSuccess} closeModal={closeModal} isModalOpen={isModalOpen} />
            <CustomMessageModal
              title={"Error"}
              icon={<BiError className="text-6xl text-yellow-700" />}
              msg={customModalMsg}
              isModalOpen={isCustomModalOpen}
              closeModal={customModalClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
