import React from "react";
import SidebarLayout from "../../layouts/SidebarLayout";
import TabBarLayout from "../../layouts/TabBarLayout";
import CashOutflowsContent from "../../features/cashflows/CashOutflowsContent";



export default function CashinflowPage() {
  return (
    <SidebarLayout>
      <TabBarLayout pathRoot={'/cash-flows'} pathNames={['Cash inflows', 'Cash outflows']} paths={['/cashInFlows','/cashOutFlows']}>
        <CashOutflowsContent />
      </TabBarLayout>
    </SidebarLayout>
  )
}
