import React from "react";
import SidebarLayout from "../../layouts/SidebarLayout";
import TabBarLayout from "../../layouts/TabBarLayout";
import HistoricalBillsContent from "../../features/income-statements/HistoricalBillsContent";

export default function HistoricalBillsPage() {
    return (
      <SidebarLayout>
        <TabBarLayout pathRoot={'/income-statements'} pathNames={['Ledgers','Historical Bills']} paths={['/ledgers','/historical-bills']}>
          <HistoricalBillsContent />
        </TabBarLayout>
      </SidebarLayout>
    );
  }
