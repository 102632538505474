import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { addAddOnsToCart } from "../../../redux-store/cartSlice";
import { addToPostedAddOns } from "../../../redux-store/eventTbSlice";
import useAddOnsPost from "../../../hooks/useAddOnsPost";
import ConfirmationModal from "../../../components/shared-comp/ConfirmationModal";
import AddAnotherFormBtn from "../../../components/shared-comp/AddAnotherFormBtn";
import SubmitFormBtn from "../../../components/shared-comp/SubmitFormBtn";
import CustomMessageModal from "../../../components/shared-comp/CustomMessageModal";

export default function AddOnsFormModal({ isModalOpen, closeModal, from, rowIdx, setPostedAddOns }) {
  const [addOnForms, setAddOnForms] = useState([{ name: "", price: "" }]);
  const [addOnFormsRes, setAddOnFormsRes] = useState([]);
  const [isConfirmationModalOpen, setModalConfirmationOpen] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [loading, setLoading] = useState(false);

  
  const handleAddClick = () => {
    const values = [...addOnForms];
    values.push({ name: "", price: "" });
    setAddOnForms(values);
  };

  const handleDeleteClick = (index) => {
    const values = [...addOnForms];
    values.splice(index, 1);
    setAddOnForms(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...addOnForms];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setAddOnForms(values);
  };

  const dispatch = useDispatch();
  const addOnPostReqResponse = useAddOnsPost();

  function handleOnSaveButton(e) {
    e.preventDefault();
    if (from === "single_event_form") {
      setLoading(true);
      dispatch(addAddOnsToCart(addOnForms));
      setLoading(false);
      closeModal();
    } else {
      setLoading(true);
      const resArray = [];
      // const autoSelected = [];
      addOnForms.map(async (item) => {
        try {
          const response = await addOnPostReqResponse(item);
          resArray.push(response);
          if (from !== "AddOnsContent") {
            response[1] === "success" && dispatch(addToPostedAddOns(response[0]));
          }
        } catch (error) {
          //console.log(error, "An error has ocurred");
          setCustomModalMsg("An error occurred while saving changes. Please try again.")
          setCustomModalOpen(true)
          // resArray.push([error, "error"]);
        }
        setAddOnFormsRes(resArray);
        setLoading(false);
        const hasError = resArray.some((item) => item.includes("error"));
        if (!hasError) {
          // setPostedAddOns(autoSelected)
          setAddOnForms([{ name: "", price: "" }]);
          setAddOnFormsRes([]);
          setModalConfirmationOpen(true);
        }
      });
    }
  }

  // //console.log(addOnFormsRes, "addon");

  function closeConfirmationModal() {
    setModalConfirmationOpen(false);
    closeModal();
  }

  function closeCustomModal() {
    setCustomModalOpen(false);
  }
  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative" onClose={closeModal} style={{ zIndex: "1200" }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="justify-center w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h1" className="flex justify-between text-2xl font-bold leading-6 text-gray-700 pb-5">
                    New Add-On
                    <button>
                      <AiOutlineClose onClick={closeModal} className="text-2xl text-slate-700 cursor-pointer" />
                    </button>
                  </Dialog.Title>

                  <div className="flex flex-col gap-5">
                    {addOnForms.length > 0 && (
                      <>
                        {addOnForms?.map((item, index) => (
                          <div key={index} className="flex flex-col items-center lg:items-end gap-5 px-5 py-5 pb-10 bg-slate-100 rounded-xl">
                            <div className="w-full flex flex-col">
                              <div className="flex justify-between items-center">
                                <p className="text-sm font-semibold text-slate-400">0{index + 1}</p>
                                {index !== 0 && <AiOutlineClose onClick={() => handleDeleteClick(item)} className="cursor-pointer text-lg drop-shadow-xl" />}

                                {addOnFormsRes[index]?.[1] === "success" && <p className="text-xs text-green-700">Successfully Saved !</p>}
                                {addOnFormsRes[index]?.[1] === "error" && <p className="text-xs text-yellow-700">Please check your inputs!</p>}
                              </div>
                              <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="fname">
                                Add-On Name
                              </label>
                              <input
                                className="text-sm outline-none px-2 h-10 bg-slate-300 rounded-md"
                                type="text"
                                id="addOnName"
                                name="name"
                                value={item?.name}
                                onChange={(event) => handleInputChange(index, event)}
                                placeholder="Type your Add On Name here"
                              />
                              {addOnFormsRes[index]?.[1] === "error" && <p className="text-xs text-red-700">{addOnFormsRes[index]?.[0].response.data.name?.[0]}</p>}
                            </div>
                            <div className="w-full flex flex-col">
                              <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="price">
                                Price
                              </label>
                              <input
                                className="text-sm outline-none px-2 h-10 bg-slate-300 rounded-md no_spinner"
                                type="number"
                                id="price"
                                name="price"
                                value={item?.price}
                                onChange={(event) => handleInputChange(index, event)}
                                placeholder="Type the price here"
                              />
                              {addOnFormsRes[index]?.[1] === "error" && <p className="text-xs text-red-700">{addOnFormsRes[index]?.[0].response.data.price?.[0]}</p>}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>

                  <div className="mt-4 flex justify-end gap-3">

                    <AddAnotherFormBtn handleBtnClick={handleAddClick} name={"Add Another Add-on"} />

                    <SubmitFormBtn handleSubmitBtn={handleOnSaveButton} loading={loading} name={"Save Add-On"} />
                  </div>
                  <ConfirmationModal isConfirmationModalOpen={isConfirmationModalOpen} closeConfirmationModal={closeConfirmationModal} />
                  <CustomMessageModal
                    title={"Error"}
                    icon={<BiError className="text-6xl text-yellow-700" />}
                    msg={customModalMsg}
                    isModalOpen={isCustomModalOpen}
                    closeModal={closeCustomModal}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
