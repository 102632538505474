import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { eventListFormator } from "../../../lib/DataFormatorV1";
import { Link } from "react-router-dom";

function EventCellEmptyView({ columnName }) {
  return <p className="text-sm text-slate-500 text-center capitalize">No {columnName.replace(/_/g, " ")}</p>;
}

function EventCellOneDataView({ eventList, colData }) {
  return (
    <Link to={`/clients-events/events?search_events=ID#${eventList[0]?.id}`} state={{ eventID: eventList[0]?.id }}>
      <div
        data-te-toggle="tooltip"
        title={colData[0]?.event_name}
        className="flex items-center text-sm text-slate-700 tracking-wide py-2 border-b-1 rounded px-1 hover:bg-slate-100"
      >
        <div className="ml-1 bg-indigo-500 px-2 rounded-3xl text-slate-50 py-1">{eventList[0]?.details[0]}</div>
        <div className="ml-1 bg-[#B058C8] px-2 rounded-3xl text-slate-50 py-1">{eventList[0]?.details[1]}</div>
        <p className="bg ml-1 bg-[#96BBE8] px-2 rounded-xl text-slate-50 py-1">{eventList[0]?.details[2]}</p>
      </div>
    </Link>
  );
}

export default function EventCellView({ columnName, colData, index }) {
  const eventList = eventListFormator(colData);
  return (
    <div className="flex flex-col">
      {colData?.length === 0 && <EventCellEmptyView columnName={columnName} />}
      {colData?.length === 1 && <EventCellOneDataView eventList={eventList} colData={colData} />}
      {colData?.length > 1 && (
        <>
          <button
            className=" p-1 border-1 rounded-xl px-3 flex items-center justify-between"
            data-te-collapse-init
            data-te-ripple-init
            data-te-ripple-color="light"
            data-te-target={`#client-${index}`}
            aria-expanded="false"
            aria-controls={`client-${index}`}
          >
            <span className="text-sm capitalize text-slate-500">{columnName.replace(/_/g, " ")} Lists</span>
            <MdKeyboardArrowDown className="text-slate-500" />
          </button>
          <div className="!visible hidden" id={`client-${index}`} data-te-collapse-item>
            {eventList?.map((item) => {
              return (
                <Link key={item.id} to={`/clients-events/events?search_events=ID#${item.id}`} state={{ eventID: item.id }}>
                  <div
                    data-te-toggle="tooltip"
                    title={item.event_name}
                    className="flex items-center text-xs text-slate-700 font-semibold tracking-wide py-2 border-b-1 rounded px-1 hover:bg-slate-100"
                  >
                    <div className="text-sm ml-1 bg-indigo-500 px-2 rounded-3xl text-slate-50 py-1">{item.details[0]}</div>
                    <div className="ml-1 bg-[#B058C8] px-2 rounded-3xl text-slate-50 py-1">{item.details[1]}</div>
                    <p className="bg ml-1 bg-[#96BBE8] px-2 rounded-xl text-slate-50 py-1">{item.details[2]}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
