import React, { useState } from "react";
import { BiErrorCircle } from "react-icons/bi";

export default function ClientsForm({ handleInputChangeClient }) {
  const [fullNameError, setFullNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "full_name") {
      if (value.length > 0) {
        handleInputChangeClient(event.target.name, event.target.value);
        setFullNameError("");
      } else {
        setFullNameError("Please enter client's full name.");
      }
    } else {
      if (value.length > 0) {
        handleInputChangeClient(event.target.name, event.target.value.replace(/\s+/g, ""));
        setPhoneNumberError("");
      } else {
        setPhoneNumberError("Please enter a phone number.");
      }
    }
  };

  return (
    <form>
      <div className="flex flex-col lg:flex-row items-center lg:justify-between lg:items-start lg:gap-20 gap-5">
        <div className="w-full flex flex-col">
          <label
            className="
           text-sm text-slate-700 font-semibold leading-10"
            htmlFor="fname"
          >
            Client's Full Name
          </label>
          <input
            className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md"
            type="text"
            name="full_name"
            onChange={handleChange}
            placeholder="Type your Full Name here"
            required
          />
          {fullNameError && (
            <span className="rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
              <BiErrorCircle />
              {fullNameError}
            </span>
          )}
        </div>
        <div className=" w-full flex flex-col">
          <label
            className="
           text-sm text-slate-700 font-semibold leading-10"
            htmlFor="phoneNumber"
          >
            Phone Number
          </label>
          <input
            className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md"
            type="text"
            name="phone_number"
            onChange={handleChange}
            placeholder="Type your Phone Number here"
            required
          />
          {phoneNumberError && (
            <span className="rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
              <BiErrorCircle />
              {phoneNumberError}
            </span>
          )}
        </div>
      </div>
    </form>
  );
}
