import React, { useState } from "react";
import { MdContentCopy, MdFileDownload } from "react-icons/md";



export default function CopyLinkButton({ text, colData }) {

  const [copied, setCopied] = useState(false);

  setTimeout(() => {
    if (copied === true) {
      setCopied(false)
    }
  }, 2000);

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(colData);
    setCopied(!copied);
  };
  if (colData === null) return ""
  return (
    <div className="flex gap-4 items-center justify-evenly w-36 font-semibold text-slate-700">
      {copied ? <p className="cursor-pointer">Copied!</p> : <MdContentCopy className="text-xl text-sky-700 cursor-pointer" data-te-toggle="tooltip" title={copied ? "Copied!" : "Click to copy"} onClick={handleCopyClick} />}
      |
      <a href={`${colData}`} target='_blank' rel="noreferrer noopener"> <MdFileDownload className="text-2xl text-sky-600 cursor-pointer" data-te-toggle="tooltip" title={"Click to download!"} /></a>
    </div>
  );
}
