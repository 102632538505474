import React from "react";
import SidebarLayout from "../../layouts/SidebarLayout";
import TabBarLayout from "../../layouts/TabBarLayout";
import AddOnsContent from "../../features/service-detail/AddOnsContent";

export default function AddOnsPage() {
    return (
      <SidebarLayout>
        <TabBarLayout pathRoot={'/service-details'} pathNames={['Add-ons', 'Event Packages']} paths={['/addOns','/eventPackages']}>
          <AddOnsContent />
        </TabBarLayout>
      </SidebarLayout>
    )
  }
  