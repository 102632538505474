import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose, AiOutlineCheckCircle } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import SuccessContent from "./SuccessContent";
// import { TiCancel } from "react-icons/ti";

export default function TablePutConfirmationModal({ putResponse, isModalOpen, closeModal, from }) {
  const navigate = useNavigate();
  const refreshPage = () => {
    navigate(0);
  };

  function closeBtn(params) {
    closeModal()
    refreshPage()
  }


  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative" onClose={closeModal} style={{ zIndex: "1200" }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="h-auto justify-center w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <p className="font-semibold text-lg text-slate-800">Confirmation</p>
                    <button>
                      <AiOutlineClose
                        data-te-dismiss="modal"
                        data-te-target="#exampleModalXl"
                        aria-label="Close"
                        onClick={closeBtn}
                        className="text-2xl text-slate-700 cursor-pointer"
                      />
                    </button>
                  </div>

                  <div className="py-5 flex flex-col gap-3">
                    {putResponse.length === 0 && (
                      <div className="w-full flex justify-center items-center gap-5">
                        <div className="w-10 h-10 border-4 border-dashed rounded-full animate-spin dark:border-violet-400"></div>
                        <p className="text-sm text-slate-600">Requesting server....</p>
                      </div>
                    )}
                    {putResponse?.map((item, index) => {
                      if (item.status === "success") {
                        if (from === "ClientsContent" || from === "EventsContent" || from === "CashInflowsContent" || from === "CashOutflowsContent" || from === "TeamMemberBill") {
                          return (
                            <div key={index} className="flex justify-between p-2 rounded-md items-center shadow">
                              <div className="flex flex-col">
                                <p className="text-sm font-semibold text-green-700">
                                  Successfully Changed <span className="italic">SL No. {item.index + 1}</span> !
                                </p>
                              </div>

                              <a
                                href={`https://${item?.res?.invoice_link}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${(from === "ClientsContent" || from === "CashOutflowsContent" || from === "TeamMemberBill") && "hidden"
                                  } inline-flex justify-center rounded-md border border-transparent bg-blue-100 p-2 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                              >
                                Download Invoice
                              </a>

                              {(from === "ClientsContent" || from === "CashOutflowsContent" || from === "TeamMemberBill") && (
                                <AiOutlineCheckCircle className="text-green-500" size={30} />
                              )}
                            </div>
                          );
                        } else {
                          return <SuccessContent key={index} message={"Successfully Updated!"} />;
                        }
                      } else {
                        return (
                          <div key={index} className="flex justify-between border p-2 rounded-md items-center shadow">
                            <div className="flex flex-col">
                              <p className="text-sm font-semibold text-yellow-600">
                                Warning at <span className=" text-indigo-400">SL No. {item.index + 1}</span> :{" "}
                              </p>
                              <p className="text-xs text-yellow-700">{item.res}</p>
                            </div>
                            <BiError className="text-2xl font-semibold text-yellow-600" />
                          </div>
                        );
                      }
                    })}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
