import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import companyLogo from "../../assets/images/Logo.png";
import loginImage from "../../assets/images/login.png";
import { GiTerror } from "react-icons/gi";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import { useNavigate } from "react-router-dom";
import { httpSubmitLogInForm } from "../../requests/httpPostRequests";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export default function SignInPage() {
  const navigate = useNavigate();
  const [username, setUserName] = useState(" ");
  const [password, setPassword] = useState("");
  const [usernameErrors, setUsernameErrors] = useState("");
  const [logInResponse, setLogInResponse] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  
  useEffect(() => {
    // Remove all cookies when the sign-in page is rendered
    const cookies = Cookies.get();
    Object.keys(cookies).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
  }, []);

  const handleOnChange = (event) => {
    const value = event.target.value;

    if (event.target.name === "username") {
      setUserName(value);
      const regex = /^[a-zA-Z][a-zA-Z0-9_-]{1,14}[a-zA-Z0-9]$/;
      let errors = "";
      if (!regex.test(value) && value.length > 0) {
        if (value.length < 3) {
          errors = "Username must be at least 3 characters long.";
        }
        if (value.length > 16) {
          errors = "Username cannot exceed 16 characters.";
        }
        if (!/^[a-zA-Z0-9_-]+$/.test(value)) {
          errors = "Username can only contain letters, numbers, underscores, and hyphens.";
        }
        if (/^[0-9]/.test(value)) {
          errors = "Username cannot start with a number.";
        }
      }
      setUsernameErrors(errors);
    } else {
      setPassword(value);
    }
  };
  
  const handleLogInSubmit = async (event) => {
    event.preventDefault();
    const response = await httpSubmitLogInForm({ username, password });
    setLogInResponse(response);
    if (response?.[1] === "success") {
      window.localStorage.setItem('USER', JSON.stringify(response[0]))
      navigate("/clients-events/clients");
    } else {
      if (response?.[0].response?.data.non_field_errors) {
        // setLogInResponse(response);
        setModalMsg(response[0].response?.data.non_field_errors?.[0]);
        setModalOpen(true);
      } else {
        setModalMsg(response?.[2]);
        setModalOpen(true);
      }
    }
  };

  //admin false: then show client, event, addOn, packages, team; admin true: all



  function closeModal() {
    setModalOpen(false);
  }

  return (
    <div>
      <div className="lg:flex">
        <div className="lg:w-1/2 xl:max-w-screen-sm">
          <div className="py-12 bg-slate-100 lg:bg-white flex justify-center lg:justify-start lg:px-12">
            <div className="cursor-pointer flex gap-2 items-center">
              <div>
                <img src={companyLogo} className="w-12" alt="" />
              </div>

              <div className="text-slate-600 font-semibold text-4xl whitespace-pre">RP Billing</div>
            </div>
          </div>
          <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
            <h2
              className="text-center text-4xl text-slate-900 font-display font-semibold lg:text-left xl:text-4xl
            xl:text-bold"
            >
              Log in
            </h2>
            <div className="mt-12">
              <form>
                <div>
                  <label htmlFor="username" className="text-sm font-bold text-gray-700 tracking-wide">
                    Username
                  </label>
                  <input
                    className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-slate-500"
                    id="username"
                    type="text"
                    name="username"
                    onChange={handleOnChange}
                    placeholder="Enter username here"
                  />
                  {usernameErrors?.length > 0 && (
                    // <ul className="flex-col justify-start">
                    //   {usernameErrors?.map((error, index) => (
                    //     <li key={index} className="rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1">{error}</li>
                    //     ))}
                    // </ul>
                    <p className="rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1">{usernameErrors}</p>
                  )}
                  {logInResponse?.[1] === "error" && logInResponse?.[0].response?.data.username?.[0] && (
                    <p className="rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1">{logInResponse?.[0].response?.data?.username?.[0]}</p>
                  )}
                </div>
                <div className="mt-8">
                  <div className="flex justify-start">
                    <label htmlFor="password" className="text-sm font-bold text-gray-700 tracking-wide">
                      Password
                    </label>
                  </div>
                  <input
                    className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-slate-500"
                    id="password"
                    type="password"
                    name="password"
                    onChange={handleOnChange}
                    placeholder="Enter your password"
                  />
                  {logInResponse?.[1] === "error" && logInResponse?.[0].response?.data?.password?.[0] && (
                    <p className="rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1">{logInResponse?.[0].response?.data?.password?.[0]}</p>
                  )}
                </div>
                <div className="mt-10">
                  <button
                    className="bg-slate-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                        font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-slate-600
                        shadow-lg"
                    onClick={handleLogInSubmit}
                  >
                    Log In
                  </button>
                  <CustomMessageModal
                    title={""}
                    icon={<GiTerror className="text-6xl text-indigo-700" />}
                    msg={modalMsg}
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex items-center justify-center bg-slate-100 flex-1 h-screen">
          <div className="transform duration-200 hover:scale-110 cursor-pointer">
            <img src={loginImage} style={{ width: "500px" }} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
