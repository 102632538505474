import React, { useEffect, useMemo, useState } from "react";
import { GiTerror } from "react-icons/gi";
import Table from "../../components/table-comp/Table";
import DropDown from "../../components/shared-comp/DropDown";
import { dynamicFilter } from "../../lib/DataFilterv2";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import { DataSorterV1 } from "../../lib/DataSorterV1";
import { httpGetHistoricalBillTableData } from "../../requests/httpGetRequests";
import Loader from "../../components/shared-comp/Loader";

export default function HistoricalBillsContent() {
  const [data, setData] = useState([]);
  const [historicalBillTableData, setHistoricalBillTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [dropDownItems, setDropDownItems] = useState([]);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [filterKey, setFilterKey] = useState(null);

  const getHistoricalBillTableData = useMemo(
    () => async () => {
      try {
        setTableLoading(true);
        const fetchedHistoricalBillTableData = await httpGetHistoricalBillTableData();
        if (fetchedHistoricalBillTableData[1] === "success") {
          setData(fetchedHistoricalBillTableData[0]);
          setHistoricalBillTableData(fetchedHistoricalBillTableData[0]);
          setTableLoading(false);
          const uniqueSources = new Set();
          const filteredData = [];

          for (const item of fetchedHistoricalBillTableData[0]) {
            if (!uniqueSources.has(item.user)) {
              filteredData.push(item);
              uniqueSources.add(item.user);
            }
          }
          setDropDownItems(filteredData);
        } else {
          setCustomModalMsg(fetchedHistoricalBillTableData[2]);
          setTableLoading(false);
          setCustomModalOpen(true);
        }
      } catch (error) {
        console.error(error);
        setCustomModalMsg("An error occurred while fetching data.");
        setTableLoading(false);
        setCustomModalOpen(true);
      }
    },
    []
  );

  useEffect(() => {
    getHistoricalBillTableData();
  }, [getHistoricalBillTableData]);

  useEffect(() => {
    if (filterKey) {
      const filteredData = dynamicFilter(historicalBillTableData, filterKey);
      setData(filteredData);
    } else {
      setData(historicalBillTableData);
    }
  }, [filterKey, historicalBillTableData]);

  const handleFilter = (columnName, value, reset) => {
    if (reset) {
      setFilterKey(null);
    } else setFilterKey({ ...filterKey, [columnName]: value });
  };

  const handleSorts = (TBdata, columnName, order) => {
    const sortedData = DataSorterV1(TBdata, columnName, order);
    setData(sortedData);
  };

  function closeModal() {
    setCustomModalOpen(false);
  }

  return (
    <div>
      <div className="flex gap-3 flex-col justify-between mt-5 md:flex-row items-center">
        <div className="w-full flex flex-col items-start gap-3">
          <h1 className="text-2xl font-semibold text-slate-500"> {data && data.length} Historical Bills</h1>
          <div className="w-full md:w-auto">
            <DropDown DropDownItems={dropDownItems} handleFilter={handleFilter} name={"Filter By Users"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
          </div>
        </div>
        <CustomMessageModal title={""} icon={<GiTerror className="text-6xl text-indigo-700" />} msg={customModalMsg} isModalOpen={isCustomModalOpen} closeModal={closeModal} />
      </div>

      {!tableLoading ? (
        <div className="mt-10">
          <Table editableCol={true} data={data} handleSorts={handleSorts} clearFilters={clearFilters} setClearFilters={setClearFilters} />
        </div>
      ) : (
        <Loader loadingText="Table Loading" />
      )}
    </div>
  );
}
