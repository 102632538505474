import React, { useEffect, useMemo, useState } from "react";
import { GiTerror } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import Table from "../../components/table-comp/Table";
import AddOnsFormModal from "../clients-events/components/AddOnsFormModal";
import AddDataBtn from "../../components/shared-comp/AddDataBtn";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import { httpGetAddOns } from "../../requests/httpGetRequests";
import Loader from "../../components/shared-comp/Loader";

export default function AddOnsContent() {

  const [data, setData] = useState([]);
  const [addOnsTableData, setAddOnsTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [isAddOnModalOpen, setAddOnModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const navigate = useNavigate();

  const getAddOnsTableData = useMemo(()=> async () => {
    try {
      setTableLoading(true)
      const fetchedAddOnsTableData = await httpGetAddOns();
      if (fetchedAddOnsTableData[1] === "success") {
        setData(fetchedAddOnsTableData[0]);
        setAddOnsTableData(fetchedAddOnsTableData[0]);
        setTableLoading(false)
      } else {
        setCustomModalMsg(fetchedAddOnsTableData[2]);
        setTableLoading(false)
        setCustomModalOpen(true);
      }
    } catch (error) {
      setCustomModalMsg("An error occurred while fetching data.");
      setTableLoading(false)
      setCustomModalOpen(true);
    }
  }, []);

  
  useEffect(() => {
    getAddOnsTableData()
  }, [getAddOnsTableData]);
  
  const refreshPage = () => {
    navigate(0);
  };

  function closeModal() {
    setAddOnModalOpen(false);
    refreshPage();
  }

  function closeCustomModal() {
    setCustomModalOpen(false);
  }

  return (
    <div>
      <div className="flex flex-col justify-between mt-5 md:flex-row items-center">
        <h1 className="text-2xl font-semibold text-slate-500"> {addOnsTableData && addOnsTableData.length} Add ons</h1>
        <div onClick={() => setAddOnModalOpen(true)}>
          <AddDataBtn targetId={""} name={"New Add-Ons"} />
        </div>
        <AddOnsFormModal from={"AddOnsContent"} closeModal={closeModal} isModalOpen={isAddOnModalOpen} />
      </div>
      <CustomMessageModal title={""} icon={<GiTerror className="text-6xl text-indigo-700" />} msg={customModalMsg} isModalOpen={isCustomModalOpen} closeModal={closeCustomModal} />

      <div className="mt-10">
       {!tableLoading? <Table from="AddOnPage" data={data} /> : <Loader loadingText="Table Loading" />}
      </div>
    </div>
  );
}
