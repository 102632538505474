import { httpUpdateClientTableData, httpUpdateEventTableData, httpUpdateCashInflowsTableData, httpUpdateCashOutflowTableData, httpUpdateTeamMemberBillTableData, httpUpdatePackageTableData } from "../requests/httpPutRequests";

export default function usePutData() {
  const getClientTablePutReqRes = async (inputs) => {
    return httpUpdateClientTableData(inputs);
  };

  const getEventTablePutReqRes = async (inputs) => {
    return httpUpdateEventTableData(inputs);
  };

  const getCashInflowsTablePutReqRes = async (inputs) => {
    return httpUpdateCashInflowsTableData(inputs);
  };

  const getCashOutflowsTablePutReqRes = async (inputs) => {
    return httpUpdateCashOutflowTableData(inputs);
  };
  const getEventPackageTablePutReqRes = async (inputs) => {
    return httpUpdatePackageTableData(inputs);
  };

  const getTeamMemberBillsTablePutReqRes = async (inputs) => {
    return httpUpdateTeamMemberBillTableData(inputs);
  };

  return {getClientTablePutReqRes,getEventPackageTablePutReqRes, getEventTablePutReqRes, getCashInflowsTablePutReqRes, getCashOutflowsTablePutReqRes, getTeamMemberBillsTablePutReqRes};
}
