import React from "react";

export default function AddAnotherFormBtn({ handleBtnClick, name }) {
  return (
    <button
      type="button"
      className="inline-flex drop-shadow justify-start rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-semibold text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
      onClick={handleBtnClick}
    >
      {name}
    </button>
  );
}
