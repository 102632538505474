import React from "react";
import { GoAlert } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";

export default function ToastMessage({ message, type }) {
  return (
    <div
      className=" flex justify-between  w-10/12 text-xs items-center rounded-lg bg-warning-100 px-6 py-1  text-warning-800 "
      role="alert"
      data-te-alert-init
      data-te-alert-show
    >
      <div className="flex flex-col lg:flex-row justify-between items-center py-2">
        <strong className="mr-2 flex items-center">
          <GoAlert className="mr-2" /> {type}
        </strong>
        <p className="px-3">{message}</p>
      </div>
      <button
        type="button"
        className=" p-1 text-warning-900 opacity-50 hover:text-warning-900 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
        data-te-alert-dismiss
        aria-label="Close"
      >
        <span className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
          <AiOutlineClose />
        </span>
      </button>
    </div>
  );
}
