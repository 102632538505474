import React, { useEffect, useState } from "react";
import Sidebar from "../components/layout-comp/Sidebar";
import Appbar from "../components/layout-comp/Appbar";

function isMobile() {
  const mediaQuery = window.matchMedia("(max-width: 767px)");
  return mediaQuery.matches;
}

export default function SidebarLayout({ children }) {
  const [mobile, setMobile] = useState(isMobile());
  const [toggle, setToggle] = useState(true);
  function toggleMargin() {
    setToggle(!toggle);
  }

  useEffect(() => {
    function handleResize() {
      setMobile(isMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (mobile) {
    return (
      <div>
        <Appbar />
        <div className={`w-11/12 mx-5`}>{children}</div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-row gap-3">
        <Sidebar toggleMargin={toggleMargin} />
        <div className={`w-full md:w-11/12 py-2 px-5 ${toggle ? "md:ml-24" : "md:ml-72"} duration-300`}>{children}</div>
      </div>
    );
  }
}
