import React from "react";
import { BiLoaderCircle } from "react-icons/bi";
import { FaExchangeAlt } from "react-icons/fa";

export default function SaveChangesButton({ changedRows,allChanges, loading, handleSaveChanges }) {

  if(allChanges !== undefined ){
    return (
        <button
        disabled={allChanges.length === 1 && !Object.keys(changedRows[0]).length}
        onClick={() => handleSaveChanges()}
        className={`${
          allChanges.length === 1 && !Object.keys(changedRows[0]).length ? "opacity-80 bg-gray-500" : "bg-indigo-800 hover:bg-indigo-800"
        } w-full rounded p-3 text-xs lg:text-sm font-semibold text-slate-100 cursor-pointer btn_shadow `}
        data-te-toggle="tooltip"
        title={allChanges.length === 1 && !Object.keys(changedRows[0]).length ? "Disabled now! Edit table first" : ""}
      >
        {loading && (
          <span className="flex justify-center gap-1 items-center">
            <BiLoaderCircle className="animate-spin text-xl" />
            <p>Processing...</p>
          </span>
        )}

        {!loading && (
          <span className="flex justify-center gap-1 items-center">
            <FaExchangeAlt className=" text-xl" />
            <p>Save Changes</p>
          </span>
        )}
      </button>
    )
  }
  return (
    <button
      disabled={!Object.keys(changedRows[0]).length}
      onClick={() => handleSaveChanges()}
      className={`${!Object.keys(changedRows[0]).length ? "opacity-80 bg-gray-500" : "bg-indigo-700 hover:bg-indigo-800"} w-full rounded p-3 text-xs lg:text-sm font-semibold text-slate-100 cursor-pointer btn_shadow `}
      data-te-toggle="tooltip"
      title={!Object.keys(changedRows[0]).length ? "Disabled now! Edit table first" : ""}
    >
      {loading && (
        <span className="flex justify-center gap-1 items-center">
          <BiLoaderCircle className="animate-spin text-xl" />
          <p>Processing...</p>
        </span>
      )}

      {!loading && (
        <span className="flex justify-center gap-1 items-center">
          <FaExchangeAlt className=" text-xl" />
          <p>Save Changes</p>
        </span>
      )}
    </button>
  );
}
