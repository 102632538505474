import React, { useEffect, useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdAddCircleOutline } from "react-icons/md";
import ComboBox from "./ComboBox";
import AddOnsFormModal from "./AddOnsFormModal";
import { BiErrorCircle } from "react-icons/bi";
import ListBox from "./ListBox";


import ToastMessage from "./ToastMessage";
import { httpGetDropLists } from "../../../requests/httpGetRequests";

export default function SingleEventFormV1({
  index,
  fields,
  length,
  view,
  ceaReqErr,
  handleListsSelect,
  handleInputChange,
  handleDeleteClick,
  addonAndPackagePrice,
  isClientSelected,
  setClientSelected,
  isFromClient,
  isEventModalOpen
}) {
  const [dropListsData, setDropListsData] = useState([]);
  const [isAddOnModalOpen, setAddOnModalOpen] = useState(false);

  //form states--------------------->

 
  const getDropList = useMemo(()=>async () => {
    const fetchedLists = await httpGetDropLists();
    fetchedLists?.package?.push({ id: "custom", name: "One time (Custom)", budget: 0 });
    fetchedLists?.video_package?.unshift({ id: null, name: "No Video Package", budget: 0 });
    setDropListsData( fetchedLists );
  }, []);

  useEffect(() => {
    if(isEventModalOpen){
      getDropList()
    }
  }, [getDropList, isEventModalOpen]);

  function handleSingleEventFormData(data, formType, selectedAddOns) {
    switch (formType) {
      case "client":
        return handleListsSelect(index, "client", data);
      case "event_type":
        return handleListsSelect(index, "event_type", data);
      case "package":
        return handleListsSelect(index, "package", data);
      case "video_package":
        return handleListsSelect(index, "video_package", data);
      case "add_ons":
        return handleListsSelect(index, "additions", data, selectedAddOns);
      case "event_leads":
        return handleListsSelect(index, "leads", data);
      default:
        return null;
    }
  }

  function closeModal() {
    setAddOnModalOpen(false);
  }

  // //console.log(addOnPrice);
  return (
    <div className="flex flex-col mt-8 pb-10 pt-5 bg-slate-100 rounded-3xl px-5">
      <div className="flex justify-between items-center gap-2">
        <p className="text-slate-400">0{index + 1}</p>
        {typeof ceaReqErr[0]?.response?.data === "object" &&
          ceaReqErr[0]?.response?.data.hasOwnProperty("error_message") &&
          index === ceaReqErr[0]?.response?.data?.event_index && <ToastMessage message={ceaReqErr[0]?.response?.data?.error_message} type={"Error Message"} />}
        {index !== 0 && (
          <div>
            <AiOutlineClose
              onClick={() => {
                handleDeleteClick(index);
              }}
              className="cursor-pointer text-lg drop-shadow-xl"
            />
          </div>
        )}
      </div>

      {/* 1st row */}
      <div className="flex flex-col lg:flex-row items-center lg:items-start lg:gap-8 gap-5">
        {view && index === 0 && (
          <div className="flex flex-col lg:w-3/12 w-full">
            <label className="text-sm text-slate-700 font-semibold leading-8">
              Clients <span className="text-red-500">*</span>
            </label>
            <div className="flex justify-between items-start w-full">
              <div className={`grow select-none`}>
                <ComboBox listData={dropListsData?.client} handleSingleEventFormData={handleSingleEventFormData} formType={"client"} setClientSelected={setClientSelected} />
              </div>

              {isFromClient && (
                <MdAddCircleOutline
                  onClick={() => setClientSelected(!isClientSelected)}
                  className="flex-none text-2xl ml-1 mt-3 text-slate-500 cursor-pointer"
                  data-te-toggle="tooltip"
                  title="Add New Client"
                />
              )}
            </div>
            <div className="relative">
              {typeof ceaReqErr[0]?.response?.data === "string" && index === ceaReqErr[0]?.response?.data?.event_index && (
                <span className="w-full rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
                  <BiErrorCircle />
                  Please select or add clients
                </span>
              )}
            </div>
          </div>
        )}

        <div className={`flex flex-col ${view && index === 0 && "lg:w-3/12"} w-full`}>
          <label className="text-sm text-slate-700 font-semibold leading-8">
            Event Type <span className="text-red-500">*</span>
          </label>
          <ComboBox listData={dropListsData?.event_type} handleSingleEventFormData={handleSingleEventFormData} formType={"event_type"} />

          {typeof ceaReqErr[0]?.response?.data === "object" && ceaReqErr[0]?.response?.data.hasOwnProperty("event_type") && index === ceaReqErr[0]?.response?.data?.event_index && (
            <span className="w-full rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-2 flex items-center gap-1">
              <BiErrorCircle />
              {ceaReqErr[0]?.response?.data?.event_type[0] || ""}
            </span>
          )}
        </div>
        <div className={`flex flex-col ${view && index === 0 && "lg:w-3/12"} w-full`}>
          <label className="text-sm text-slate-700 font-semibold leading-8">Add ons </label>
          <div className="flex justify-between items-start">
            <div className="grow">
              <ListBox formIndex={index} listData={dropListsData?.add_ons} multiple={true} handleSingleEventFormData={handleSingleEventFormData} formType={"add_ons"} />
            </div>
            <MdAddCircleOutline onClick={() => setAddOnModalOpen(true)} className="flex-none text-2xl ml-1 mt-3 text-slate-500 cursor-pointer" />
          </div>

          {typeof ceaReqErr[0]?.response?.data === "object" && ceaReqErr[0]?.response?.data.hasOwnProperty("add_ons") && index === ceaReqErr[0]?.response?.data?.event_index && (
            <span className="w-full rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
              <BiErrorCircle />
              {ceaReqErr[0]?.response?.data?.add_ons[0] || ""}
            </span>
          )}

          <AddOnsFormModal closeModal={closeModal} from={"single_event_form"} isModalOpen={isAddOnModalOpen} />
        </div>

        <div className={`flex flex-col ${view && index === 0 && "lg:w-3/12"} w-full`}>
          <label className="text-sm text-slate-700 font-semibold leading-8">Event Lead </label>
          <div className="flex justify-between items-start">
            <div className="grow">
              <ListBox listData={dropListsData?.event_leads} multiple={true} handleSingleEventFormData={handleSingleEventFormData} formType={"event_leads"} />
            </div>
          </div>

          {typeof ceaReqErr[0]?.response?.data === "object" &&
            ceaReqErr[0]?.response?.data.hasOwnProperty("event_leads") &&
            index === ceaReqErr[0]?.response?.data?.event_index && (
              <span className="w-full rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
                <BiErrorCircle />
                {ceaReqErr[0]?.response?.data?.event_leads[0] || ""}
              </span>
            )}
        </div>
      </div>

      {/* 2nd row */}
      <div className="mt-8 flex flex-col lg:flex-row items-center lg:items-start lg:gap-8 gap-5">
        <div className="flex flex-col lg:w-3/12 w-full">
          <label className="text-sm text-slate-700 font-semibold leading-8">
            Packages <span className="text-red-500">*</span>
          </label>
          <ComboBox listData={dropListsData?.package} handleSingleEventFormData={handleSingleEventFormData} index={index} length={length} formType={"package"} />
        </div>

        <div className="flex flex-col lg:w-3/12 w-full">
          <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="date">
            Date <span className="text-red-500">*</span>
          </label>
          <input
            className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md"
            type="date"
            name="date"
            value={fields?.date}
            onChange={(event) => handleInputChange(index, event)}
          />

          {typeof ceaReqErr[0]?.response?.data === "object" && ceaReqErr[0]?.response?.data.hasOwnProperty("date") && index === ceaReqErr[0]?.response?.data?.event_index && (
            <span className="w-full rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
              <BiErrorCircle size={25} />
              {ceaReqErr[0]?.response?.data?.date[0] || ""}
            </span>
          )}
        </div>

        <div className="flex flex-col lg:w-3/12 w-full">
          <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="venue">
            Venue <span className="text-red-500">*</span>
          </label>
          <input
            className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md"
            type="text"
            name="venue"
            value={fields?.venue}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Enter the venue here"
          />

          {typeof ceaReqErr[0]?.response?.data === "object" && ceaReqErr[0]?.response?.data.hasOwnProperty("venue") && index === ceaReqErr[0]?.response?.data?.event_index && (
            <span className="w-full rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
              <BiErrorCircle />
              {ceaReqErr[0]?.response?.data?.venue[0] || ""}
            </span>
          )}
        </div>
        <div className="flex flex-col lg:w-3/12 w-full">
          <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="discount">
            Discount (TK)
          </label>
          <input
            className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md no_spinner"
            type="number"
            name="discount"
            min="0"
            value={fields?.discount}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Enter the Discount here"
          />

          {typeof ceaReqErr[0]?.response?.data === "object" && ceaReqErr[0]?.response?.data.hasOwnProperty("discount") && index === ceaReqErr[0]?.response?.data?.event_index && (
            <span className="w-full rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
              <BiErrorCircle />
              {ceaReqErr[0]?.response?.data?.discount[0] || ""}
            </span>
          )}
          {/* {fields.discount > addonAndPackagePrice[index].package + addonAndPackagePrice[index].additions && (
            <span className="w-full rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
              <BiErrorCircle />
              {`Discount limit is ${addonAndPackagePrice[index].package + addonAndPackagePrice[index].additions} TK`}
            </span>
          )} */}
        </div>
      </div>

      <div className="mt-8 flex flex-col lg:flex-row items-center lg:items-start lg:gap-8 gap-5">
        <div className="flex flex-col lg:w-3/12 w-full">
          <label className="text-sm text-slate-700 font-semibold leading-8">
            Video packages
          </label>
          <ComboBox listData={dropListsData?.video_package} handleSingleEventFormData={handleSingleEventFormData} index={index} length={length} formType={"video_package"} />
        </div>
      </div>
    </div>
  );
}
