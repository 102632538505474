import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { HiCheck, HiChevronDown } from "react-icons/hi";

export default function ComboBox({ listData, handleSingleEventFormData, formType, index, length, setClientSelected }) {
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState("");
  

  const filteredItems =
    query === ""
      ? listData
      : listData?.filter((item) =>
          (item.full_name || (Object.keys(item).length === 1 && Object.keys(item)[0]) || item.name)
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  function handleOnSelect(item) {
    setSelected(item)
    handleSingleEventFormData(item, formType);
    formType === "client" && setClientSelected(true);
  }

  

  return (
    <div>
      <Combobox value={selected} onChange={handleOnSelect}>
        {({ open }) => (
          <div className="relative mt-1">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-slate-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 outline-none"
                displayValue={(item) => item.full_name || (Object.keys(item).length === 1 && Object.keys(item)[0]) || item.name}
                placeholder="Enter or choose"
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <HiChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </Combobox.Button>
            </div>
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery("")}>
              <Combobox.Options
                className={`absolute ${index===length-1 && formType ==="package"? "-top-44":""} mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
                style={{ zIndex: "1100" }}
              >
                {filteredItems?.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
                ) : (
                  filteredItems?.map((item, i) => (
                    <Combobox.Option
                      key={i}
                      className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-slate-600 text-white" : "text-gray-900"}`}
                      value={item}
                      // onClick={() => handleOnSelect(item)}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`block  ${selected ? "font-semibold" : "font-normal"}`}>
                            {item.full_name || (Object.keys(item).length === 1 && Object.keys(item)[0]) || item.name}{(formType ==="package" || formType==="video_package") && `(${item?.budget} tk)`}
                          </span>
                          {selected ? (
                            <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-slate-600"}`}>
                              <HiCheck className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
    </div>
  );
}
