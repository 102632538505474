import { createSlice } from "@reduxjs/toolkit";

const eventTbSlice = createSlice({
  name: "eventTb",

  initialState: {
    add_ons:[],
    pkg:[],
    video_package:[],
    event_type:[],
    leads:[],
    posted_add_ons:[]
  },

  reducers: {
  
    addToAddOns(state, action){
        const { index, id, add_ons } = action.payload;
        const itemIndex = state.add_ons.findIndex((item) => item.index === index); 
        if (itemIndex === -1) {
          state.add_ons.push({ index, id, add_ons });
        } else {
          state.add_ons[itemIndex].add_ons = add_ons;
        }
     
    },
    addToPostedAddOns(state, action){
      const {id, name} = action.payload;
      state.posted_add_ons.push({id, name})
   
  },
    addToPackage(state, action){
        const newItem = action.payload;

        const itemIndex = state.pkg.findIndex((item) => item.index === newItem.index); 
        if (itemIndex === -1) {
          state.pkg.push(newItem);
        } else {
          state.pkg[itemIndex] = newItem;
        }
    },

    addToVideoPackage(state, action){
      const newItem = action.payload;

      const itemIndex = state.video_package.findIndex((item) => item.index === newItem.index); 
      if (itemIndex === -1) {
        state.video_package.push(newItem);
      } else {
        state.video_package[itemIndex] = newItem;
      }
  },

    addToEventType(state, action){
        const newItem = action.payload;
        const itemIndex = state.event_type.findIndex((item) => item.index === newItem.index); 
        if (itemIndex === -1) {
          state.event_type.push(newItem);
        } else {
          state.event_type[itemIndex]= newItem;
        }
    },

    addToLeadBy(state, action){
        const { index, id, leads } = action.payload;
        const itemIndex = state.leads.findIndex((item) => item.index === index); 
        if (itemIndex === -1) {
          state.leads.push({ index, id, leads });
        } else {
          state.leads[itemIndex].leads = leads;
        }
    },

    backToInitialState(state, action){
      state.add_ons = []
      state.pkg = []
      state.event_type = []
      state.lead_by = []
      state.video_package = []
    }

  },
});

export const { addToAddOns,addToPostedAddOns, addToPackage,addToVideoPackage,  addToEventType, addToLeadBy, backToInitialState} = eventTbSlice.actions;
export default eventTbSlice;
