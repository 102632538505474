import React, { useEffect, useMemo, useState } from "react";

import { GiTerror } from "react-icons/gi";
import Table from "../../components/table-comp/Table";
import DropDown from "../../components/shared-comp/DropDown";
import { DataSorterV1 } from "../../lib/DataSorterV1";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import { dynamicFilter } from "../../lib/DataFilterv2";
import { useSelector } from "react-redux";
import SaveChangesButton from "../../components/shared-comp/SaveChangesButton";
import TablePutConfirmationModal from "../../components/shared-comp/TablePutConfirmationModal";
import AddDataBtn from "../../components/shared-comp/AddDataBtn";
import usePutData from "../../hooks/usePutData";
import AddPackageModal from "./components/AddPackageModal";
import { httpGetEventPackages } from "../../requests/httpGetRequests";
import Loader from "../../components/shared-comp/Loader";

const DropDownItems = [
  {
    title: "Active Package",
    fieldName: "active",
    value: true,
  },
  {
    title: "Inactive package",
    fieldName: "active",
    value: false,
  },
];

export default function EventPackagesContent() {
  const [data, setData] = useState([]);
  const [eventPackagesTableData, setEventPackagesTableData] = useState([]);
  const [changedRows, setChangedRows] = useState([{}]);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const { packageStatusList } = useSelector((state) => state.packageStates);
  const [putResponse, setPutResponse] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [filterKey, setFilterKey] = useState(null);

  const getEventPackageTableData = useMemo(
    () => async () => {
      try {
        setTableLoading(true);
        const fetchedEventPackageTableData = await httpGetEventPackages();
        if (fetchedEventPackageTableData[1] === "success") {
          setData(fetchedEventPackageTableData[0]);
          setEventPackagesTableData(fetchedEventPackageTableData[0]);
          setTableLoading(false);
        } else {
          setCustomModalMsg(fetchedEventPackageTableData[2]);
          setTableLoading(false);
          setCustomModalOpen(true);
        }
      } catch (error) {
        console.error(error);
        setCustomModalMsg("An error occurred while fetching data.");
        setTableLoading(false);
        setCustomModalOpen(true);
      }
    },
    []
  );

  useEffect(() => {
    getEventPackageTableData();
  }, [getEventPackageTableData]);

  useEffect(() => {
    packageStatusList.length !== 0 && setChangedRows(packageStatusList);
  }, [packageStatusList]);

  useEffect(() => {
    if (filterKey) {
      const filteredData = dynamicFilter(eventPackagesTableData, filterKey);
      setData(filteredData);
    } else {
      setData(eventPackagesTableData);
    }
  }, [filterKey, eventPackagesTableData]);

  const handleFilter = (columnName, value, reset) => {
    if (reset) {
      setFilterKey(null);
    } else setFilterKey({ ...filterKey, [columnName]: value });
  };

  const { getEventPackageTablePutReqRes } = usePutData();

  const handleSaveChanges = () => {
    changedRows?.map(async (item) => {
      try {
        setLoading(true);
        const response = await getEventPackageTablePutReqRes(item);
        setPutResponse((prevArray) => [...prevArray, response]);
        setLoading(false);
        console.log(response, "response");
      } catch (error) {
        //console.log(error, "Error during making requests");
        setLoading(false);
        setCustomModalMsg("An error occurred while saving changes. Please try again.");
        setCustomModalOpen(true);
      }
    });
    setChangedRows([{}]);
    setModalOpen(true);
  };

  const handleSorts = (TBdata, columnName, order) => {
    const sortedData = DataSorterV1(TBdata, columnName, order);
    setData(sortedData);
  };

  function closeModal() {
    setModalOpen(false);
  }

  function closeCustomModal() {
    setCustomModalOpen(false);
  }

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h1 className="text-2xl font-semibold text-slate-500"> {data && data.length} Packages</h1>
      </div>
      <div className="text-sm text-slate-600 flex flex-col justify-between mt-5 xl:flex-row gap-3">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <DropDown DropDownItems={DropDownItems} handleFilter={handleFilter} name={"Package Status"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
        </div>
        <div className="mt-5 md:mt-0 w-full gap-3 xl:w-4/12 flex flex-col lg:flex-row justify-between lg:justify-between items-center">
          <SaveChangesButton changedRows={changedRows} loading={loading} handleSaveChanges={handleSaveChanges} />
          <AddDataBtn targetId={"#addPackageModalXl"} name={"Add New Packages"} />
          <TablePutConfirmationModal putResponse={putResponse} isModalOpen={isModalOpen} closeModal={closeModal} />
        </div>
      </div>
      <CustomMessageModal title={""} icon={<GiTerror className="text-6xl text-indigo-700" />} msg={customModalMsg} isModalOpen={isCustomModalOpen} closeModal={closeCustomModal} />

      <AddPackageModal />

      <div className="mt-10">
        {!tableLoading ? (
          <Table from="EventPackages" editableCol={true} data={data} handleSorts={handleSorts} clearFilters={clearFilters} setClearFilters={setClearFilters} />
        ) : (
          <Loader loadingText="Table Loading" />
        )}
      </div>
    </div>
  );
}
