import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import SuccessContent from "../../../components/shared-comp/SuccessContent";

export default function GenerateInvoiceModal({ successRes, isModalOpen, closeModal }) {
  
  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative" onClose={closeModal} style={{ zIndex: "1200" }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="justify-center w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all h-64">
                  <div className="flex justify-end">
                    <button>
                      <AiOutlineClose
                        data-te-modal-init
                        data-te-dismiss="modal"
                        data-te-target="#exampleModalXl"
                        aria-label="Close"
                        onClick={closeModal}
                        className="text-2xl text-slate-700 cursor-pointer"
                      />
                    </button>
                  </div>

                  <SuccessContent message={"Successfully saved!"} />

                  <div className="mt-4 flex justify-center">
                    {successRes[0]?.invoice_link !== undefined && (
                      <a
                      href={`https://${successRes[0]?.invoice_link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 p-2 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        Download Invoice
                      </a>
                    )}
                  </div>
                  <p className="mt-2 text-xs text-slate-400 text-center">Please Click on the cross button to refresh the page!</p>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
