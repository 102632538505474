import React, { useState, useMemo } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import ListBoxTable from "./ListBoxTable";
import AddOnsFormModal from "./AddOnsFormModal";

export default function ListColumns({ dropListsData, columnName, colData, index, eventID }) {
  const [isAddOnModalOpen, setAddOnModalOpen] = useState(false);

  function closeModal() {
    setAddOnModalOpen(false);
  }

  const listBoxTable = useMemo(() => {
    switch (columnName) {
      case "event_type":
        return <ListBoxTable formType={columnName} listData={dropListsData} colData={colData} index={index} eventID={eventID} multiple={false} />;
      case "package":
        return <ListBoxTable formType={columnName} listData={dropListsData} colData={colData} index={index} eventID={eventID} multiple={false} />;
      case "video_package":
        return <ListBoxTable formType={columnName} listData={dropListsData} colData={colData} index={index} eventID={eventID} multiple={false} />;
      case "add_ons":
        return <ListBoxTable formType={columnName} listData={dropListsData} colData={colData} index={index} eventID={eventID} multiple={true} />;
      case "lead_by":
        return <ListBoxTable formType={"leads"} listData={dropListsData} colData={colData} index={index} eventID={eventID} multiple={true} />;
      case "covered_events":
        return <ListBoxTable formType={columnName} listData={dropListsData} colData={colData} index={index} eventID={eventID} multiple={true} />;
      default:
        return null;
    }
  }, [columnName, dropListsData, colData, index, eventID]);

  return (
    <div className="w-full flex items-center justify-end md:justify-between">
      {listBoxTable}
      {columnName === "add_ons" && (
        <MdAddCircleOutline onClick={() => setAddOnModalOpen(true)} className="text-2xl ml-2 text-slate-500 cursor-pointer" />
      )}
      <AddOnsFormModal closeModal={closeModal} rowIdx={index} isModalOpen={isAddOnModalOpen} />
    </div>
  );
}
