import React, { useEffect, useMemo, useState } from "react";
import { GiTerror } from "react-icons/gi";
import Table from "../../components/table-comp/Table";
import DropDown from "../../components/shared-comp/DropDown";
import { dynamicFilter } from "../../lib/DataFilterv2";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import { DataSorterV1 } from "../../lib/DataSorterV1";
import { httpGetLedgersTableData } from "../../requests/httpGetRequests";
import { removeKeys } from "../../lib/DataFormatorV1";
import Loader from "../../components/shared-comp/Loader";

export default function LedgersContent() {
  const [data, setData] = useState([]);
  const [ledgersTableData, setLedgersTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [monthYear, setMonthYear] = useState("");
  const [dropDownItems, setDropDownItems] = useState([]);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const [filterKey, setFilterKey] = useState(null);


  const getLedgersTableData = useMemo(() => async () => {
    try {
      setTableLoading(true)
      const fetchedLedgersTableData = await httpGetLedgersTableData();
      if (fetchedLedgersTableData[1] === "success") {
        const removedData = removeKeys(fetchedLedgersTableData[0], ["index"])
        setLedgersTableData(removedData)
        setData(filterDataByMonthAndYear(removedData));
        setTableLoading(false)
        setIsInitialLoad(true)
        const now = new Date();
        const year = new Date().getFullYear();
        const monthName = now.toLocaleString("default", { month: "long" });
        setMonthYear(`Events before/after ${monthName}, ${year}`);

        const uniqueSources = new Set();
        const filteredData = [];
        for (const item of removedData) {
          if (!uniqueSources.has(item.month)) {
            filteredData.push({ month_year: item.month + "/" + item.year, month: item.month, filterByMonth: true });
            uniqueSources.add(item.month);
          }
        }
        setDropDownItems(filteredData);
      }  else {
        setCustomModalMsg(fetchedLedgersTableData[2]);
        setTableLoading(false)
        setCustomModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      setCustomModalMsg("An error occurred while fetching data.");
      setTableLoading(false)
      setCustomModalOpen(true);
    } 

  }, []);



  useEffect(() => {
    getLedgersTableData()
  }, [getLedgersTableData]);

  

  function filterDataByMonthAndYear(data) {
    var today = new Date();
    var next = new Date(today);
    next.setMonth(today.getMonth() + 1);
    var lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);
    const prevPresentNextMonthLedger = data.filter(
      (item) =>
        (item.month === lastMonth.getMonth() + 1 && item.year === lastMonth.getFullYear()) ||
        (item.month === today.getMonth() + 1 && item.year === today.getFullYear()) ||
        (item.month === next.getMonth() + 1 && item.year === next.getFullYear())
    );
    return prevPresentNextMonthLedger;
  }


  const getMonthName = (monthNumber) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames[monthNumber - 1];
  };


  useEffect(() => {
    if (filterKey) {
      const filteredData = dynamicFilter(ledgersTableData, filterKey);
      setData(filteredData);
      setMonthYear(`${getMonthName(filteredData[0].month)}, ${filteredData[0].year}`);
      setIsInitialLoad(false)
    }
    else if(isInitialLoad) {
      setData(filterDataByMonthAndYear(ledgersTableData));
      const now = new Date();
      const year = new Date().getFullYear();
      const monthName = now.toLocaleString("default", { month: "long" });
      setMonthYear(`Ledgers before/after ${monthName}, ${year}`);
    }else{
      setData(ledgersTableData);
      setMonthYear("All Data");
    }
  }, [filterKey,isInitialLoad, ledgersTableData]);
  

  
  const handleFilter = (columnName, value, reset) => {
    if (reset) {
      setFilterKey(null);
      setIsInitialLoad(false)
    } else setFilterKey({ ...filterKey, [columnName]: value });
  };



  const handleSorts = (TBdata, columnName, order) => {
    const sortedData = DataSorterV1(TBdata, columnName, order);
    setData(sortedData);
  };

  function closeModal() {
    setCustomModalOpen(false);
  }

  return (
    <div>
      <div className="flex gap-3 flex-col justify-between mt-5 md:flex-row items-center">
        <div className="w-full flex flex-col items-start gap-3">
          <h1 className="text-2xl font-semibold text-slate-500"> {monthYear} </h1>
          <div className="w-full md:w-auto">
            <DropDown DropDownItems={dropDownItems} handleFilter={handleFilter} name={"Month Year"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
          </div>
        </div>
        <CustomMessageModal title={""} icon={<GiTerror className="text-6xl text-indigo-700" />} msg={customModalMsg} isModalOpen={isCustomModalOpen} closeModal={closeModal} />
      </div>

      {!tableLoading ? (
        <div className="mt-10">
          <Table editableCol={true} data={data} handleSorts={handleSorts} clearFilters={clearFilters} setClearFilters={setClearFilters} />
        </div>
      ) : <Loader loadingText="Table Loading" />}
    </div>
  );
}
