import { createSlice } from "@reduxjs/toolkit";

const eventFormSlice = createSlice({
  name: "eventFormStates",

  initialState: {
    addOnPrices: [],
    packagePrices: [],
    event_ids:[]
  },

  reducers: {
    setAddOnPrice(state, action) {
      const item = action.payload;
      const itemIndex = state.addOnPrices.findIndex((addOns) => addOns.formIndex === item.formIndex);
      if (itemIndex === -1) {
        state.addOnPrices.push(item);
      } else {
        state.addOnPrices[itemIndex] = item;
      }
    },
    setPackagePrice(state, action) {
      const item = action.payload;
      const itemIndex = state.packagePrices.findIndex((pkg) => pkg.formIndex === item.formIndex);
      if (itemIndex === -1) {
        state.packagePrices.push(item);
      } else {
        state.packagePrices[itemIndex] = item;
      }
    },
    addToBilledEvents(state, action) {
      console.log(action.payload)
      const id = action.payload;
        const itemIndex = state.event_ids.findIndex((item) => item === id); 
        if (itemIndex === -1) {
          state.event_ids.push(id);
        } else {
          state.event_ids[itemIndex] = id;
        }
    },
    removeBilledEvents(state, action) {
      console.log(action.payload)
      const id = action.payload;
      state.event_ids = state.event_ids.filter((item) => item !== id);
    },
  },
});

export const { setAddOnPrice, setPackagePrice, addToBilledEvents, removeBilledEvents } = eventFormSlice.actions;
export default eventFormSlice;
