import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",

  initialState: {
    itemList: [],
    client: "",
    addOns: [],
  },

  reducers: {
    addToCart(state, action) {
      const newItem = action.payload;
      state.itemList.push({ ...newItem });
    },

    addClientToCart(state, action) {
      const newItem = action.payload;
      state.client = newItem;
    },

    addAddOnsToCart(state, action) {
      const newItem = action.payload;
      state.addOns = newItem;
    },

    removeFromCart(state, action) {
      const newItem = action.payload;
      state.itemList = state.itemList.filter((item) => item.eventID !== newItem);
    },
  },
});

export const { addToCart, addClientToCart, removeFromCart, addAddOnsToCart } = cartSlice.actions;
export default cartSlice;
