import { configureStore } from "@reduxjs/toolkit";
import cartSlice from './cartSlice';
import eventTbSlice from "./eventTbSlice";
import eventFormSlice from "./eventFormSlice";
import teamMemberBillSlice from "./teamMemberBillSlice";
import packageSlice from './packageSlice'



const store = configureStore({
    reducer:{
        cart: cartSlice.reducer,
        eventTb: eventTbSlice.reducer,
        eventFormStates: eventFormSlice.reducer,
        teamMemberBill: teamMemberBillSlice.reducer,
        packageStates: packageSlice.reducer
    }
})

export default store;