
export default function CashAdvanceCommentsForm({ setCashInflow, setComment }) {


  return (
    <form>
      <div className="flex flex-col lg:flex-row items-center lg:justify-between md:items-start lg:gap-25 gap-5 mt-5">
        <div className=" lg:w-[46.5%] w-full flex flex-col">
          <label
            className="
             text-sm text-slate-700 font-semibold leading-10"
            htmlFor="phoneNumber"
          >
            Cash Advance
          </label>
          <input
            className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md no_spinner"
            type="number"
            name="cash_inflow"
            onChange={(e)=> setCashInflow(Number(e.target.value))}
            placeholder="Type amount of cash in advance"
            required
          />
          {/* {phoneNumberError && (
            <span className="rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
              <BiErrorCircle />
              {phoneNumberError}
            </span>
          )} */}
        </div>

        <div className=" lg:w-[46.5%] w-full flex flex-col">
          <label
            className="
             text-sm text-slate-700 font-semibold leading-10"
            htmlFor="phoneNumber"
          >
            Comments
          </label>
          <textarea
            className="text-sm outline-none p-2 h-20 bg-slate-200 rounded-md no_spinner"
            type="text"
            name="comment"
            onChange={(e)=> setComment(e.target.value)}
            placeholder="Type your comments here"
            required
          />
          {/* {phoneNumberError && (
            <span className="rounded-sm p-1 px-2 bg-red-100 text-red-900 text-xs mt-1 flex items-center gap-1">
              <BiErrorCircle />
              {phoneNumberError}
            </span>
          )} */}
        </div>
      </div>
    </form>
  );
}
