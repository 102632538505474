import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import companyLogo from "../../assets/images/Logo.png";
import { HiMenuAlt3 } from "react-icons/hi";
// import SearchBox from "../clients-comp/SearchBox";
import {
  // MdOutlineDashboard,
  MdWorkOutline,
  // MdOutlineEventNote,
  // MdUpdate,
  // MdArrowForwardIos,
  // MdLogout,
  // MdAddChart,
} from "react-icons/md";
import { RiHandCoinLine, RiTeamLine, RiBillLine } from "react-icons/ri";
import {
  // TbReportAnalytics,
  TbCurrencyTaka,
} from "react-icons/tb";
import { BiLogOutCircle } from "react-icons/bi";

export default function Appbar() {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  useEffect(() => {
    const data = JSON.parse(window.localStorage.getItem("USER"));
    setUser(data);
  }, []);


  const role = user?.role;
  let menus;
  if (role === "Admin") {
    menus = [
      { name: "Clients & Events", link: "/clients-events/clients", icon: MdWorkOutline },
      { name: "Finance", link: "/cash-flows/cashInFlows", icon: TbCurrencyTaka },
      { name: "Income Statement", link: "/income-statements/ledgers", icon: RiBillLine },
      { name: "Service Detail", link: "/service-details/addOns", icon: RiHandCoinLine },
      { name: "Team members", link: "/team/team-bills", icon: RiTeamLine },
    ];
  } else if (role === "Manager") {
    menus = [
      { name: "Clients & Events", link: "/clients-events/clients", icon: MdWorkOutline },
      { name: "Service Detail", link: "/service-details/addOns", icon: RiHandCoinLine },
      { name: "Finance", link: "/cash-flows/cashInFlows", icon: TbCurrencyTaka },
      { name: "Team members", link: "/team/team-bills", icon: RiTeamLine },
    ];
  } else {
    menus = [
      { name: "Clients & Events", link: "/clients-events/clients", icon: MdWorkOutline },
      { name: "Team members", link: "/team/team-bills", icon: RiTeamLine },
    ];
  }


  function handleLogOut() {
    let loginURL = `${process.env.REACT_APP_API_URL}/user-logout`
    axios
      .post(loginURL, {
        withCredentials: true,
      })
      .then((response) => {
        response.status === 200 && navigate("/log-in");
      })
      .then((error) => {
        // //console.log(error);
      });
  }

  return (
    <div className="block md:hidden sticky top-0" style={{ zIndex: "1000" }}>
      <nav
        className="flex-no-wrap flex w-full items-center justify-between bg-indigo-900 py-4 shadow-md shadow-black/5 dark:shadow-black/10 lg:flex-wrap lg:justify-start"
        data-te-navbar-ref
      >
        <div className="flex w-full flex-wrap items-center justify-between px-6">
          <div className=" flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400">
            <img src={companyLogo} className="w-8" alt="" loading="lazy" />
            <p className="text-yellow-400 text-xl whitespace-pre tracking-wide">
              {" "}
              RP Billing
            </p>
          </div>

          <button
            className="block border-0 bg-transparent py-2  text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
            type="button"
            data-te-collapse-init
            data-te-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="[&>svg]:w-7">
              <HiMenuAlt3 size={26} className="cursor-pointer" color="white" />
            </span>
          </button>

          <div
            className="!visible hidden md:divide-x-2 md:divide-slate-600 flex-grow basis-[100%] items-center lg:!flex lg:basis-auto"
            id="navbarSupportedContent1"
            data-te-collapse-item
          >
            <ul className="mt-3 py-4 border-t-1 list-style-none mr-auto flex flex-col gap-5 pl-0 lg:flex-row" data-te-navbar-nav-ref>
              {menus.map((item, index) => (
                <Link to={item.link} key={index} className="border-b-1 py-2 flex items-center gap-3" data-te-nav-item-ref>
                  <div>
                    {React.createElement(item?.icon, {
                      size: "20",
                      color: "white",
                    })}
                  </div>
                  <p className="text-slate-50 text-sm">{item.name}</p>
                </Link>
              ))}
            </ul>
            <div className="flex justify-between py-1">

              <div className="flex-col items-center justify-center cursor-pointer" onClick={() => handleLogOut()}>
                <button data-te-toggle="tooltip" title="Log out" className="ml-1 rotate-90 text-slate-900 bg-indigo-700 rounded-full p-2 shadow-md">
                  <BiLogOutCircle className="text-slate-50" />
                </button>
                <p className="text-white text-xs">Log Out</p>
              </div>

              <div className="relative flex items-center">
                <div className="relative flex gap-2">
                  <a className="hidden-arrow flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none" href="...">
                    <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className="rounded-full w-10" alt="" loading="lazy" />
                  </a>
                  <div>
                    <p className="font-bold text-slate-50 text-xs md:text-base">{user?.name}</p>
                    <p className="text-slate-400 text-xs">{user?.admin ? "Admin" : "Team member"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
