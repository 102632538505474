import React from "react";
import SidebarLayout from "../../layouts/SidebarLayout";
import TabBarLayout from "../../layouts/TabBarLayout";
import EventsContent from "../../features/clients-events/EventsContent";

export default function EventsPage() {
  return (
    <SidebarLayout>
      <TabBarLayout pathRoot={'/clients-events'} pathNames={['Clients', 'Events']} paths={['/clients','/events']}>
        <EventsContent />
      </TabBarLayout>
    </SidebarLayout>
  );
}
