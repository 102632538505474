function getErrorMessage(error) {
  let defaultErrorMessage = "An error occurred during the request";

  if (error.code === "ERR_NETWORK") {
    return [error, "error", "Oops! Please check your internet connection and try again.", "ERR_NETWORK"];
  } else if (error.response) {
    const { status } = error.response;
    const responseData = error.response.data;

    if (responseData && typeof responseData === "object") {
      if (`event_index` in Object(responseData)) {
        defaultErrorMessage = `For event:${Number(Object.values(responseData).flat()[1])+1} --> ${Object.keys(responseData).flat()[0]}: ${Object.values(responseData).flat()[0]}`;
      } else {
        defaultErrorMessage = `${Object.keys(responseData).flat()[0]}: ${Object.values(responseData).flat()[0]}`;
      }
    } else if (responseData.message) {
      defaultErrorMessage = responseData.message;
    }

    const errorMessage = defaultErrorMessage || `Error: ${status} - An error occurred`;
    return [error, "error", errorMessage, status];
  } else if (error.response?.status === 500) {
    return [error, "error", "Oops! Something went wrong on the server. Our team is already working to fix it and try again later.", "Internal_server_error"];
  } else {
    return [error, defaultErrorMessage];
  }
}

function getErrorMessagesOfPutRequests(error) {
  let errorMessage = "An error occurred while processing your request.";

  if (error.response) {
    const responseData = error.response.data;

    if (responseData && typeof responseData === "object") {
      errorMessage = Object.values(responseData).flat()[0];
    } else if (responseData.message) {
      errorMessage = responseData.message;
    }
  }

  if (error.code === "ERR_NETWORK") {
    errorMessage = "Oops! Please check your internet connection and try again.";
  } else if (error.response?.status === 500) {
    errorMessage = "Oops! Something went wrong on the server. Our team is already working to fix it. Please try again later.";
  }

  return errorMessage;
}

export { getErrorMessage, getErrorMessagesOfPutRequests };
