import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

export default function AddDataBtn({ targetId, name, handleModal }) {
  return (
    <button
      onClick={handleModal}
      data-te-toggle="modal"
      data-te-target={targetId}
      data-te-ripple-init
      data-te-ripple-color="light"
      className="bg-violet-700 w-full rounded p-3 flex gap-2 justify-center items-center text-xs lg:text-sm font-semibold text-slate-100 cursor-pointer btn_shadow hover:bg-violet-800"
    >
      <AiOutlinePlusCircle className="text-xl text-slate-100" />
      <p>{name}</p>
    </button>
  );
}
