function dynamicFilter(data, filterKeys) {
  if(filterKeys === null) return data 
  else return data?.filter((item) => {
    return Object.keys(filterKeys)?.every((key) => {
      const filterValue = filterKeys[key];
      const itemValue = item[key] === null? "": item[key];

      if (typeof itemValue === "object") {
        if (Array.isArray(itemValue)) {
          return itemValue?.some((value) => value.id === filterValue.id);
        } else {
          const PascalKey = capitalizeFirstLetterKey(filterValue);
          return deepEqual(PascalKey, itemValue);
        }
      }
      return itemValue === filterValue;
    });
  });
}

function capitalizeFirstLetterKey(obj) {
  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
      newObj[capitalizedKey] = obj[key];
    }
  }
  return newObj;
}

function deepEqual(obj1, obj2) {
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export { dynamicFilter };
