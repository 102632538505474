import React, { useEffect, useMemo, useState } from "react";

import { GiTerror } from "react-icons/gi";
import Table from "../../components/table-comp/Table";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import { DataSorterV1 } from "../../lib/DataSorterV1";
import { httpGetTeamMembersTableData } from "../../requests/httpGetRequests";
import Loader from "../../components/shared-comp/Loader";


export default function TeamMembersContent() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");

  const getMembersTableData = useMemo(() => async () => {
    try {
      setTableLoading(true)
      const fetchedTeamMembersTableData = await httpGetTeamMembersTableData();
      if (fetchedTeamMembersTableData[1] === "success") {
        setData(fetchedTeamMembersTableData[0]);
        setTableLoading(false)
      } else {
        setCustomModalMsg(fetchedTeamMembersTableData[2]);
        setTableLoading(false)
        setCustomModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      setCustomModalMsg("An error occurred while fetching data.");
      setTableLoading(false)
      setCustomModalOpen(true);
    }

  }, []);




  useEffect(() => {
    getMembersTableData()
  }, [getMembersTableData]);


  const handleSorts = (TBdata, columnName, order) => {
    const sortedData = DataSorterV1(TBdata, columnName, order);
    setData(sortedData);
  };

  function closeModal() {
    setCustomModalOpen(false);
  }

  return (
    <div>
      <div className="flex gap-3 flex-col justify-between mt-5 md:flex-row items-center">
        <div className="w-full flex flex-col items-start gap-3">
          <h1 className="text-2xl font-semibold text-slate-500"> {data && data.length} Team Members</h1>
        </div>
        <CustomMessageModal title={""} icon={<GiTerror className="text-6xl text-indigo-700" />} msg={customModalMsg} isModalOpen={isCustomModalOpen} closeModal={closeModal} />
      </div>

      <div className="mt-10">
       {!tableLoading? <Table from="TeamMembersBillPage" data={data} handleSorts={handleSorts} />: <Loader loadingText="Table Loading" />}
      </div>
    </div>
  );
}
