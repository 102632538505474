import React, { useEffect, useMemo, useState } from "react";
import DropDown from "../../components/shared-comp/DropDown";
import { useNavigate } from "react-router";

import { GiTerror } from "react-icons/gi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Table from "../../components/table-comp/Table";
import AddEventModal from "./components/AddEventModal";
import { DataSorterV1 } from "../../lib/DataSorterV1";
import usePutData from "../../hooks/usePutData";
import { useSelector, useDispatch } from "react-redux";
import { backToInitialState } from "../../redux-store/eventTbSlice";

import TablePutConfirmationModal from "../../components/shared-comp/TablePutConfirmationModal";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import SaveChangesButton from "../../components/shared-comp/SaveChangesButton";
import AddDataBtn from "../../components/shared-comp/AddDataBtn";
import RowDeletionPrompt from "../../components/shared-comp/RowDeletionPrompt";
import { httpDeleteEventTableRow } from "../../requests/httpDeleteRequests";
import { dynamicFilter } from "../../lib/DataFilterv2";
import GenerateInvoiceModal from "./components/GenerateInvoiceModal";
import { httpSubmitGenerateBills } from "../../requests/httpPostRequests";
import { httpGetDropLists, httpGetEventsTableData } from "../../requests/httpGetRequests";
import { eventsTableFormator } from "../../lib/DataFormatorV1";
import Loader from "../../components/shared-comp/Loader";
// import { removeKeys } from "../../lib/DataFormatorV1";

const DropDownItems1 = [
  {
    title: "Active Events",
    fieldName: "active",
    value: true,
  },
  {
    title: "Past Events",
    fieldName: "active",
    value: false,
  },
];

const DropDownItems2 = [
  {
    title: "Prospective Events",
    fieldName: "event_status",
    value: "PR",
  },
  {
    title: "Confirmed Events",
    fieldName: "event_status",
    value: "C",
  },
  {
    title: "Completed Events",
    fieldName: "event_status",
    value: "D",
  },
  {
    title: "Shelved Events",
    fieldName: "event_status",
    value: "S",
  },
];

export default function EventsContent() {
  const { leads, add_ons, pkg,video_package, event_type } = useSelector((state) => state.eventTb);
  const { event_ids } = useSelector((state) => state.eventFormStates);

  const [dropListsData, setDropListsData] = useState([]);
 
  const [isModalOpen, setModalOpen] = useState(false);

  const [data, setData] = useState([]);
  const [eventsTableData, setEventsTableData] = useState([]);
  const [changedRows, setChangedRows] = useState([{}]);
  const [allChanges, setAllChanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isEventModalOpen, setEventModalOpen] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [putResponse, setPutResponse] = useState([]);
  const [postResponse, setPosResponse] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [isCustomSuccessModalOpen, setCustomSuccessModalOpen] = useState(false);
  const [isGenerateBillsModalOpen, setGenerateBillsModalOpen] = useState(false);
  const [showRowDeletionPrompt, setShowRowDeletionPrompt] = useState(false);
  const [rowToBeDeleted, setRowToBeDeleted] = useState({});
  const [filterKey, setFilterKey] = useState(null);

  const dispatch = useDispatch();

  const getEventsTableData = useMemo(() => async () => {
   try {
     setTableLoading(true)
     const fetchedEventsTableData = await httpGetEventsTableData();
     if (fetchedEventsTableData[1] === "success") {
       const formatedData = eventsTableFormator(fetchedEventsTableData[0]);
       setData(formatedData);
       setEventsTableData(formatedData)
       setTableLoading(false)
     } else {
       setCustomModalMsg(fetchedEventsTableData[2]);
       setCustomModalOpen(true);
       setTableLoading(false)
     }
   } catch (error) {
    setCustomModalMsg("An error occurred while fetching data.");
    setCustomModalOpen(true);
    setTableLoading(false)
   }
  }, []);


  useEffect(() => {
    getEventsTableData()
  }, [getEventsTableData]);


  useEffect(() => {
    const tempData = changedRows.map((item) => {
      if (item.index === undefined) {
        return [];
      } else
        return {
          index: item.index,
          id: item.id,
          discount: item.discount,
          venue: item.venue,
          date: item.date,
        };
    });
    const listBoxTableData = [...tempData, ...add_ons, ...leads, ...event_type, ...pkg, ...video_package];
    setAllChanges(listBoxTableData);
  }, [changedRows, add_ons, leads, event_type, pkg, video_package]);


  async function getDropList() {
    const fetchedLists = await httpGetDropLists();
    fetchedLists?.package?.push({ id: null, name: "One time (Custom)", budget: 0 });
    setDropListsData( fetchedLists );
  }

  useEffect(() => {
    getDropList()
  }, []);

  const handleSorts = (TBdata, columnName, order) => {
    const sortedData = DataSorterV1(TBdata, columnName, order);
    setData(sortedData);
  };

  //filter code=======================
  useEffect(() => {
    if (filterKey) {
      const filteredData = dynamicFilter(eventsTableData, filterKey);
      setData(filteredData);
    } else {
      setData(eventsTableData);
    }
  }, [filterKey, eventsTableData]);

  const handleFilter = (columnName, value, reset) => {
    if (reset) {
      setFilterKey(null);
    } else setFilterKey({ ...filterKey, [columnName]: value });
  };

  //===================

  function combineArrays() {
    // const combinedArray = [...arr1, ...arr2, ...arr3, ...arr4, ...arr5];
    const result = allChanges.reduce((acc, obj) => {
      const existingObj = acc.find((o) => o.index === obj.index);
      if (existingObj) {
        let newObj = { ...existingObj };
        newObj.index = obj.index; // update the index property of newObj
        Object.assign(newObj, obj);
        acc.splice(acc.indexOf(existingObj), 1, newObj);
      } else {
        obj.length === undefined && acc.push(obj);
      }

      return acc;
    }, []);

    return result;
  }

  const { getEventTablePutReqRes } = usePutData();

  const handleSaveChanges = () => {
    const updatedRows = combineArrays();
    //console.log(updatedRows);

    updatedRows.map(async (item) => {
      try {
        setLoading(true);
        const response = await getEventTablePutReqRes(item);
        setPutResponse((prevArray) => [...prevArray, response]);
        setLoading(false);
      } catch (error) {
        //console.error(error, "Error during making requests");
        setCustomModalMsg("An error occurred while saving changes. Please try again.");
        setCustomModalOpen(true);
      }
    });
    setChangedRows([{}]);
    dispatch(backToInitialState());
    setAllChanges([]);

    setModalOpen(true);
  };

  const handleGenerateBills = async () => {
    try {
      const response = await httpSubmitGenerateBills({ event_ids });
      if (response[1] === "success") {
        setPosResponse(response);
        setGenerateBillsModalOpen(true);
      } else {
        setCustomModalMsg(response[2]);
        setCustomModalOpen(true);
      }
    } catch (error) {
      //console.error(error, "Error during making requests");
      setCustomModalMsg("An error occurred while saving changes. Please try again.");
      setCustomModalOpen(true);
    }
  };

  function closeModal() {
    setPutResponse([]);
    setModalOpen(false);
    setCustomModalOpen(false);
  }

  //row deletion functions

  const requestDeleteRowData = async () => {
    console.log(rowToBeDeleted, "Confirmed");
    try {
      setLoading(true);
      const response = await httpDeleteEventTableRow(rowToBeDeleted);
      setLoading(false);
      if (response?.status === "success") {
        setCustomModalMsg(`The row has been deleted successfully`);
        setCustomSuccessModalOpen(true);
      } else {
        setCustomModalMsg(response?.res);
        setCustomModalOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setCustomModalMsg("An error occurred while saving changes. Please try again.");
      setCustomModalOpen(true);
    }
  };

  const handleDeleteRow = (rowData) => {
    setShowRowDeletionPrompt(true);
    setRowToBeDeleted(rowData);
  };

  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };

  function closeCustomSuccessModal() {
    setCustomSuccessModalOpen(false);
    refreshPage();
  }

  function closeGenerateBillsModal() {
    setGenerateBillsModalOpen(false);
    refreshPage();
  }

  function closeRowDeletionModal() {
    setShowRowDeletionPrompt(false);
  }

  // console.log(data)
  return (
    <div>
      <h1 className="text-2xl font-semibold text-slate-500">{data && data.length} Events</h1>

      <div className="flex flex-col justify-between mt-5 xl:flex-row gap-3 xl:items-start">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4">
          <DropDown handleFilter={handleFilter} DropDownItems={DropDownItems1} name={"Event Activity"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
          <DropDown handleFilter={handleFilter} DropDownItems={DropDownItems2} name={"Event Status"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
          <DropDown handleFilter={handleFilter} DropDownItems={dropListsData?.event_type} name={"Event Type"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
          <DropDown handleFilter={handleFilter} DropDownItems={dropListsData?.package} name={"Package"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
          <DropDown handleFilter={handleFilter} DropDownItems={dropListsData?.add_ons} name={"Add-ons"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
          <DropDown handleFilter={handleFilter} DropDownItems={dropListsData?.event_leads} name={"Lead By"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
        </div>
        <div className="mt-5 md:mt-0 w-full gap-3 xl:w-3/12 flex flex-col lg:flex-row justify-between lg:justify-between items-center">
          <SaveChangesButton changedRows={changedRows} allChanges={allChanges} loading={loading} handleSaveChanges={handleSaveChanges} />
          <AddDataBtn targetId={"#exampleModalXl"} handleModal={()=> setEventModalOpen(true)} name={"Add Events"} />
          <AddEventModal isEventModalOpen={isEventModalOpen} setEventModalOpen={setEventModalOpen} isFromClient={false} />
          <TablePutConfirmationModal from={"EventsContent"} putResponse={putResponse} isModalOpen={isModalOpen} closeModal={closeModal} />
          <CustomMessageModal title={""} icon={<GiTerror className="text-6xl text-indigo-700" />} msg={customModalMsg} isModalOpen={isCustomModalOpen} closeModal={closeModal} />
          <CustomMessageModal
            title={""}
            icon={<AiOutlineCheckCircle className="text-6xl text-green-700" />}
            msg={customModalMsg}
            isModalOpen={isCustomSuccessModalOpen}
            closeModal={closeCustomSuccessModal}
          />
          <GenerateInvoiceModal successRes={postResponse} isModalOpen={isGenerateBillsModalOpen} closeModal={closeGenerateBillsModal} />
          <RowDeletionPrompt isModalOpen={showRowDeletionPrompt} requestDeleteRowData={requestDeleteRowData} closeModal={closeRowDeletionModal} />
        </div>
      </div>

      <div className="mt-10">
      {!tableLoading ?  <Table
          from={"Events"}
          handleDeleteRow={handleDeleteRow}
          data={data}
          dropListsData={dropListsData}
          setChangedRows={setChangedRows}
          handleSorts={handleSorts}
          clearFilters={clearFilters}
          setClearFilters={setClearFilters}
          handleGenerateBills={handleGenerateBills}
        />: <Loader loadingText="Table Loading" />}
      </div>
    </div>
  );
}
