import React from "react";
import { Routes, Route } from "react-router-dom";
import SignInPage from "./pages/authentication/SignInPage";
import ClientsPage from "./pages/clients/ClientsPage";
import EventsPage from "./pages/events/EventsPage";
import CashinflowPage from "./pages/cash-inflows/CashinflowPage";
import CashoutflowPage from "./pages/cash-outflows/CashoutflowPage";
import AddOnsPage from "./pages/add-ons/AddOnsPage";
import EventPackagesPage from "./pages/event-packages/EventPackagesPage";
import HistoricalBillsPage from "./pages/historical-bills/HistoricalBillsPage";
import LedgerPage from "./pages/ledgers/LedgerPage";
import TeamBillsPage from "./pages/team-bills/TeamBillsPage";
import TeamMembersPage from "./pages/team-members/TeamMembersPage";
import PrivateOutlet from "./components/auth-comp/PrivateOutlet";

function App() {


  return (
    <>
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route path="/log-in" element={<SignInPage />} />
        <Route path="/" element={<PrivateOutlet />}>
          <Route path="/clients-events/clients" element={<ClientsPage />} />
          <Route path="/clients-events/events" element={<EventsPage />} />
          <Route path="/cash-flows/cashInFlows" element={<CashinflowPage />} />
          <Route path="/cash-flows/cashOutFlows" element={<CashoutflowPage />} />
          <Route path="/service-details/addOns" element={<AddOnsPage />} />
          <Route path="/service-details/eventPackages" element={<EventPackagesPage />} />
          <Route path="/income-statements/historical-bills" element={<HistoricalBillsPage />} />
          <Route path="/income-statements/ledgers" element={<LedgerPage />} />
          <Route path="/team/team-bills" element={<TeamBillsPage />} />
          <Route path="/team/team-members" element={<TeamMembersPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
