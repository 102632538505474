import React from "react";
import SidebarLayout from "../../layouts/SidebarLayout";
import TabBarLayout from "../../layouts/TabBarLayout";
import TeamBillsContent from "../../features/team/TeamBillsContent";

export default function TeamBillsPage() {
  return (
    <SidebarLayout>
      <TabBarLayout pathRoot={"/team"} pathNames={["Team Bills", "Team Members"]} paths={["/team-bills", "/team-members"]}>
        <TeamBillsContent />
      </TabBarLayout>
    </SidebarLayout>
  );
}
