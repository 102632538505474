import React from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';

export default function TabBar({pathRoot, pathNames, paths}) {

  const navigate = useNavigate();
  const {pathname} = useLocation();

  const handleClick = (path) => {
    navigate(`${pathRoot+path}`, { replace: true });
  };


  return (
    <nav>
      <ul
        className="mb-4 flex list-none flex-wrap border-b-0 pl-0 md:flex-row"
        data-te-nav-ref
      >
          <Link
            to={pathRoot+paths[0]}
            className={`uppercase text-xs font-semibold p-4 ${pathname===pathRoot+paths[0]? 'text-sky-300 border-b-2 border-sky-300':'text-sky-400'}`}
            onClick={()=>handleClick(paths[0])}
          >
            {pathNames[0]}
          </Link>
     
          <Link
           to={pathRoot+paths[1]}
            className={`uppercase text-xs font-semibold p-4 ${pathname===pathRoot+paths[1]? 'text-sky-300 border-b-2 border-sky-300':'text-sky-400'}`}
            onClick={()=>handleClick(paths[1])}
          >
            {pathNames[1]}
          </Link>
     
      </ul>
    </nav>
  );
}
