import { createSlice } from "@reduxjs/toolkit";

const teamMemberBillSlice = createSlice({
  name: "teamMemberBill",

  initialState: {
     covered_events_Arr:[],
  },

  reducers: {
    addToCoveredEvents(state, action){
      const { index, id, covered_events } = action.payload;
      const itemIndex = state.covered_events_Arr.findIndex((item) => item.index === index); 
      if (itemIndex === -1) {
        state.covered_events_Arr.push({ index, id, covered_events });
      } else {
        state.covered_events_Arr[itemIndex].covered_events = covered_events;
      }
  },
  },
});

export const { addToCoveredEvents } = teamMemberBillSlice.actions;
export default teamMemberBillSlice;
