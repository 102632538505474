import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./Table.css";
import { FiSearch } from "react-icons/fi";
import { FaSort } from "react-icons/fa";
import { AiOutlineClear } from "react-icons/ai";
// import { BsCheckCircleFill } from "react-icons/bs";
import { BiEditAlt, BiLinkExternal } from "react-icons/bi";
import { MdAddCircleOutline, MdDelete } from "react-icons/md";
import CopyLinkButton from "../shared-comp/CopyLinkButton";
import EventCellView from "../../features/clients-events/components/EventCellView";
import ListColumns from "../../features/clients-events/components/ListColumns";
import EventCellViewV2 from "../../features/clients-events/components/EventCellViewV2";
import FormCheckBox from "../../features/service-detail/components/FormCheckBox";
import { Link, useLocation } from "react-router-dom";
import EventFormCheckBox from "../../features/clients-events/components/EventFormCheckBox";
import { useSelector } from "react-redux";
import Loader from "../shared-comp/Loader";

export default function Table({ from, data, handleSorts, handleDeleteRow, setChangedRows, dropListsData, setOutFlowModalData, clearFilters, setClearFilters, handleGenerateBills }) {
  const { event_ids } = useSelector((state) => state.eventFormStates);
  //states
  const [editedData, setEditedData] = useState([]);
  const [order, setOrder] = useState(false);
  const [loading, setLoading] = useState(false);

  const { state, pathname } = useLocation();

  const getQueryDataFromUrl = useCallback(
    (key) => {
      if (key.client_name) {
        return data.filter((item) => item.client === key.client_name || item.full_name === key.client_name);
      } else if (key.eventID) {
        return data.filter((item) => item.id === key.eventID);
      } else {
        return data;
      }
    },
    [data]
  );
  
  useEffect(() => {
    setLoading(true);
    if (state) {
      setEditedData(getQueryDataFromUrl(state));
    } else {
      setEditedData(data);
    }
    setLoading(false);
  }, [data, getQueryDataFromUrl, state]);

  //extract Column keys(Names basically)
  const columns = Object.keys(data?.[0] ? data[0] : {});

  //necessary handle functions
  const handleOnChange = (e, id, item) => {
    const { name, value } = e.target;

    const editedDt = editedData?.map((item) => (item.index === id && name ? { ...item, [name]: value } : item));
    setEditedData(editedDt);
    const it = { ...item, [name]: value };

    setChangedRows((state) => {
      if (Object.keys(state[state?.length - 1]).length === 0) {
        return [it];
      } else {
        const index = state.findIndex((item) => item.index === id);

        if (index !== -1) {
          const newState = [...state];
          newState[index] = it;
          return newState;
        } else {
          return [...state, it];
        }
      }
    });
  };

  const MemoizedCellComponents = useMemo(() => {
    const handleCellComponents = (columnName, colData, rowData, idx) => {
      switch (columnName) {
        case "latest_invoice_link":
          return <CopyLinkButton colData={colData} />;
        case "invoice_link":
          return <CopyLinkButton colData={colData} />;
        case "active_events":
          return <EventCellView columnName={columnName} colData={colData} index={rowData.index} />;
        case "past_events":
          return <EventCellView columnName={columnName} colData={colData} index={rowData.index} />;
        case "event_type":
          return <ListColumns columnName={columnName} dropListsData={dropListsData?.event_type} colData={colData} index={rowData.index} eventID={rowData.id} />;
        case "package":
          return <ListColumns columnName={columnName} dropListsData={dropListsData?.package} colData={colData} index={rowData.index} eventID={rowData.id} />;
        case "video_package":
          return <ListColumns columnName={columnName} dropListsData={dropListsData?.video_package} colData={colData} index={rowData.index} eventID={rowData.id} />;
        case "add_ons":
          return <ListColumns columnName={columnName} dropListsData={dropListsData?.add_ons} colData={colData} index={rowData.index} eventID={rowData.id} />;
        case "lead_by":
          return <ListColumns columnName={columnName} dropListsData={dropListsData?.event_leads} colData={colData} index={rowData.index} eventID={rowData.id} />;
        case "covered_events":
          return <ListColumns columnName={columnName} dropListsData={dropListsData} colData={colData} index={rowData.index} eventID={rowData.id} />;
        case "covered_by":
          return <EventCellViewV2 columnName={columnName} colData={colData} index={rowData.index} />;
        case "active":
          return <FormCheckBox columnName={columnName} colData={colData} index={idx} pkgID={rowData.id} />;
        case "events":
          return <EventCellViewV2 columnName={columnName} colData={colData} index={idx} />;
        case "expenses_type":
          return (
            <>
              <div className="w-full flex justify-end md:justify-between items-center">
                {colData}
                {(colData === "Payroll" && rowData.is_active === true) && (
                  <MdAddCircleOutline
                    onClick={() => setOutFlowModalData(rowData)}
                    data-te-toggle="modal"
                    data-te-target="#addCashOutflowModalXl"
                    className="text-xl cursor-pointer text-green-600"
                  />
                )}
              </div>
            </>
          );
        case "claim_conflict":
          return (
            <div key={idx} className="w-full flex justify-end md:justify-start items-center">
              {colData.map((item, i) => (
                <div key={i}>
                  <p className="text-sm text-slate-600">{item}</p>
                </div>
              ))}
            </div>
          );
        default:
          return "----";
      }
    };

    return handleCellComponents;
  }, [dropListsData, setOutFlowModalData]);

  const handleSortingType = (column) => {
    setOrder(!order);
    if (column !== "status" && order) {
      handleSorts(editedData, column, "asc");
    } else if (column !== "status" && !order) {
      handleSorts(editedData, column, "desc");
    } else if (column === "status" && order) {
      handleSorts(editedData, column, "Active");
    } else {
      handleSorts(editedData, column, "Inactive");
    }
  };

  // Update the clearFilters state in the parent component
  const handleClearFilters = () => {
    clearFilters ? setClearFilters(!clearFilters) : setClearFilters(true);
  };

  //Need to improve search functionality
  function handleSearch(event) {
    if (event.target.value.length === 0) {
      setLoading(true);
      setEditedData(data);
      setLoading(false);
    } else {
      setEditedData(data.filter((item) => (item.full_name || item.client || item.name || item.source).toLowerCase().includes(event.target.value.toLowerCase().trim())));
    }
  }

  const convertToTitleCase = useMemo(() => {
    return (str) => {
      const words = str.toLowerCase().split("_");
      const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      const titleCaseStr = titleCaseWords.join(" ");
      return titleCaseStr;
    };
  }, []);

  return (
    <>
      <div className="flex flex-col md:flex-row items-center md:justify-between border-b-1 border-slate-300 mb-8">
        <div className="flex justify-center md:justify-start items-center w-full md:w-1/2 border-b-1 md:border-b-0 py-2 md:py-0">
          <FiSearch size={20} className="cursor-pointer text-slate-400 mr-2" />
          <input className="outline-none w-3/5 text-sm py-2" placeholder="Search name here" type="text" onChange={handleSearch} />
        </div>

        <div className="flex gap-2 mt-3">
          {
            from === "Events" && (
              <button onClick={handleGenerateBills} disabled={event_ids?.length === 0} className={`${event_ids?.length === 0 ? "bg-[#B2A2B9]" : "bg-[#bd71de]"} py-2 px-3 rounded-md shadow-md text-white mb-3 font-bold hover:shadow-lg hover:bg-indigo-700`}>Create Partial Bill ({event_ids?.length}) </button>
            )
          }
          {from === "AddOnPage" || from === "TeamMembersBillPage" || (
            <Link to={`${pathname}`} className="bg-indigo-500 px-3 rounded-md shadow-md text-white mb-3 font-bold hover:shadow-lg hover:bg-indigo-700">
              <button onClick={handleClearFilters} className="flex items-center justify-center gap-2 h-10">
                <AiOutlineClear /> Clear all filters
              </button>
            </Link>
          )}
        </div>
      </div>
      <div className="table_container">
        {loading && (
          <Loader loadingText="Table Processing" />
        )}
        {!loading && data && data.length === 0 ? (
          <div className="w-full flex justify-center items-center gap-5">
            <p className="text-sm text-slate-600">No Data Found !</p>
          </div>
        ) : (
          <div>
            <table className={`${editedData?.length === 0 && "hidden"}`}>
              <thead>
                <tr>
                  {from === "Events" && <th className="bg-slate-100"></th>}
                  {columns?.map((column) => (
                    <th
                      className={`${(column === "id" ||
                        column === "client_id" ||
                        column === "month-year" ||
                        column === "month_year" ||
                        column === "is_active" ||
                        column === "cleared" ||
                        column === "status" ||
                        column === "payment_status" ||
                        column === "event_status" ||
                        (from !== "EventPackages" && column === "active")) &&
                        "hidden"
                        } bg-slate-100`}
                      key={column}
                      scope="col"
                    >
                      <div className="flex items-center gap-5">
                        <p className="text-indigo-800">{column === "index" ? "SL No." : convertToTitleCase(column)}</p>
                        {(column === "full_name" ||
                          column === "total_revenue" ||
                          column === "date" ||
                          column === "venue" ||
                          column === "client" ||
                          column === "cash" ||
                          column === "advance_received" ||
                          column === "gross_profit" ||
                          column === "revenue" ||
                          column === "overhead_expenses" ||
                          column === "net_profit" ||
                          column === "budget") && (
                            <div onClick={() => handleSortingType(column)} className="p-1 bg-indigo-600 rounded-lg cursor-pointer hover:bg-indigo-500 ">
                              <FaSort className={`text-slate-50`} size={15} />
                            </div>
                          )}
                      </div>
                    </th>
                  ))}
                  <th className={`${from === "Clients" || from === "Events" || from === "CashInflow" ? "" : "hidden"} text-indigo-800 bg-slate-100 h-full`}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {editedData?.map((row, index) => (
                  <tr key={index} className={`${event_ids.includes(row.id) && "bg-slate-100"} hover:bg-sky-100`}>
                    {from === "Events" && (
                      <td className="bg-slate-100">
                        <EventFormCheckBox id={row.id} />
                      </td>
                    )}
                    {Object.keys(row).map((col, i) => {
                      if (
                        Array.isArray(row[col]) ||
                        (typeof row[col] === "object" && row[col] !== null) ||
                        col === "latest_invoice_link" ||
                        col === "invoice_link" ||
                        col === "add_ons" ||
                        col === "package" ||
                        col === "event_type" ||
                        col === "video_package" ||
                        col === "active" ||
                        col === "expenses_type"
                      ) {
                        return (
                          <td className={`${from !== "EventPackages" && col === "active" ? "hidden" : ""} text-slate-600 `} key={i} data-label={convertToTitleCase(col)}>
                            <div className=" flex justify-end md:justify-start items-center">{MemoizedCellComponents(col, row[col], row, index)}</div>
                          </td>
                        );
                      }
                      return (
                        <td
                          key={i * 0.1}
                          data-label={convertToTitleCase(col)}
                          className={`${(col === "id" ||
                            col === "client_id" ||
                            col === "month-year" ||
                            col === "month_year" ||
                            col === "is_active" ||
                            col === "cleared" ||
                            col === "status" ||
                            col === "payment_status" ||
                            col === "event_status") &&
                            "hidden"
                            } text-sm text-slate-600`}
                        >
                          {col === "discount" ||
                            col === "venue" ||
                            col === "full_name" ||
                            col === "phone_number" ||
                            (from === "Events" && col === "date") ||
                            (from === "CashInflow" && col === "amount") ||
                            (row.expenses_type === "Overhead" && (col === "source_of_expense" || col === "amount")) ||
                            (!row.cleared && (col === "adjustment" || col === "comment")) ? (
                            <div className="flex items-center justify-end md:justify-start md:gap-3">
                              <input
                                name={col}
                                value={row[col]}
                                className=" text-end md:text-start bg-slate-50 border-2 w-3/4 md:w-11/12 outline-none px-2 h-10 focus:bg-slate-100 rounded-md no_spinner"
                                type={
                                  /^-?\d+\.?\d*$/.test(row[col])
                                    ? "number"
                                    : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(row[col])
                                      ? "email"
                                      : /^\d{4}-\d{2}-\d{2}$/.test(row[col])
                                        ? "date"
                                        : "text"
                                }
                                onChange={(e) => {
                                  handleOnChange(e, row.index, row);
                                }}
                              />
                              <BiEditAlt className="h-5 w-5 text-gray-400 hidden md:block" aria-hidden="true" />
                              {col === "full_name" && (
                                <Link to={`/clients-events/events?search_events=${row[col].replace(/\s+/g, "")}`} state={{ client_name: row[col] }}>
                                  <BiLinkExternal className="text-blue-600 hover:text-blue-800 cursor-pointer" aria-hidden="true" />
                                </Link>
                              )}
                            </div>
                          ) : (
                            <div className="lg:flex lg:justify-between lg:items-center">
                              <p>{col === "index" ? row[col] + 1 : row[col]}</p>
                              {(col === "client" || col === "source") && (
                                <Link to={`/clients-events/clients?search_clients=${row[col].replace(/\s+/g, "")}`} state={{ client_name: row[col] }}>
                                  <BiLinkExternal className=" cursor-pointer text-blue-600 hover:text-blue-800" aria-hidden="true" />
                                </Link>
                              )}
                            </div>
                          )}
                        </td>
                      );
                    })}
                    <td className={`${from === "Clients" || from === "Events" || from === "CashInflow" ? "" : "hidden"}`}>
                      <button
                        onClick={() => handleDeleteRow(row)}
                        className="text-sm flex items-center text-slate-600 hover:text-red-600 bg-slate-200 hover:bg-slate-300 shadow-md py-2 px-4 rounded-md"
                      >
                        <MdDelete className="text-md" />
                        <p>Delete</p>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
