import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
// import { GiTerror } from "react-icons/gi";

export default function CustomMessageModal({title, icon,msg, isModalOpen, closeModal }) {
  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative" onClose={closeModal} style={{ zIndex: "1200" }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="h-auto justify-center w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <p className="font-semibold text-lg text-slate-800">{title}</p>
                    <button>
                      <AiOutlineClose
                        data-te-dismiss="modal"
                        data-te-target="#exampleModalXl"
                        aria-label="Close"
                        onClick={closeModal}
                        className="text-2xl text-slate-700 cursor-pointer"
                      />
                    </button>
                  </div>

                  <div className="py-5 flex flex-col items-center gap-3"> 
                     {icon}
                     <p className="text-slate-700">{msg}</p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
