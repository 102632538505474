import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AiOutlineClose, AiOutlineCheckCircle } from "react-icons/ai";
import { GiTerror } from "react-icons/gi";
// import Table from "../../../components/table-comp/Table";
import { httpSubmitTeamMemberBills } from "../../../requests/httpPostRequests";
import CustomMessageModal from "../../../components/shared-comp/CustomMessageModal";
import TeamBillListBox from "./TeamBillListBox";
import SubmitFormBtn from "../../../components/shared-comp/SubmitFormBtn";
// import usePutData from "../../../hooks/usePutData";

export default function AddTeamMemberBills({ listData }) {
  const navigate = useNavigate();
  const [defaultInfo, setDefaultInfo] = useState({});
  const [events, setCoveredEvents] = useState([]);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isErrModalOpen, setErrModalOpen] = useState(false);
  const [errModalMsg, setErrModalMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);

  useEffect(() => {
    const now = new Date();
    const month = now.getMonth(); // add 1 to zero-based month index
    const year = now.getFullYear();
    setDefaultInfo({ month, year });
  }, []);

  async function handleOnSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    const adjustment = data.get("adjustment");
    const comment = data.get("comment");
    const month = data.get("month");
    const year = data.get("year");
    //console.log(month, year, comment, adjustment, events);

    try {
      setLoading(true);
      const response = await httpSubmitTeamMemberBills({ events, adjustment, comment, month, year });
      setResponse(response);
      if (response[1] === "success") {
        setSuccessModalOpen(true);
      }
      else{
        setErrModalMsg(response[2])
        setErrModalOpen(true);
      }
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setErrModalMsg("An error occurred while submitting the form")
      setErrModalOpen(true);
    }
  }

  const refreshPage = () => {
    navigate(0);
  };

  function closeSuccessModal() {
    setSuccessModalOpen(false);
    refreshPage();
  }

  function closeErrModal() {
    setErrModalOpen(false);
  }

  return (
    <div
      data-te-modal-init
      className={`fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
      id="addTeamMembersBill"
      tabIndex={-1}
      aria-labelledby="addTeamMembersBill"
      aria-modal="true"
      role="dialog"
      // ref={lgModalRef}
    >
      <div
        data-te-modal-dialog-ref
        className="flex items-center pointer-events-none relative h-[calc(100%-1rem)] w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
      >
        <form
          onSubmit={handleOnSubmit}
          className="pointer-events-auto relative flex max-h-[100%] overflow-hidden w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none "
        >
          <div className="shadow px-10 flex justify-between flex-shrink-0 items-center rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <h5 className="text-lg lg:text-xl font-bold leading-normal text-neutral-800" id="addTeamMembersBill">
              Add Team Members Bill
            </h5>

            <button
              type="button"
              className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              data-te-modal-dismiss
              aria-label="Close"
            >
              <AiOutlineClose size={24} />
            </button>
          </div>
          <div className="relative overflow-y-auto p-4 px-10 pb-10">
            <div className="flex flex-col gap-5">
              {/* Row 1 */}
              <div className="flex flex-col lg:flex-row gap-3 justify-between items-start">
                <div className="flex flex-col lg:w-3/6 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="source_of_expense">
                    Events
                  </label>
                  <TeamBillListBox listData={listData} setCoveredEvents={setCoveredEvents} multiple={true} />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.events?.[0]}</p>}
                </div>

                <div className="flex flex-col lg:w-3/6 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="month">
                    Month
                  </label>
                  <input
                    className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md no_spinner"
                    type="number"
                    name="month"
                    defaultValue={defaultInfo.month}
                    placeholder="Enter the month here"
                  />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.month?.[0]}</p>}
                </div>
              </div>

              {/* 2nd row */}
              <div className="flex flex-col lg:flex-row gap-3 items-start">
                <div className="flex flex-col lg:w-3/6 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="year">
                    Year
                  </label>
                  <input
                    className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md no_spinner"
                    type="number"
                    name="year"
                    defaultValue={defaultInfo.year}
                    placeholder="Enter the year here"
                  />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.year?.[0]}</p>}
                </div>
                <div className="flex flex-col lg:w-3/6 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="adjustment">
                    Adjustment
                  </label>
                  <input className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md no_spinner" type="number" name="adjustment" defaultValue={0} placeholder="Enter the amount here" />

                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.adjustment?.[0]}</p>}
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-3 ">
                
                <div className=" flex flex-col w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="comment">
                    Comment
                  </label>
                  <textarea className="py-3 text-sm outline-none px-2 h-24 bg-slate-200 rounded-md" type="text" name="comment" placeholder="Type your comments here" />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.comment?.[0]}</p>}
                </div>
              </div>
            </div>
          </div>
          {response?.[1] === "error" && response?.[0].response.status === 500 && <p className="text-xs text-center pb-5 text-red-700 mt-1">500: Internal server Error!</p>}
          <div className="shadow flex flex-shrink-0 flex-wrap gap-3 items-center justify-end px-10 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <SubmitFormBtn loading={loading} name={"Save Bill"} />
            <CustomMessageModal
              title={"Confirmation"}
              icon={<AiOutlineCheckCircle className="text-6xl text-green-700" size={70} />}
              msg={"Form has been saved Successfully!"}
              isModalOpen={isSuccessModalOpen}
              closeModal={closeSuccessModal}
            />
            <CustomMessageModal
            title={""}
            icon={<GiTerror className="text-6xl text-indigo-700" />}
            msg={errModalMsg}
            isModalOpen={isErrModalOpen}
            closeModal={closeErrModal}
          />
          </div>
        </form>
      </div>
    </div>
  );
}
