import { useCallback, useEffect, useState } from "react";
import { httpGetClientPendingPayment } from "../requests/httpGetRequests";

export default function useClientPaymentPending() {
  const [clientPaymentPending, saveClientPaymentPending] = useState([]);

  const getClientPendingPayments = useCallback(async () => {
    const fetchedClientPendingPayments = await httpGetClientPendingPayment();
    if (fetchedClientPendingPayments[1] === "success") {
      saveClientPaymentPending(fetchedClientPendingPayments[0]);
    }else{

    }
  }, []);

  useEffect(() => {
    getClientPendingPayments();
  }, [getClientPendingPayments]);

  return {
    clientPaymentPending,
  };
}
