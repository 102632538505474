import React from "react";
import TabBar from "../components/layout-comp/TabBar";

export default function TabBarLayout({ children, pathRoot, pathNames, paths }) {
  return (
    <>
      <TabBar pathRoot={pathRoot} pathNames={pathNames} paths={paths}/>
      {children}
    </>
  );
}
