import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import companyLogo from "../../assets/images/Logo.png";
import { MdWorkOutline, MdArrowForwardIos } from "react-icons/md";
import { RiHandCoinLine, RiTeamLine, RiBillLine } from "react-icons/ri";
import { TbCurrencyTaka } from "react-icons/tb";
import { BiLogOutCircle } from "react-icons/bi";

export default function Sidebar({ toggleMargin }) {
  const [user, setUser] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const data = JSON.parse(window.localStorage.getItem("USER"));
    setUser(data);
  }, []);

  const [open, setOpen] = useState(false);
  const role = user?.role;
  let menus;
  if (role === "Admin") {
    menus = [
      { name: "Clients & Events", link: "/clients-events/clients", icon: MdWorkOutline },
      { name: "Finance", link: "/cash-flows/cashInFlows", icon: TbCurrencyTaka },
      { name: "Income Statement", link: "/income-statements/ledgers", icon: RiBillLine },
      { name: "Service Detail", link: "/service-details/addOns", icon: RiHandCoinLine },
      { name: "Team members", link: "/team/team-bills", icon: RiTeamLine },
    ];
  } else if (role === "Manager") {
    menus = [
      { name: "Clients & Events", link: "/clients-events/clients", icon: MdWorkOutline },
      { name: "Service Detail", link: "/service-details/addOns", icon: RiHandCoinLine },
      { name: "Finance", link: "/cash-flows/cashInFlows", icon: TbCurrencyTaka },
      { name: "Team members", link: "/team/team-bills", icon: RiTeamLine },
    ];
  } else {
    menus = [
      { name: "Clients & Events", link: "/clients-events/clients", icon: MdWorkOutline },
      { name: "Team members", link: "/team/team-bills", icon: RiTeamLine },
    ];
  }

  const isSidebarCollapsed = () => {
    setOpen(!open);
    toggleMargin();
  };


  function handleLogOut() {
    let loginURL = `${process.env.REACT_APP_API_URL}/user-logout`
    console.log(loginURL)
    axios
      .post(loginURL, {
        withCredentials: true,
      })
      .then((response) => {
        response.status === 200 && navigate("/log-in");
      })
      .then((error) => {
        //console.log(error);
      });
  }

  return (
    <div className="md:block hidden fixed rounded-lg">
      <div className={`bg-slate-200 min-h-screen relative ${open ? "w-64 pr-10" : "w-16"} duration-500 text-gray-100 px-4 md:block shadow`}>
        <div className="relative">
          <div className="py-3 flex justify-center items-center absolute top-5 -right-11 bg-slate-200 rounded-full w-11 shadow-lg" onClick={isSidebarCollapsed}>
            <MdArrowForwardIos size={20} className={`cursor-pointer ${open && "rotate-180"}`} color="black" />
          </div>
        </div>
        <div className="flex flex-col gap-32">
          <div>
            <div className="flex items-center mt-20 gap-2">
              <img src={companyLogo} className="w-10" alt="BigCo Inc. logo" />
              {open && (
                <h3 className="text-slate-500 font-semibold text-2xl break-words">
                  RP Billing
                </h3>
              )}
            </div>

            <div className="flex gap-3 items-center mt-6">
              <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className="rounded-full w-10" alt="" loading="lazy" />
              {open && (
                <div className="flex flex-col ">
                  <h3 className="text-slate-700 font-bold whitespace-pre text-sm break-words">{user?.name}</h3>
                  <p className="text-slate-600 text-xs">{user?.admin ? "Admin" : "Team member"}</p>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-3 ">
            {menus?.map((menu, i) => (
              <Link
                to={menu?.link}
                key={i}
                className={` ${menu?.margin && "mt-5"} group flex items-center text-sm text-slate-900 gap-3.5 font-medium p-2 hover:bg-[#e3e3de] rounded-md`}
              >
                <div className="">
                  {React.createElement(menu?.icon, {
                    size: "20",
                    color: "black",
                  })}
                </div>
                <h2
                  style={{
                    transitionDelay: `${i}00ms`,
                  }}
                  className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"}`}
                >
                  {menu?.name}
                </h2>
                <h2
                  className={`${open && "hidden"
                    } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
                >
                  {menu?.name}
                </h2>
              </Link>
            ))}
          </div>
          <div>
            <button data-te-toggle="tooltip" title="Log out" onClick={() => handleLogOut()} className="text-slate-900 bg-indigo-700 rounded-full p-2 shadow-md">
              <BiLogOutCircle className="text-slate-50" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
