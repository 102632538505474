import axios from "axios";
import { getErrorMessage } from "../lib/ErrorHandler";

const API_URL = process.env.REACT_APP_API_URL;

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

async function httpSubmitLogInForm(formData) {
  try {
    const getCSRF = await axios.get(`${API_URL}/setcsrf`);
    if (getCSRF.status === 200) {
      try {
        const response = await axios.post(`${API_URL}/user-login`, formData);
        //console.log(response, "from http file");
        return [response?.data, "success"];
      } catch (error) {
        console.log(error, "from login api");
        const errorData = getErrorMessage(error);
        return errorData;
      }
    } else {
      console.log("csrf data not set lol");
      return ["error", "csrf data not set"];
    }
  } catch (error) {
    console.log(error, "from csrf api");
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpSubmitEventForm(formData) {
  try {
    const response = await axios.post(`${API_URL}/cea-registration`, formData);
    console.log(response, "from http file h");
    return [response?.data, "success"];
  } catch (error) {
    console.log(error, "from http file hi");
    const errorData = getErrorMessage(error);
    return errorData;
  }
}


async function httpSubmitGenerateBills(formData) {
  try {
    const response = await axios.post(`${API_URL}/generate-partial-bill`, formData);
    return [response?.data, "success"];
  } catch (error) {
    console.log(error, "from http file hi");
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpSubmitCashInflowForm(formData) {
  try {
    const response = await axios.post(`${API_URL}/cashinflows/`, formData);
    //console.log(response, "from http file");
    return [response?.data, "success"];
  } catch (error) {
    //console.log(error, "from http file");
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpSubmitCashOutflowForm(formData) {
  try {
    const response = await axios.post(`${API_URL}/cashoutflows/`, formData);
    //console.log(response, "from http file");
    return [response?.data, "success"];
  } catch (error) {
    // console.log(error, "from http file");
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpSubmitTeamMemberBills(formData) {
  try {
    const response = await axios.post(`${API_URL}/teammemberbills/`, formData);
    //console.log(response, "from http file");
    return [response?.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpSubmitAddOnForm(formData) {
  try {
    const response = await axios.post(`${API_URL}/add-ons/`, formData);
    //console.log(response, "from http file addons");
    return [response?.data, "success"];
  } catch (error) {
    //console.log(error, "from http file addons");
    return [error, "error"];
  }
}

async function httpSubmitPackages(formData) {
  try {
    const response = await axios.post(`${API_URL}/packages/`, formData);
    //console.log(response, "from http file addons");
    return [response?.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

export { httpSubmitLogInForm, httpSubmitEventForm,httpSubmitGenerateBills, httpSubmitAddOnForm, httpSubmitCashInflowForm, httpSubmitCashOutflowForm, httpSubmitTeamMemberBills, httpSubmitPackages };
