import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import DropDown from "../../components/shared-comp/DropDown";
import { GiTerror } from "react-icons/gi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import AddEventModal from "./components/AddEventModal";
import Table from "../../components/table-comp/Table";
import { DataSorterV1 } from "../../lib/DataSorterV1";
import usePutData from "../../hooks/usePutData";
import TablePutConfirmationModal from "../../components/shared-comp/TablePutConfirmationModal";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import SaveChangesButton from "../../components/shared-comp/SaveChangesButton";
import AddDataBtn from "../../components/shared-comp/AddDataBtn";
import { httpDeleteClientTableRow } from "../../requests/httpDeleteRequests";
import { dynamicFilter } from "../../lib/DataFilterv2";
import RowDeletionPrompt from "../../components/shared-comp/RowDeletionPrompt";
import { httpGetClientsTableData } from "../../requests/httpGetRequests";
import { clientsTableFormator } from "../../lib/DataFormatorV1";
import Loader from "../../components/shared-comp/Loader";

const DropDownItems = [
  {
    title: "Active Clients",
    fieldName: "status",
    value: "Active",
  },
  {
    title: "Inactive Clients",
    fieldName: "status",
    value: "Inactive",
  },
];

export default function ClientsContent() {
  const [data, setData] = useState([]);
  const [clientsTableData, setClientsTableData] = useState([]);
  const [changedRows, setChangedRows] = useState([{}]);
  const [clientMonthYearDropLists, setClientMonthYearDropLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEventModalOpen, setEventModalOpen] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [putResponse, setPutResponse] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [isCustomSuccessModalOpen, setCustomSuccessModalOpen] = useState(false);
  const [showRowDeletionPrompt, setShowRowDeletionPrompt] = useState(false);
  const [rowToBeDeleted, setRowToBeDeleted] = useState({});
  const [filterKey, setFilterKey] = useState();


  const getClientsTableData = useMemo(() => async () => {
    try {
      setTableLoading(true)
      const fetchedClientsTableData = await httpGetClientsTableData();
      if (fetchedClientsTableData[1] === "success") {
        const formatedData = clientsTableFormator(fetchedClientsTableData[0]);
        setData(formatedData);
        setClientsTableData(formatedData)
        setTableLoading(false)
        const uniqueSources = new Set();
        const filteredData = [];
        for (const item of formatedData) {
          if (!uniqueSources.has(item.month_year) && item.month_year !== null) {
            uniqueSources.add(item.month_year);
            filteredData.push(item);
          }
        }
        setClientMonthYearDropLists(filteredData);
      } else {
        setCustomModalMsg(fetchedClientsTableData[2]);
        setCustomModalOpen(true);
        setTableLoading(false)
      }
    } catch (error) {
      // console.error(error);
      setCustomModalMsg("An error occurred while fetching data.");
      setCustomModalOpen(true);
      setTableLoading(false)
    } 
  }, []);


  useEffect(() => {
    getClientsTableData();
  }, [getClientsTableData]);


  useEffect(() => {
    console.log(filterKey)
    if (filterKey) {
      const filteredData = dynamicFilter(clientsTableData, filterKey);
      setData(filteredData);
    } else {
      setData(clientsTableData);
    }
  }, [filterKey, clientsTableData]);

  const handleFilter = (columnName, value, reset) => {
   
    if (reset) {
      setFilterKey(null);
    } else setFilterKey({ ...filterKey, [columnName]: value });
  };
  
  
  const handleSorts = (TBdata, columnName, order) => {
    const sortedData = DataSorterV1(TBdata, columnName, order);
    setData(sortedData);
  };

  //Row Put operation functions

  const { getClientTablePutReqRes } = usePutData();

  const handleSaveChanges = () => {
    changedRows.map(async (item) => {
      try {
        setLoading(true);
        const response = await getClientTablePutReqRes(item);
        setPutResponse((prevArray) => [...prevArray, response]);
        setLoading(false);
        //console.log(response);
      } catch (error) {
        //console.log(error, "Error during making requests");
        setLoading(false);
        setCustomModalMsg("An error occurred while saving changes. Please try again.");
        setCustomModalOpen(true);
      }
    });
    setChangedRows([{}]);
    setModalOpen(true);
  };

  function closeModal() {
    setPutResponse([]);
    setModalOpen(false);
    setCustomModalOpen(false);
  }

  //row deletion functions

  const requestDeleteRowData = async () => {
    console.log(rowToBeDeleted, "Confirmed");
    try {
      setLoading(true);
      const response = await httpDeleteClientTableRow(rowToBeDeleted);
      setLoading(false);
      if (response?.status === "success") {
        setCustomModalMsg(`The row has been deleted successfully`);
        setCustomSuccessModalOpen(true);
      } else {
        setCustomModalMsg(response?.res);
        setCustomModalOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setCustomModalMsg("An error occurred while saving changes. Please try again.");
      setCustomModalOpen(true);
    }
  };

  const handleDeleteRow = (rowData) => {
    setShowRowDeletionPrompt(true);
    setRowToBeDeleted(rowData);
  };

  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };

  function closeCustomSuccessModal() {
    setCustomSuccessModalOpen(false);
    refreshPage();
  }

  function closeRowDeletionModal() {
    setShowRowDeletionPrompt(false);
  }

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h1 className="text-2xl font-semibold text-slate-500">{data && data.length} Clients</h1>
        <div className="md:mt-0 mt-5 flex gap-3 text-sm font-semibold text-slate-500">
          <div className="flex gap-2 items-center">
            <div className="w-3.5 h-3.5 rounded-full bg-indigo-500"></div>
            <div>Event Name</div>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-3.5 h-3.5 rounded-full bg-[#B058C8]"></div>
            <div>Package</div>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-3.5 h-3.5 rounded-full bg-[#96BBE8]"></div>
            <div>Date</div>
          </div>
        </div>
      </div>

      <div className="text-sm text-slate-600 flex flex-col justify-between mt-5 xl:flex-row gap-3">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
          <DropDown
          //  defaultDropItem={{
          //   title: "Active Clients",
          //   isDefault:true
          // }}
            DropDownItems={DropDownItems}
            handleFilter={handleFilter}
            name={"Client Activity"}
            clearFilters={clearFilters}
            setClearFilters={setClearFilters}
          />
          <DropDown DropDownItems={clientMonthYearDropLists} handleFilter={handleFilter} name={"Month-Year"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
        </div>

        <div className="mt-5 md:mt-0 w-full gap-3 xl:w-3/12 flex flex-col lg:flex-row justify-between lg:justify-between items-center">
          <SaveChangesButton changedRows={changedRows} loading={loading} handleSaveChanges={handleSaveChanges} />
          <AddDataBtn targetId={"#exampleModalXl"} handleModal={() => setEventModalOpen(true)} name={"Add Events"} />
          <AddEventModal isEventModalOpen={isEventModalOpen} setEventModalOpen={setEventModalOpen} isFromClient={true} />
          <TablePutConfirmationModal from={"ClientsContent"} putResponse={putResponse} isModalOpen={isModalOpen} closeModal={closeModal} />
          <CustomMessageModal title={""} icon={<GiTerror className="text-6xl text-indigo-700" />} msg={customModalMsg} isModalOpen={isCustomModalOpen} closeModal={closeModal} />
          <CustomMessageModal
            title={""}
            icon={<AiOutlineCheckCircle className="text-6xl text-green-700" />}
            msg={customModalMsg}
            isModalOpen={isCustomSuccessModalOpen}
            closeModal={closeCustomSuccessModal}
          />
          <RowDeletionPrompt isModalOpen={showRowDeletionPrompt} requestDeleteRowData={requestDeleteRowData} closeModal={closeRowDeletionModal} />
        </div>
      </div>

      <div className="mt-10">
      {!tableLoading?
        <Table
          from="Clients"
          data={data}
          handleDeleteRow={handleDeleteRow}
          handleSorts={handleSorts}
          setChangedRows={setChangedRows}
          clearFilters={clearFilters}
          setClearFilters={setClearFilters}
        /> : <Loader loadingText="Table Loading"/> }
      </div>
    </div>
  );
}
