import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiCheck } from "react-icons/hi";
import { BiEditAlt } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { addToAddOns, addToPackage, addToVideoPackage, addToEventType, addToLeadBy } from "../../../redux-store/eventTbSlice";
import { addToCoveredEvents } from "../../../redux-store/teamMemberBillSlice";

export default function ListBoxTable({ listData, multiple, colData, formType, index, eventID }) {
  const [selected, setSelected] = useState([]);

  const posted_add_ons = useSelector((state) => state.eventTb.posted_add_ons);

  const dispatch = useDispatch();
  const handleMultipleItems = useCallback(
    (items) => {
      setSelected(items);

      switch (formType) {
        case "event_type":
          const eventType = Object.values(items)[0];
          dispatch(addToEventType({ index, id: eventID, [formType]: eventType }));
          break;
        case "package":
          const pkg = items.id;
          dispatch(addToPackage({ index, id: eventID, [formType]: pkg }));
          break;
        case "video_package":
          const video_package = items.id;
          dispatch(addToVideoPackage({ index, id: eventID, [formType]: video_package }));
          break;
        case "add_ons":
          const addOnsId = items.map((item) => item.id);
          dispatch(addToAddOns({ index, id: eventID, [formType]: addOnsId }));
          break;
        case "leads":
          const leadById = items.map((item) => item.id);
          dispatch(addToLeadBy({ index, id: eventID, [formType]: leadById }));
          break;
        case "covered_events":
          const coveredEventsId = items.map((item) => item.id);
          dispatch(addToCoveredEvents({ index, id: eventID, [formType]: coveredEventsId }));
          break;
        default:
          break;
      }
    },
    [formType, dispatch, eventID, index]
  );

   
  useEffect(() => {
    if (multiple && listData && listData.length > 0) {
      const matchedObjects = listData.filter((item) => {
        return colData?.some((colItem) => colItem.id === item.id);
      });
      const result = matchedObjects.map((item) => listData.find((obj) => obj.id === item.id));
      setSelected(result);
    } else {
      formType === "event_type" && setSelected(colData);
      formType === "package" && setSelected(colData);
      formType === "video_package" && setSelected(colData);
    }
  }, [multiple, listData, colData, formType, index]);

  const listboxButtonText = useMemo(() => {
    if (formType === "event_type") {
      if (selected.length > 0) {
        return colData;
      } else {
        return Object.keys(selected)[0] || "No event type";
      }
    } else if (formType === "package" || formType === "video_package" ) {
      if (selected.length > 0) {
        return colData;
      } else {
        return Object.values(selected)[1];
      }
    } else if (formType === "add_ons") {
      return selected.length > 0 ? multiple && selected.map((item) => item.name || Object.values(item)).join(", ") : "No Add-On";
    } else if (formType === "leads") {
      return selected.length > 0 ? multiple && selected.map((item) => item.user__first_name || Object.values(item)).join(", ") : "No event lead";
    } else if (formType === "covered_events") {
      return selected.length > 0
        ? multiple &&
            selected.map((item, i) => (
              <p key={i} className="border-1 rounded-xl my-1 bg-slate-50 p-2">
                {item.eventName}
              </p>
            ))
        : "No event";
    } else {
      return "";
    }
  }, [selected, formType, multiple, colData]);

  return (
    <div className=" w-9/12 md:w-full ">
      <Listbox value={selected} onChange={handleMultipleItems} multiple={multiple}>
        <div className="relative mt-1 ">
          <Listbox.Button className="relative w-full border-1 rounded-2xl bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className={`block break-all text-sm ${selected.length === 0 ? "text-slate-500 " : "text-slate-700"}`}>{listboxButtonText}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <BiEditAlt className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className="absolute mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              style={{ zIndex: "1100" }}
            >
              {formType === "add_ons" &&
                posted_add_ons?.map((item, itemIdx) => (
                  <Listbox.Option
                    key={itemIdx}
                    className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-slate-600 text-white" : "text-gray-900"}`}
                    value={item}
                  >
                    {({ selected }) => {
                      return (
                        <div className="flex flex-col justify-between">
                          <div className="flex flex-row justify-between">
                            <span className={`block ${selected ? "font-medium" : "font-normal"}`}>{item.name || item.user__first_name || Object.keys(item)}</span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <HiCheck className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}

                            <span className="border px-1 text-xs rounded-full text-sky-500">New</span>
                          </div>
                          {item?.price && <span className={`${selected ? "font-medium" : "font-normal"} text-xs`}>{"( " + item?.price + " TK )"}</span>}
                        </div>
                      );
                    }}
                  </Listbox.Option>
                ))}

              {listData?.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-slate-600 text-white" : "text-gray-900"}`}
                  value={item}
                >
                  {({ selected }) => {
                    return (
                      <div>
                        <span className={`block ${selected ? "font-medium" : "font-normal"}`}>
                          {item.name || item.user__first_name || item.eventName || Object.keys(item)}{" "}
                          {item?.price && <span className="text-xs">{"( " + item?.price + " TK )"}</span>}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <HiCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                    );
                  }}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

/* {formType === "event_type"
                ? selected.length > 0
                  ? multiple
                    ? selected?.map((item) => item.name || Object.keys(item)).join(", ")
                    : Object.keys(selected[0])[0]
                  : Object.keys(selected)
                : ""}

              {formType === "package"
                ? selected.length > 0
                  ? multiple
                    ? selected?.map((item) => item.name || Object.keys(item)).join(", ")
                    : colData
                  : Object.values(selected)[1]
                : ""}

              {formType === "add_ons" ? (selected.length > 0 ? multiple && selected?.map((item) => item.name || Object.values(item)).join(", ") : "No Add-On") : ""}

              {formType === "lead_by" ? (selected.length > 0 ? multiple && selected?.map((item) => item.user__first_name || Object.values(item)).join(", ") : "No event lead") : ""}

              {formType === "covered_events"
                ? selected.length > 0
                  ? multiple && selected?.map((item,i) => <p key={i} className="border-1 rounded-xl my-1 bg-slate-50 p-2">{item.eventName}</p>)
                  : "No event"
                : ""} */
