const clientsTableFormator = (inputArray) => {
  return inputArray?.map((item) => {
    const id = Object.keys(item)[0];
    const {
      id: client_id,
      full_name,
      phone_number,
      active_events,
      past_events,
      "month-year": monthYear,
      ...rest
    } = item[id];
    const activeEvents = active_events?.map((event) => {
      const eventId = Object.keys(event)[0];
      const eventName = event[eventId];
      return { eventId: Number(eventId), event_name: eventName };
    });
    const pastEvents = past_events?.map((event) => {
      const eventId = Object.keys(event)[0];
      const eventName = event[eventId];
      return { eventId: Number(eventId), event_name: eventName };
    });
    return {
      index: Number(id),
      client_id,
      full_name,
      phone_number,
      active_events: activeEvents,
      past_events: pastEvents,
      "month-year": monthYear,
      ...rest
    };
  });
};

const eventsTableFormator = (inputArray) => {
  return inputArray?.map((item) => {
    const index = Object.keys(item)[0];
    const {
      ...rest
    } = item[index];

    return {
      index: Number(index),
     ...rest
    };
  });
};

function cashOutflowFormator(data) {
  return data.reduce(([fieldsWithoutTBill, tBill, OutflowDropLists], item) => {
    const { related_tbill, ...fields } = item;
    fieldsWithoutTBill.push(fields);
    OutflowDropLists.push({month_year: item.month_year});
    if (related_tbill) {
      related_tbill.id=item.id
      related_tbill.index=item.index
      tBill.push(related_tbill)
    };
    return [fieldsWithoutTBill, tBill, OutflowDropLists];
  }, [[], [], []]);
}


const eventListFormator = (data) => {
  return data.map(({ eventId, event_name }) => {
    const eventDetails = event_name.split(" | ");
    const eventNameParts = eventDetails[0].split(" - ");
    const client = eventNameParts[0];
    const eventShortName = getShortNames(eventNameParts[1].toUpperCase());
    const packageShortName = getShortNames(eventDetails[1].toUpperCase());
    const date = eventDetails[2];

    const eventShortDetails = [eventShortName, packageShortName, date];

    return {
      id: eventId,
      client,
      details: eventShortDetails,
      event_name
    };
  });
};

function getShortNames(name) {
  //Every new case should be in UPPERCASE
  switch (name) {
    case "HOLUD":
      return "H";
    case "MEHEDI":
      return "M";
    case "ENGAGEMENT":
      return "E";
    case "AKDH":
      return "A";
    case "WEDDING":
      return "WE";
    case "WALIMA":
      return "WA";
    case "BRIDAL_SHOWER":
      return "BRS";
    case "BIRTHDAY":
      return "BD";
    case "OTHERS":
      return "O";
    case "BASIC":
      return "B";
    case "STORYTELLING":
      return "ST";
    case "PREMIUM":
      return "P(n)";
    case "STANDARD":
      return "S(n)";
    case "STANDARD 16/03/23":
      return "S (o)";
    case "PREMIUM - 16/03/23":
      return "P (n)";
    case "BASIC PLUS":
      return "BP";
    case "SINGLE PHOTOGRAPHER and VIDEOGRAPHER":
      return "SPV";
    case "DYNAMIC DUO - 16/3/23":
      return "DD (o)";
    default:
      return "C";
  }
}


function removeKeys(data, keysToRemove) {
  return data.map((obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (!keysToRemove.includes(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  });
}

export { clientsTableFormator, eventsTableFormator, eventListFormator, removeKeys, cashOutflowFormator };
