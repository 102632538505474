import React, { useEffect, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";

export default function DropDown({ handleFilter, DropDownItems, name, defaultDropItem, clearFilters, setClearFilters }) {
  const [toggleDown, setToggleDown] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(defaultDropItem?.title ? defaultDropItem : "");

  useEffect(() => {
    if (clearFilters) {
      setDropDownValue("");
      handleFilter("", "", true);
      setClearFilters(false)
    }
  }, [clearFilters, handleFilter, setClearFilters]);




  function handleOnClick(item) {
    setDropDownValue(item);
    
    if (name === "Event Type" && item !== "") {
      handleFilter("event_type", item);
    } else if (name === "Package" && item !== "") {
      handleFilter("package", item.name);
    } else if (name === "Add-ons" && item !== "") {
      handleFilter("add_ons", item);
    } else if (name === "Lead By" && item !== "") {
      handleFilter("lead_by", item);
    } else if (name === "Filter By Users" && item !== "") {
      handleFilter("user", item.user);
    } else if (name === "Month-Year" && item !== "") {
      handleFilter("month_year", item.month_year);
    } else if (name === "Month Year" && item !== "") {
      if (item.filterByMonth) {
        handleFilter("month", item.month);
      } else {
        handleFilter("month-year", item.month_year);
      }
    } else {
      handleFilter(item?.fieldName, item?.value);
    }
    setToggleDown(!toggleDown);
  }

  return (
    <div className="relative">
      <div
        onClick={() => {
          setToggleDown(!toggleDown);
        }}
        className={`select-none flex justify-between items-start  md:w-42 p-3 rounded ${dropDownValue ? "text-white bg-blue-600":"text-slate-500 bg-slate-200"}  cursor-pointer shadow-md hover:shadow-lg`}
        style={{ minWidth: "150px" }}
      >
        <p className="font-semibold text-sm">
          {dropDownValue
            ? dropDownValue.title || dropDownValue.name || dropDownValue.user || dropDownValue.user__first_name || dropDownValue.month_year || Object.keys(dropDownValue)[0]
            : name}
        </p>
        <TiArrowSortedDown className="text-xl" />
      </div>
      <div
        className={`${!toggleDown && "hidden"} md:absolute w-full bg-white border border-b shadow mt-1 overflow-y-auto overflow-x-hidden`}
        style={{ zIndex: "900", maxHeight: "300px" }}
      >
        {toggleDown &&
          DropDownItems.map((item, index) => {
            return (
              <div
                key={index}
                className="select-none cursor-pointer py-2 px-3 text-sm text-slate-600 hover:bg-slate-300 border-b"
                style={{ animation: `fadeInFromBottom 0.1s ease-out` }}
                onClick={() => handleOnClick(item)}
              >
                {item.title || item.name || item.user__first_name || item.user || item.month_year || Object.keys(item)[0]}
              </div>
            );
          })}
      </div>
      <style>
        {`
          @keyframes fadeInFromBottom {
            from {
              transform: translateY(100%);
            }
            to {
              transform: translateY(0%);
            }
          }

          ::-webkit-scrollbar {
            width: 10px;
          }
          
       
          ::-webkit-scrollbar-track {
            background: #f1f1f1; 
          }
           
         
          ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px 
          }
          
      
          ::-webkit-scrollbar-thumb:hover {
            background: #555; 
          }
        `}
      </style>
    </div>
  );
}
