import axios from "axios";
import { getErrorMessagesOfPutRequests } from "../lib/ErrorHandler";

const API_URL = process.env.REACT_APP_API_URL;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

async function httpUpdateClientTableData(data) {
  const { client_id, full_name, phone_number, index } = data;

  const newData = {
    full_name,
    phone_number,
  };

  try {
    const response = await axios.put(`${API_URL}/clients/${client_id}/`, newData);
    //console.log(response, "from http file");
    return {
      res: response?.data,
      status: "success",
      index: data?.index,
    };
  } catch (error) {


    return {
      res: getErrorMessagesOfPutRequests(error),
      status: "error",
      index,
    };
  }
}

async function httpUpdateEventTableData(data) {
  try {
    const response = await axios.put(`${API_URL}/events/${data.id}/`, data);

    return {
      res: response?.data,
      status: "success",
      index: data?.index,
    };
  } catch (error) {
    console.log(error)
    return {
      res: getErrorMessagesOfPutRequests(error),
      status: "error",
      index: data?.index,
    };
  }
}

async function httpUpdateCashInflowsTableData(data) {
  const formData = {
    amount: data.amount,
  };
  try {
    const response = await axios.put(`${API_URL}/cashinflows/${data.id}/`, formData);
    //console.log(response, "from http file");
    return {
      res: response?.data,
      status: "success",
      index: data?.index,
    };
  } catch (error) {
    return {
      res: getErrorMessagesOfPutRequests(error),
      status: "error",
      index: data?.index,
    };
  }
}

async function httpUpdateCashOutflowTableData(data) {
  try {
    const response = await axios.put(`${API_URL}/cashoutflows/${data.formData.id}/`, data.formData);
    //console.log(response, "from http file");
    if (data.from === "main") {
      return {
        res: response?.data,
        status: "success",
        index: data?.index,
      };
    } else {
      return [response?.data, "success"];
    }
  } catch (error) {
    console.log(error,"blah")
    return {
      res: getErrorMessagesOfPutRequests(error),
      status: "error",
      index: data?.index,
    };
  }
}

async function httpUpdateTeamMemberBillTableData(data) {
  const formData = {
    events: typeof data.covered_events[0] === "number" ? data.covered_events : data.covered_events.map(item => item.id),
    month: data.month,
    year: data.year,
    comment: data.comment,
    adjustment:data.adjustment
  };

  try {
    const response = await axios.put(`${API_URL}/teammemberbills/${data.id}/`, formData);
    //console.log(response, "from http file");
    return {
      res: response?.data,
      status: "success",
      index: data?.index,
    };
  } catch (error) {

    console.log(error)
    return {
      res: getErrorMessagesOfPutRequests(error),
      status: "error",
      index: data?.index,
    };
  }
}

async function httpUpdatePackageTableData(data) {

  const formData = {
    id: data.id,
    active: data.active
  }
  console.log(data, formData,"Packages");
  try {
    const response = await axios.patch(`${API_URL}/packages/${data.id}/`, formData);
    console.log(response, "from http file");
    return {
      res: response?.data,
      status: "success",
      index: data?.index,
    };
  } catch (error) {
    console.log(error, "from http file");
    return {
      res: getErrorMessagesOfPutRequests(error),
      status: "error",
      index: data?.index,
    };
  }
}

export {httpUpdatePackageTableData, httpUpdateClientTableData, httpUpdateEventTableData, httpUpdateCashInflowsTableData, httpUpdateCashOutflowTableData, httpUpdateTeamMemberBillTableData };
