import { useCallback, useEffect, useState } from "react";
import { httpGetEventsData } from "../requests/httpGetRequests";
import { eventsTableFormator } from "../lib/DataFormatorV1";


export default function useDropListsEvents() {
    const[billEventsDropLists, saveBillEventsDropLists] = useState([])
   
   
   const getEventFullString = useCallback(async () => {
     const fetchedEventsLists = await httpGetEventsData()
     const newList = newDrop(eventsTableFormator(fetchedEventsLists))
     saveBillEventsDropLists(newList)
   },[])
   
   function newDrop(data) {
     
     const newList = data?.map((item)=>{
         const id = item.id
         const str = item.client + " - " + (item.event_type? item.event_type:"Custom Event" ) + "| " + (item.package ? item.package : 'No Package') + " | " + item.date
        return {id, eventName: str}
     })
   
     return newList
   }
   

   useEffect(() => {
     getEventFullString();
   }, [getEventFullString]);
   
     return {
       billEventsDropLists
     }
   }
   