import React, { useEffect, useMemo, useState } from "react";
import DropDown from "../../components/shared-comp/DropDown";

import { GiTerror } from "react-icons/gi";

import Table from "../../components/table-comp/Table";
import { DataSorterV1 } from "../../lib/DataSorterV1";
import usePutData from "../../hooks/usePutData";
import { dynamicFilter } from "../../lib/DataFilterv2";
import TablePutConfirmationModal from "../../components/shared-comp/TablePutConfirmationModal";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import { cashOutflowFormator } from "../../lib/DataFormatorV1";
import AddCashOutflowModal from "./components/AddCashOutflowModal";
import SaveChangesButton from "../../components/shared-comp/SaveChangesButton";
import AddDataBtn from "../../components/shared-comp/AddDataBtn";
import { httpGetCashOutflowTableData } from "../../requests/httpGetRequests";
import Loader from "../../components/shared-comp/Loader";
// import GenerateInvoiceModal from "./GenerateInvoiceModal";

const DropDownItems = [
  {
    title: "Active Outflows",
    fieldName: "is_active",
    value: true,
  },
  {
    title: "Past Outflows",
    fieldName: "is_active",
    value: false,
  },
];

export default function CashOutflowsContent() {
  const [data, setData] = useState([]);
  const [formatedDataForFilter, setFormatedDataForFilter] = useState([]);
  const [tBillTableData, setTBillTableData] = useState([]);
  const [outFlowMonthYearDropLists, setOutFlowMonthYearDropLists] = useState([]);
  const [outflowModalData, setOutFlowModalData] = useState({});
  const [changedRows, setChangedRows] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isTablePutConfirmationModal, setTablePutConfirmationModal] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [putResponse, setPutResponse] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [filterKey, setFilterKey] = useState(null);


  const getCashOutflowTableData = useMemo(
    () => async () => {
      try {
        setTableLoading(true);
        const fetchedCashOutflowTableData = await httpGetCashOutflowTableData();
        if (fetchedCashOutflowTableData[1] === "success") {
          const [fieldsWithoutTBill, tBill, OutflowDropLists] = cashOutflowFormator(fetchedCashOutflowTableData[0]);
          setData(fieldsWithoutTBill);
          setFormatedDataForFilter(fieldsWithoutTBill);
          setTBillTableData(tBill);
          const uniqueSources = new Set();
          const filteredData = [];

          for (const item of OutflowDropLists) {
            if (!uniqueSources.has(item.month_year)) {
              filteredData.push(item);
              uniqueSources.add(item.month_year);
            }
          }
          setOutFlowMonthYearDropLists(filteredData);
          setTableLoading(false);
        } else {
          setCustomModalMsg(fetchedCashOutflowTableData[2]);
          setTableLoading(false);
          setCustomModalOpen(true);
        }
      } catch (error) {
        console.error(error);
        setCustomModalMsg("An error occurred while fetching data.");
        setTableLoading(false);
        setCustomModalOpen(true);
      }
    },
    []
  );

  useEffect(() => {
    getCashOutflowTableData();
  }, [getCashOutflowTableData]);

  const handleSorts = (TBdata, columnName, order) => {
    //console.log(TBdata, columnName, order);
    const sortedData = DataSorterV1(TBdata, columnName, order);
    setData(sortedData);
  };

  useEffect(() => {
    if (filterKey) {
      const filteredData = dynamicFilter(formatedDataForFilter, filterKey);
      setData(filteredData);
    } else {
      setData(formatedDataForFilter);
    }
  }, [filterKey, formatedDataForFilter]);

  const handleFilter = (columnName, value, reset) => {
    // console.log(columnName, value, reset)
    if (reset) {
      setFilterKey(null);
    } else setFilterKey({ ...filterKey, [columnName]: value });
  };

  const { getCashOutflowsTablePutReqRes } = usePutData();

  const handleSaveChanges = () => {
    changedRows.map(async (item) => {
      const data = {
        formData: {
          id: item.id,
          source: item.source_of_expense,
          amount: item.amount,
        },
        from: "main",
        index: item.index,
      };
      try {
        setLoading(true);
        const response = await getCashOutflowsTablePutReqRes(data);
        //console.log(response, "from put");
        setPutResponse((prevArray) => [...prevArray, response]);
        setLoading(false);
      } catch (error) {
        console.error(error, "Error during making requests");
        setCustomModalMsg("Error during making requests");
        setCustomModalOpen(true);
      }
    });
    setChangedRows([{}]);
    setTablePutConfirmationModal(true);
  };

  function closeModal() {
    setPutResponse([]);
    setTablePutConfirmationModal(false);
    setCustomModalOpen(false);
  }

  // //console.log(putResponse, "Put response");

  return (
    <div>
      <h1 className="text-2xl font-semibold text-slate-500">{data && data.length} Cash Outflows</h1>

      <div className="flex flex-col justify-between mt-5 xl:flex-row gap-3">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
          <DropDown DropDownItems={DropDownItems} handleFilter={handleFilter} name={"Outflow Activity"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
          <DropDown DropDownItems={outFlowMonthYearDropLists} handleFilter={handleFilter} name={"Month-Year"} clearFilters={clearFilters} setClearFilters={setClearFilters} />
        </div>

        <div className="mt-5 md:mt-0 w-full gap-3 xl:w-4/12 flex flex-col lg:flex-row justify-between lg:justify-between items-center">
          <SaveChangesButton changedRows={changedRows} loading={loading} handleSaveChanges={handleSaveChanges} />
          <div
            className="w-full"
            onClick={() => {
              setOutFlowModalData({});
            }}
          >
            <AddDataBtn targetId={"#addCashOutflowModalXl"} name={"Add Cash Outflow"} />
          </div>

          <AddCashOutflowModal data={outflowModalData} tBillTableData={tBillTableData} />
          <TablePutConfirmationModal from={"CashOutflowsContent"} putResponse={putResponse} isModalOpen={isTablePutConfirmationModal} closeModal={closeModal} />
          <CustomMessageModal title={""} icon={<GiTerror className="text-6xl text-indigo-700" />} msg={customModalMsg} isModalOpen={isCustomModalOpen} closeModal={closeModal} />
        </div>
      </div>

      <div className="mt-10">
       {!tableLoading? <Table
          from={"CashOutflow"}
          data={data}
          setOutFlowModalData={setOutFlowModalData}
          handleSorts={handleSorts}
          setChangedRows={setChangedRows}
          clearFilters={clearFilters}
          setClearFilters={setClearFilters}
        />: <Loader loadingText="Table Loading" />}
      </div>
    </div>
  );
}
