import axios from "axios";
import { getErrorMessagesOfPutRequests } from "../lib/ErrorHandler";

const API_URL = process.env.REACT_APP_API_URL;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

async function httpDeleteClientTableRow(data) {
  
  try {
    const response = await axios.delete(`${API_URL}/clients/${data.client_id}/`);
    //console.log(response, "from http file");
    return {
      res: response?.data,
      status: "success",
      index: data?.index,
    };
  } catch (error) {

    return {
      res: getErrorMessagesOfPutRequests(error),
      status: "error",
      index: data?.index,
    };
  }
}

async function httpDeleteEventTableRow(data) {
  try {
    const response = await axios.delete(`${API_URL}/events/${data.id}/`);

    return {
      res: response?.data,
      status: "success",
      index: data?.index,
    };
  } catch (error) {
    
    return {
      res: getErrorMessagesOfPutRequests(error),
      status: "error",
      index: data?.index,
    };
  }
}

async function httpDeleteCashInflowsTableRow(data) {
  
  try {
    const response = await axios.delete(`${API_URL}/cashinflows/${data.id}/`);
    //console.log(response, "from http file");
    return {
      res: response?.data,
      status: "success",
      index: data?.index,
    };
  } catch (error) {
    return {
      res: getErrorMessagesOfPutRequests(error),
      status: "error",
      index: data?.index,
    };
  }
}



export {httpDeleteClientTableRow, httpDeleteEventTableRow, httpDeleteCashInflowsTableRow };
