import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiCheck, HiChevronDown } from "react-icons/hi";
import { useSelector } from "react-redux";


export default function ListBox({ listData, multiple, handleSingleEventFormData, formType, formIndex }) {
  const [selected, setSelected] = useState(multiple ? [] : "");
  const newAddOns = useSelector((state) => state.cart.addOns);

  function handleMultipleItems(items) {
    setSelected(items);
    const formattedData = [];
    items?.forEach((item) => {
      if (item.id) {
        formattedData.push(item.id);
      } else {
        formattedData.push(item);
      }
    });
    handleSingleEventFormData(formattedData, formType, items);
  }

  return (
    <div>
      <Listbox value={selected} onChange={handleMultipleItems} multiple={multiple}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className={`block break-all ${selected.length === 0 && "text-semibold text-slate-400"}`}>
              {multiple
                ? selected.length > 0
                  ? selected?.map((item, i) => (
                      <p key={i} className="border-1 rounded-xl my-1 bg-slate-50 p-2">
                        {item.name || item.user__first_name}
                      </p>
                    ))
                  : "choose here"
                : selected.length > 0
                ? selected
                : "choose here"}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <HiChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          {formType === "add_ons" && newAddOns.length !== 0 && <span className="px-1 text-xs text-slate-400">Select the newly added add-ons!</span>}

          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className="absolute mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              style={{ zIndex: "1100" }}
            >
              {formType === "add_ons" &&
                newAddOns?.map((item, itemIdx) => (
                  <Listbox.Option
                    key={itemIdx}
                    className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-slate-600 text-white" : "text-gray-900"}`}
                    value={item}
                    // onClick={() => handleMultipleItems(item, formType)}
                  >
                    {({ selected }) => (
                      <div className="flex flex-col justify-between">
                        <div className="flex flex-row justify-between">
                          <span className={`block ${selected ? "font-medium" : "font-normal"}`}>{item.name || item.user__first_name}</span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <HiCheck className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}

                          <span className="border px-1 text-xs rounded-full text-sky-500">New</span>
                        </div>
                        {item?.price && <span className={`${selected ? "font-medium" : "font-normal"} text-xs`}>{"( " + item?.price + " TK )"}</span>}
                      </div>
                    )}
                  </Listbox.Option>
                ))}

              {listData?.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-slate-600 text-white" : "text-gray-900"}`}
                  value={item}
                  // onClick={() => handleMultipleItems(item, formType)}
                >
                  {({ selected }) => (
                    <div>
                      <span className={`block ${selected ? "font-medium" : "font-normal"}`}>
                        {item.name || item.user__first_name} {item?.price && <span className="text-xs">{"( " + item?.price + " TK )"}</span>}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <HiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
