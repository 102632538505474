import React from "react";
import SidebarLayout from "../../layouts/SidebarLayout";
import TabBarLayout from "../../layouts/TabBarLayout";
import ClientsContent from "../../features/clients-events/ClientsContent";


export default function ClientsPage() {

  return (
    <>
      <SidebarLayout>
        <TabBarLayout pathRoot={"/clients-events"} pathNames={["Clients", "Events"]} paths={["/clients", "/events"]}>
          <ClientsContent />
        </TabBarLayout>
      </SidebarLayout>
    </>
  );
}
