import React, { useEffect, useMemo, useState } from "react";
import DropDown from "../../components/shared-comp/DropDown";

import { GiTerror } from "react-icons/gi";

import Table from "../../components/table-comp/Table";
import { DataSorterV1 } from "../../lib/DataSorterV1";
import { dynamicFilter } from "../../lib/DataFilterv2";
import TablePutConfirmationModal from "../../components/shared-comp/TablePutConfirmationModal";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import AddTeamMemberBills from "./components/AddTeamMemberBills";
import useDropListsEvents from "../../hooks/useDropListsEvents";
import { useSelector } from "react-redux";
import { httpUpdateTeamMemberBillTableData } from "../../requests/httpPutRequests";
import SaveChangesButton from "../../components/shared-comp/SaveChangesButton";
import AddDataBtn from "../../components/shared-comp/AddDataBtn";
import { httpGetTeamBillsTableData } from "../../requests/httpGetRequests";
import Loader from "../../components/shared-comp/Loader";


const DropDownItems = [
  {
    title: "Cleared",
    fieldName: "cleared",
    value: true,
  },
  {
    title: "Pending",
    fieldName: "cleared",
    value: false,
  },
];

export default function TeamBillsContent() {
  const { covered_events_Arr } = useSelector((state) => state.teamMemberBill);
  const { billEventsDropLists } = useDropListsEvents();
  const [data, setData] = useState([]);
  const [dataForFilter, setDataForFilter] = useState([]);
  const [changedRows, setChangedRows] = useState([{}]);
  const [allChanges, setAllChanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [isTablePutConfirmationModal, setTablePutConfirmationModal] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [putResponse, setPutResponse] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [filterKey, setFilterKey] = useState({ cleared: false });

  const getTeamBillsTableData = useMemo(
    () => async () => {
      try {
        setTableLoading(true)
        const fetchedTeamBillsTableData = await httpGetTeamBillsTableData();
        if (fetchedTeamBillsTableData[1] === "success") {
          const [firstItem, restOfData] = splitData(fetchedTeamBillsTableData[0]);
          const pendingBills = restOfData.filter((item) => item.cleared === false);
          setData(pendingBills);
          setDataForFilter(restOfData);
          setCanAdd(firstItem?.can_add);
          setTableLoading(false)
        } else {
          setCustomModalMsg(fetchedTeamBillsTableData[2]);
          setTableLoading(false)
          setCustomModalOpen(true);
        }
      } catch (error) {
        console.error(error);
        setCustomModalMsg("An error occurred while fetching data.");
        setTableLoading(false)
        setCustomModalOpen(true);
      }
    },
    []
  );

  useEffect(() => {
    getTeamBillsTableData()
  }, [getTeamBillsTableData]);


  useEffect(() => {
    const tempData = changedRows.map((item) => {
      if (item.index === undefined) {
        return [];
      } else return { covered_events: item.covered_events.map((item) => item.id), ...item };
    });
    setAllChanges([...tempData, ...covered_events_Arr]);
  }, [covered_events_Arr, changedRows]);

  function splitData(data) {
    const firstItem = data.shift();
    return [firstItem, data];
  }

  function combineArrays() {
    const result = allChanges.reduce((acc, obj) => {
      const existingObj = acc.find((o) => o.index === obj.index);
      if (existingObj) {
        let newObj = { ...existingObj };
        newObj.index = obj.index;
        Object.assign(newObj, obj);
        acc.splice(acc.indexOf(existingObj), 1, newObj);
      } else {
        obj.length === undefined && acc.push(obj);
      }

      return acc;
    }, []);

    return result;
  }

  const handleSorts = (TBdata, columnName, order) => {
    const sortedData = DataSorterV1(TBdata, columnName, order);
    setData(sortedData);
  };


  useEffect(() => {
    if (filterKey) {
      const filteredData = dynamicFilter(dataForFilter, filterKey);
      setData(filteredData);
    } else {
      setData(dataForFilter);
    }
  }, [filterKey, dataForFilter]);



  const handleFilter = (columnName, value, reset) => {
    if (reset) {
      setFilterKey(null);
    } else setFilterKey({ ...filterKey, [columnName]: value });
  };


  const handleSaveChanges = () => {
    const combinedChanges = combineArrays();
    combinedChanges.map(async (item) => {
      try {
        setLoading(true);
        const response = await httpUpdateTeamMemberBillTableData(item);
        setPutResponse((prevArray) => [...prevArray, response]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error, "Error during making requests");
      }
    });
    setLoading(false);
    setChangedRows([{}]);
    setTablePutConfirmationModal(true);
  };

  function closeModal() {
    setPutResponse([]);
    setTablePutConfirmationModal(false);
    setCustomModalOpen(false);
  }


  return (
    <div>
      <h1 className="text-2xl font-semibold text-slate-500">Team Bills {data && data.length}</h1>

      <div className="flex flex-col justify-between mt-5 xl:flex-row gap-3">
        <DropDown
          DropDownItems={DropDownItems}
          defaultDropItem={{
            title: "Pending",
            isDefault: true,
          }}
          handleFilter={handleFilter}
          name={"Payment status"}
          clearFilters={clearFilters}
          setClearFilters={setClearFilters}
        />

        <div className="mt-5 md:mt-0 w-full gap-3 xl:w-5/12 flex flex-col lg:flex-row justify-between lg:justify-between items-center">
          <SaveChangesButton changedRows={changedRows} allChanges={allChanges} loading={loading} handleSaveChanges={handleSaveChanges} />
          {canAdd && <AddDataBtn targetId={"#addTeamMembersBill"} name={"Add Team-Members Bill"} />}

          <AddTeamMemberBills listData={billEventsDropLists} />
          <TablePutConfirmationModal from={"TeamMemberBill"} putResponse={putResponse} isModalOpen={isTablePutConfirmationModal} closeModal={closeModal} />
          <CustomMessageModal title={""} icon={<GiTerror className="text-6xl text-indigo-700" />} msg={customModalMsg} isModalOpen={isCustomModalOpen} closeModal={closeModal} />
        </div>
      </div>

      <div className="mt-10">
       {!tableLoading? <Table
          data={data}
          dropListsData={billEventsDropLists}
          handleSorts={handleSorts}
          setChangedRows={setChangedRows}
          clearFilters={clearFilters}
          setClearFilters={setClearFilters}
        />:<Loader loadingText="Table Loading" />}
      </div>
    </div>
  );
}
