import { useCallback, useEffect, useState } from "react";
import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export default function useAuth() {

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const getAuth = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}`);
      if (response.status === 200) {
        setIsAuthenticated(true);
      }
    } catch (error) {
      //console.log(error.response.data.detail, "err");
      if (error.code === "ERR_NETWORK") { }
      else setIsAuthenticated(false);
    }
  }, [API_URL]);


  useEffect(() => {
    getAuth();
  }, [getAuth]);

  return {
    isAuthenticated,
  };
}
