function sortByString(arr, col, order) {
  return arr.sort((a, b) => {
    const stringA = a[col].toLowerCase();
    const stringB = b[col].toLowerCase();
    if (order === "asc") {
      return stringA.localeCompare(stringB);
    } else if (order === "desc") {
      return stringB.localeCompare(stringA);
    }
    return arr;
  });
}

function sortArrayByNumber(arr, Col, order) {
  arr.sort((a, b) => {
    const numA = Number(a[Col]);
    const numB = Number(b[Col]);
    if (order === "asc") {
      return numA - numB;
    } else if (order === "desc") {
      return numB - numA;
    }
    return arr;
  });
  return arr;
}

function sortDataByStatus(data,col, state) {
    if (state === "Active") {
      return data.sort((a, b) => {
        if (a[col] === "Active" && b[col] === "Inactive") {
          return -1;
        } else if (a[col] === "Inactive" && b[col] === "Active") {
          return 1;
        } else {
          return 0;
        }
      });
    } else if (state === "Inactive") {
      return data.sort((a, b) => {
        if (a[col] === "Inactive" && b[col] === "Active") {
          return -1;
        } else if (a[col] === "Active" && b[col] === "Inactive") {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

function sortByMonthYear(arr, col, order) {
  arr.sort(function (a, b) {
    var dateA = new Date("01-" + a[col]);
    var dateB = new Date("01-" + b[col]);
    return order === "asc" ? dateA - dateB : dateB - dateA;
  });
  return arr;
}

// function filterByStatus(data, status) {
//   return data.filter((item) => item.status === status);
// }

function sortEvents(arr, col, order) {
  if (order === "asc") {
    arr.sort((a, b) => a[col] - b[col].length);
  } else if (order === "desc") {
    arr.sort((a, b) => b[col].length - a[col].length);
  }
  return arr;
}

function sortDataByDate(data, sortOrder) {
  
  if (sortOrder === 'asc') {
    data.sort((a, b) => new Date(a.date) - new Date(b.date));
  } else if (sortOrder === 'desc') {
    data.sort((a, b) => new Date(b.date) - new Date(a.date));
  } else {
    throw new Error('Invalid sort order. Use "asc" or "desc".');
  }
  return data;
}

function DataSorterV1(data, column, order) {
  
  switch (column) {
    case "index":
        return sortArrayByNumber(data,column,order );
    case "total_revenue":
        return sortArrayByNumber(data,column,order );
    case "revenue":
        return sortArrayByNumber(data,column,order );
    case "advance_received":
        return sortArrayByNumber(data,column,order );
    case "budget":
        return sortArrayByNumber(data,column,order );
    case "total_pending_payment":
        return sortArrayByNumber(data,column,order );
    case "gross_profit":
        return sortArrayByNumber(data,column,order );
    case "overhead_expenses":
        return sortArrayByNumber(data,column,order );
    case "net_profit":
        return sortArrayByNumber(data,column,order );
    case "cash":
        return sortArrayByNumber(data,column,order );
    case "full_name":
        return sortByString(data,column,order );
    case "client":
        return sortByString(data,column,order );
    case "phone_number":
        return sortArrayByNumber(data,column,order );
    case "amount":
        return sortArrayByNumber(data,column,order );
    case "month-year":
        return sortByMonthYear(data,column,order );
    case "date":
        return sortDataByDate(data,order );
    case "venue":
        return sortByString(data,column,order );
    case "active_events":
        return sortEvents(data,column,order );
    case "past_events":
        return sortEvents(data,column,order );
    case "status":
        return sortDataByStatus(data,column, order );
    default:
      return data;
  }
}

export { DataSorterV1 };
