import React from "react";
import { BiLoaderCircle } from "react-icons/bi";

export default function SubmitFormBtn({ handleSubmitBtn, loading, name }) {
  return (
    <button
      type="submit"
      disabled={loading}
      className={`${loading && "opacity-60" } bg-indigo-800 rounded p-2 px-3 flex gap-2 justify-center items-center text-sm font-semibold text-slate-100 cursor-pointer btn_shadow hover:bg-indigo-800`}
      data-te-ripple-init
      data-te-ripple-color="light"
      onClick={handleSubmitBtn}
    >
      {loading ? (
        <span className="flex justify-center gap-1 items-center">
          <BiLoaderCircle className="animate-spin text-xl" />
          <p>Processing...</p>
        </span>
      ) : (
        name
      )}
    </button>
  );
}
