import React from "react";
import SidebarLayout from "../../layouts/SidebarLayout";
import TabBarLayout from "../../layouts/TabBarLayout";
import TeamMembersContent from "../../features/team/TeamMembersContent";

export default function TeamMembersPage() {
  return (
    <SidebarLayout>
      <TabBarLayout pathRoot={"/team"} pathNames={["Team Bills", "Team Members"]} paths={["/team-bills", "/team-members"]}>
        <TeamMembersContent />
      </TabBarLayout>
    </SidebarLayout>
  );
}
