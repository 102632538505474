import React, { useMemo } from "react";

export default function OutflowFormTable({ data }) {

  const convertToTitleCase = useMemo(() => {
    return (str) => {
      const words = str.toLowerCase().split("_");
      const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      const titleCaseStr = titleCaseWords.join(" ");
      return titleCaseStr;
    };
  }, []);

  if (!data || data.length === 0) {
    return <p>No data available.</p>;
  }
  const headers = Object.keys(data[0]);

  return (
    <table className="table">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{convertToTitleCase(header)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <tr key={i}>
            {headers.map((header) => (
              <td key={header} className="text-slate-700 text-sm">{row[header]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
