import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";




function EventCellEmptyView({ columnName }) {
    return (
      <p className="text-sm text-slate-500 text-center capitalize">
        No {columnName.replace(/_/g, " ")}
      </p>
    );
  }
  
  function EventCellOneDataView({colData}) {
    return (
        <div  key={colData[0]?.id} className="flex items-center text-sm text-slate-700 tracking-wide py-2 border-b-1 rounded px-3">
        <div className="ml-1 px-3 rounded-3xl text-slate-800 py-1">
          {colData[0]?.name || colData[0]}
        </div>
      </div>
    );
  }



export default function EventCellViewV2({ columnName, colData, index }) {
   
    return (
      <div className="w-full flex flex-col items-end md:items-start">
        {colData?.length === 0 && <EventCellEmptyView columnName={columnName} />}
        {colData?.length === 1 && <EventCellOneDataView colData={colData} />}
        {colData?.length > 1 && (
          <>
            <div
              className=" p-1 border-1 rounded-xl px-3 flex items-center justify-between"
              data-te-collapse-init
              data-te-ripple-init
              data-te-ripple-color="light"
              data-te-target={`#client-${index}`}
              aria-expanded="false"
              aria-controls={`client-${index}`}
            >
              <span className="text-sm capitalize text-slate-700">
                {columnName.replace(/_/g, " ")} Lists
              </span>
              <MdKeyboardArrowDown className="text-slate-500" />
            </div>
            <div
              className="!visible hidden"
              id={`client-${index}`}
              data-te-collapse-item
            >
              {colData?.map((item) => {
                return (
                  <div key={item.id || item} className="flex justify-start items-center text-sm text-slate-700 tracking-wide py-2 border-b-1 rounded px-3">
                    <div className=" px-3 rounded-3xl text-slate-600 py-1">
                      {item.name? item.name : item}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }
  