import { createSlice } from "@reduxjs/toolkit";

const packageSlice = createSlice({
  name: "packageStates",

  initialState: {
   packageStatusList:[]
  },

  reducers: {
    updatePackageStatusList(state, action) {
      const { index, id, active } = action.payload;
        const itemIndex = state.packageStatusList.findIndex((item) => item.index === index); 
        if (itemIndex === -1) {
          state.packageStatusList.push({ index, id, active });
        } else {
          state.packageStatusList[itemIndex].active = active;
        }
    },
  },
});

export const {updatePackageStatusList } = packageSlice.actions;
export default packageSlice;
