import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import { GiTerror } from "react-icons/gi";
import { BiLoaderCircle } from "react-icons/bi";
import useClientPaymentPending from "../../../hooks/useClientPaymentPending";
import CashInflowComboBox from "./CashInflowComboBox";
import useCashInflowPost from "../../../hooks/useCashInflowPost";
import GenerateInvoiceModal from "../../clients-events/components/GenerateInvoiceModal";
import CustomMessageModal from "../../../components/shared-comp/CustomMessageModal";

export default function AddCashInflowModal({ isModalOpen, closeModal, from }) {
  const navigate = useNavigate();
  const { clientPaymentPending } = useClientPaymentPending();
  const [modalData, setModalData] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [amount, setAmount] = useState("");
  const [postReqResponse, setPostReqResponse] = useState([]);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const uniqueSources = new Set();
    const filteredData = [];
    if (clientPaymentPending.length > 0) {
      for (const item of clientPaymentPending) {
        if (!uniqueSources.has(item.name)) {
          filteredData.push(item);
          uniqueSources.add(item.name);
        }
      }
    }
    setModalData(filteredData);
  }, [clientPaymentPending]);

  const cashInflowPostReqResponse = useCashInflowPost();

  async function handleSubmit() {
    const formData = {
      source: selectedSource?.id || null,
      amount: amount || null,
    };
    try {
      setLoading(true);
      const response = await cashInflowPostReqResponse(formData);
      setPostReqResponse(response);
      if (response[1] === "success") {
        setSuccessModalOpen(true);
      } else {
        setCustomModalMsg(response?.[2]);
        setCustomModalOpen(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //console.log(error, "An error has ocurred");
      setCustomModalMsg("An error occurred while saving changes. Please try again.");
      setCustomModalOpen(true);
    }
  }

  const refreshPage = () => {
    navigate(0);
  };

  function closeErrorModal() {
    setCustomModalOpen(false);
  }

  function closeSuccessModal() {
    setSuccessModalOpen(false);
    refreshPage()
  }

  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative" onClose={closeModal} style={{ zIndex: "1200" }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="justify-center w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h1" className="flex justify-between text-2xl font-bold leading-6 text-gray-700 pb-5">
                    Add Cash Inflow
                    <button>
                      <AiOutlineClose onClick={closeModal} className="text-2xl text-slate-700 cursor-pointer" />
                    </button>
                  </Dialog.Title>

                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col items-center lg:items-end gap-5 px-5 py-5 pb-10 bg-slate-100 rounded-xl">
                      <div className="w-full flex flex-col">
                        <div className="flex justify-between items-center">
                          {postReqResponse?.[1] === "success" && <p className="text-xs text-green-700">Successfully Saved !</p>}
                          {postReqResponse?.[1] === "error" && <p className="text-xs text-yellow-700">Please check your inputs!</p>}
                        </div>
                        <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="fname">
                          Source
                        </label>
                        <CashInflowComboBox listData={modalData} setSelectedSource={setSelectedSource} />
                        {postReqResponse?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{postReqResponse?.[0].response?.data?.source?.[0]}</p>}
                      </div>
                      <div className="w-full flex flex-col">
                        <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="price">
                          Amount
                        </label>
                        {selectedSource && (
                          <p className="text-xs text-slate-400">
                            Total pending payment for {selectedSource?.name} is BDT {selectedSource?.pending_payment}
                          </p>
                        )}
                        <input
                          className="text-sm outline-none mt-1 px-2 h-10 bg-slate-300 rounded-md no_spinner"
                          type="number"
                          id="amount"
                          name="amount"
                          onChange={(event) => setAmount(event.target.value)}
                          placeholder="Type the amount here"
                        />
                        {amount.includes("-") && <p className="text-xs text-red-700 mt-1">Please remove "-" from the input</p>}
                        {amount > selectedSource?.pending_payment && <p className="text-xs text-red-700 mt-1">Your amount exceeds the limit!</p>}
                        {postReqResponse?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{postReqResponse?.[0].response?.data?.amount?.[0]}</p>}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-end">
                    <button
                      disabled={!(selectedSource !== null && amount !== "" && amount <= selectedSource?.pending_payment)}
                      type="submit"
                      className={`${
                        !(selectedSource !== null && amount !== "" && amount <= selectedSource?.pending_payment) ? "opacity-80 bg-gray-500" : "bg-indigo-800 hover:bg-indigo-900"
                      }  rounded p-2 px-3 flex gap-2 justify-center items-center text-sm font-semibold text-slate-100 cursor-pointer btn_shadow`}
                      onClick={handleSubmit}
                      data-te-toggle="tooltip"
                      title={!(selectedSource !== null && amount !== "" && amount <= selectedSource?.pending_payment) ? "Disabled now! Please input data." : ""}
                    >
                      {loading ? (
                        <span className="flex justify-center gap-1 items-center">
                          <BiLoaderCircle className="animate-spin text-xl" />
                          <p>Processing...</p>
                        </span>
                      ) : (
                        "Save Cash Inflow"
                      )}
                    </button>
                  </div>
                  <GenerateInvoiceModal successRes={postReqResponse} isModalOpen={isSuccessModalOpen} closeModal={closeSuccessModal} />
                  <CustomMessageModal
                    title={""}
                    icon={<GiTerror className="text-6xl text-indigo-700" />}
                    msg={customModalMsg}
                    isModalOpen={isCustomModalOpen}
                    closeModal={closeErrorModal}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
