import React, { useEffect, useMemo, useState } from "react";
import DropDown from "../../components/shared-comp/DropDown";
import { GiTerror } from "react-icons/gi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Table from "../../components/table-comp/Table";
import { DataSorterV1 } from "../../lib/DataSorterV1";
import usePutData from "../../hooks/usePutData";
import { dynamicFilter } from "../../lib/DataFilterv2";
import TablePutConfirmationModal from "../../components/shared-comp/TablePutConfirmationModal";
import CustomMessageModal from "../../components/shared-comp/CustomMessageModal";
import AddCashInflowModal from "./components/AddCashInflowModal";
import SaveChangesButton from "../../components/shared-comp/SaveChangesButton";
import AddDataBtn from "../../components/shared-comp/AddDataBtn";
import { httpDeleteCashInflowsTableRow } from "../../requests/httpDeleteRequests";
import { useNavigate } from "react-router";
import RowDeletionPrompt from "../../components/shared-comp/RowDeletionPrompt";
import { httpGetCashInflowTableData } from "../../requests/httpGetRequests";
import Loader from "../../components/shared-comp/Loader";
// import GenerateInvoiceModal from "./GenerateInvoiceModal";

const DropDownItems = [
  {
    title: "Active Inflows",
    fieldName: "active",
    value: true,
  },
  {
    title: "Past Inflows",
    fieldName: "active",
    value: false,
  },
];

export default function CashInflowsContent() {
  const [data, setData] = useState([]);
  const [cashInflowTableData, setCashInflowTableData] = useState([]);
  const [changedRows, setChangedRows] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isTablePutConfirmationModal, setTablePutConfirmationModal] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [putResponse, setPutResponse] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [isCustomSuccessModalOpen, setCustomSuccessModalOpen] = useState(false);
  const [showRowDeletionPrompt, setShowRowDeletionPrompt] = useState(false);
  const [rowToBeDeleted, setRowToBeDeleted] = useState({});
  const [filterKey, setFilterKey] = useState(null);


  const getCashInflowTableData = useMemo(() => async () => {
    try {
      setTableLoading(true)
      const fetchedCashInflowTableData = await httpGetCashInflowTableData();
      if (fetchedCashInflowTableData[1] === "success") {
        setData(fetchedCashInflowTableData[0]);
        setCashInflowTableData(fetchedCashInflowTableData[0]);
        setTableLoading(false)
      } else {
        setCustomModalMsg(fetchedCashInflowTableData[2]);
        setCustomModalOpen(true);
        setTableLoading(false)
      }
    } catch (error) {
      console.error(error);
      setCustomModalMsg("An error occurred while fetching data.");
      setCustomModalOpen(true);
      setTableLoading(false)
    }
  }, []);





  useEffect(() => {
    getCashInflowTableData()
  }, [getCashInflowTableData]);

  const handleSorts = (TBdata, columnName, order) => {
    //console.log(TBdata, columnName, order);
    const sortedData = DataSorterV1(TBdata, columnName, order);
    setData(sortedData);
  };

  useEffect(() => {
    if (filterKey) {
      const filteredData = dynamicFilter(cashInflowTableData, filterKey);
      setData(filteredData);
    } else {
      setData(cashInflowTableData);
    }
  }, [filterKey, cashInflowTableData]);

  const handleFilter = (columnName, value, reset) => {
    // console.log(columnName, value, reset)
    if (reset) {
      setFilterKey(null);
    } else setFilterKey({ ...filterKey, [columnName]: value });
  };

  const { getCashInflowsTablePutReqRes } = usePutData();

  const handleSaveChanges = () => {
    changedRows.map(async (item) => {
      try {
        setLoading(true);
        const response = await getCashInflowsTablePutReqRes(item);
        //console.log(response, "from put");
        setPutResponse((prevArray) => [...prevArray, response]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error, "An error occurred while saving changes. Please try again.");
        setCustomModalMsg("An error occurred while saving changes. Please try again.");
        setCustomModalOpen(true);
      }
    });
    setChangedRows([{}]);
    setTablePutConfirmationModal(true);
  };

  function closeModal() {
    setPutResponse([]);
    setModalOpen(false);
    setTablePutConfirmationModal(false);
    setCustomModalOpen(false);
  }

  //row deletion functions

  const requestDeleteRowData = async () => {
    console.log(rowToBeDeleted, "Confirmed");
    try {
      setLoading(true);
      const response = await httpDeleteCashInflowsTableRow(rowToBeDeleted);
      setLoading(false);
      if (response?.status === "success") {
        setCustomModalMsg(`The row has been deleted successfully`);
        setCustomSuccessModalOpen(true);
      } else {
        setCustomModalMsg(response?.res);
        setCustomModalOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setCustomModalMsg("An error occurred while saving changes. Please try again.");
      setCustomModalOpen(true);
    }
  };

  const handleDeleteRow = (rowData) => {
    setShowRowDeletionPrompt(true);
    setRowToBeDeleted(rowData);
  };

  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };

  function closeCustomSuccessModal() {
    setCustomSuccessModalOpen(false);
    refreshPage();
  }

  function closeRowDeletionModal() {
    setShowRowDeletionPrompt(false);
  }

  return (
    <div>
      <h1 className="text-2xl font-semibold text-slate-500">{data && data.length} Cash Inflows</h1>

      <div className="flex flex-col justify-between mt-5 lg:flex-row gap-3">
        <DropDown DropDownItems={DropDownItems} handleFilter={handleFilter} name={"Inflow Activity"} clearFilters={clearFilters} setClearFilters={setClearFilters} />

        <div className="mt-5 md:mt-0 w-full gap-3 xl:w-4/12 flex flex-col lg:flex-row justify-between lg:justify-between items-center">
          <SaveChangesButton changedRows={changedRows} loading={loading} handleSaveChanges={handleSaveChanges} />
          <div className="w-full" onClick={() => setModalOpen(true)}>
            <AddDataBtn targetId={""} name={"Add Cash Inflow"} />
          </div>
          <AddCashInflowModal isModalOpen={isModalOpen} closeModal={closeModal} />
          <TablePutConfirmationModal from={"CashInflowsContent"} putResponse={putResponse} isModalOpen={isTablePutConfirmationModal} closeModal={closeModal} />
          <CustomMessageModal title={""} icon={<GiTerror className="text-6xl text-indigo-700" />} msg={customModalMsg} isModalOpen={isCustomModalOpen} closeModal={closeModal} />
          <CustomMessageModal
            title={""}
            icon={<AiOutlineCheckCircle className="text-6xl text-green-700" />}
            msg={customModalMsg}
            isModalOpen={isCustomSuccessModalOpen}
            closeModal={closeCustomSuccessModal}
          />
          <RowDeletionPrompt isModalOpen={showRowDeletionPrompt} requestDeleteRowData={requestDeleteRowData} closeModal={closeRowDeletionModal} />
        </div>
      </div>

      <div className="mt-10">
       {!tableLoading? <Table
          from={"CashInflow"}
          handleDeleteRow={handleDeleteRow}
          data={data}
          handleSorts={handleSorts}
          setChangedRows={setChangedRows}
          clearFilters={clearFilters}
          setClearFilters={setClearFilters}
        /> : <Loader loadingText="Table Loading" />}
      </div>
    </div>
  );
}
