import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AiOutlineClose, AiOutlineCheckCircle } from "react-icons/ai";
import { GiTerror } from "react-icons/gi";
import { httpSubmitCashOutflowForm } from "../../../requests/httpPostRequests";
import CustomMessageModal from "../../../components/shared-comp/CustomMessageModal";
import usePutData from "../../../hooks/usePutData";
import SubmitFormBtn from "../../../components/shared-comp/SubmitFormBtn";
import OutflowFormTable from "./OutflowFormTable";
import { removeKeys } from "../../../lib/DataFormatorV1";

export default function AddCashOutflowModal({ data, tBillTableData }) {
  const navigate = useNavigate();
  const [defaultInfo, setDefaultInfo] = useState({});
  const [selectedTBill, setSelectedTBill] = useState([]);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [isCustomErrModalOpen, setCustomErrModalOpen] = useState(false);
  const [customModalMsg, setCustomModalMsg] = useState("");
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      setDefaultInfo(data);
      const filteredTBill = tBillTableData.filter((tBill) => tBill.index === data.index);
      setSelectedTBill(removeKeys(filteredTBill, ["id", "index"]));
    } else {
      const source_of_expense = "";
      const now = new Date();
      const month = now.getMonth() + 1; // add 1 to zero-based month index
      const year = now.getFullYear();
      const date_cleared = now.toISOString().substring(0, 10);
      setDefaultInfo({ source_of_expense, month, year, date_cleared });
    }
  }, [data, tBillTableData]);

  const { getCashOutflowsTablePutReqRes } = usePutData();

  async function handleOnSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    const date_cleared = data.get("date_cleared");
    const source = data.get("source_of_expense");
    const amount = data.get("amount");
    const month = data.get("month");
    const year = data.get("year");

    if (defaultInfo.source_of_expense !== "") {
      const data = {
        formData: { id: defaultInfo.id, source, amount, month, year, date_cleared },
        from: "modal",
      };
      try {
        setLoading(true);
        const response = await getCashOutflowsTablePutReqRes(data);
        setResponse(response);
        if (response[1] === "success") {
          setCustomModalOpen(true);
        } else {
          setCustomModalMsg(response?.[2]);
          setCustomErrModalOpen(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log(error, "An error has ocurred");
        setCustomModalMsg("An error occurred while saving changes. Please try again.");
        setCustomErrModalOpen(true);
      }
    } else {
      try {
        setLoading(true);
        const response = await httpSubmitCashOutflowForm({ source, amount, month, year, date_cleared });
        setResponse(response);
        if (response[1] === "success") {
          setCustomModalOpen(true);
        } else {
          setCustomModalMsg(response?.[2]);
          setCustomErrModalOpen(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // //console.log(error, "An error has ocurred");
        setCustomModalMsg("An error occurred while saving changes. Please try again.");
        setCustomErrModalOpen(true);
      }
    }
  }

  function restrictPreviousMonthDate() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().slice(0, 10);
    if (Object.keys(data).length !== 0) {
      return firstDayOfMonth;
    } else return null;
  }

  const refreshPage = () => {
    navigate(0);
  };

  function closeErrorModal() {
    setCustomErrModalOpen(false);
  }

  function closeSuccessModal() {
    setCustomModalOpen(false);
    refreshPage();
  }

  return (
    <div
      data-te-modal-init
      className={`fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
      id="addCashOutflowModalXl"
      tabIndex={-1}
      aria-labelledby="addCashOutflowModalXl"
      aria-modal="true"
      role="dialog"
      // ref={lgModalRef}
    >
      <div
        data-te-modal-dialog-ref
        className="flex items-center pointer-events-none relative h-[calc(100%-1rem)] w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
      >
        <form
          onSubmit={handleOnSubmit}
          className="pointer-events-auto relative flex max-h-[100%] overflow-hidden w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none "
        >
          <div className="shadow px-10 flex justify-between flex-shrink-0 items-center rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <h5 className="text-lg lg:text-xl font-bold leading-normal text-neutral-800" id="addCashOutflowModalXl">
              {Object.keys(data).length !== 0 ? "Cash Outflow (Clear payments)" : "Add Cash Outflow"}
            </h5>

            <button
              type="button"
              className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              data-te-modal-dismiss
              aria-label="Close"
            >
              <AiOutlineClose size={24} />
            </button>
          </div>
          <div className="relative overflow-y-auto p-4 px-10 pb-10">
            {Object.keys(data).length !== 0 && (
              <div className="pb-10 overflow-y-auto">
                <OutflowFormTable data={selectedTBill} />
              </div>
            )}
            <div className="flex flex-col gap-5 mt-1 lg:mt-14">
              {/* Row 1 */}
              {response?.[1] === "error" && response?.[0].response?.status === 500 && <p className="text-xs text-red-700 mt-1">500: Internal server Error!</p>}
              <div className="flex flex-col lg:flex-row gap-3 justify-between items-center">
                <div className="flex flex-col lg:w-4/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="source_of_expense">
                    Source
                  </label>
                  <input
                    className={`text-sm outline-none px-2 h-10 bg-slate-200 rounded-md ${Object.keys(data).length !== 0 && "pointer-events-none text-slate-500"}`}
                    type="text"
                    name="source_of_expense"
                    defaultValue={defaultInfo.source_of_expense}
                    placeholder="Enter the source of expense here"
                  />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response?.data?.source?.[0]}</p>}
                </div>

                <div className="flex flex-col lg:w-4/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="month">
                    Month
                  </label>
                  <input
                    className={`no_spinner text-sm outline-none px-2 h-10 bg-slate-200 rounded-md ${Object.keys(data).length !== 0 && "pointer-events-none text-slate-500"}`}
                    type="number"
                    name="month"
                    defaultValue={defaultInfo.month}
                    placeholder="Enter the month here"
                  />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response?.data?.month?.[0]}</p>}
                </div>

                <div className="flex flex-col lg:w-4/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="year">
                    Year
                  </label>
                  <input
                    className={`no_spinner text-sm outline-none px-2 h-10 bg-slate-200 rounded-md ${Object.keys(data).length !== 0 && "pointer-events-none text-slate-500"}`}
                    type="number"
                    name="year"
                    defaultValue={defaultInfo.year}
                    placeholder="Enter the year here"
                  />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response?.data?.year?.[0]}</p>}
                </div>
              </div>

              {/* 2nd row */}
              <div className="flex flex-col lg:flex-row gap-3 ">
                <div className="flex flex-col lg:w-4/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="amount">
                    Amount
                  </label>
                  <input className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md no_spinner" type="number" name="amount" defaultValue={0} placeholder="Enter the amount here" />
                  {defaultInfo.amount !== undefined && (
                    <p className="text-xs text-slate-400">
                      Bill for events: BDT {tBillTableData.find((item) => item.id === defaultInfo.id).bill_for_events} | Adjustments: BDT{" "}
                      {tBillTableData.find((item) => item.id === defaultInfo.id).adjustment} for | Total: BDT {tBillTableData.find((item) => item.id === defaultInfo.id).total_bill}
                    </p>
                  )}
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response?.data?.amount?.[0]}</p>}
                </div>
                <div className=" flex flex-col lg:w-4/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="date">
                    Date cleared
                  </label>
                  <input
                    className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md cursor-pointer"
                    type="date"
                    name="date_cleared"
                    defaultValue={new Date().toISOString().substring(0, 10)}
                    min={restrictPreviousMonthDate()}
                  />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">This field may not be blank</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="shadow flex flex-shrink-0 flex-wrap gap-3 items-center justify-end px-10 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <SubmitFormBtn loading={loading} name={"Save Cash Outflow"} />

            <CustomMessageModal
              title={"Confirmation"}
              icon={<AiOutlineCheckCircle className="text-6xl text-green-700" size={70} />}
              msg={"Form has been saved Successfully!"}
              isModalOpen={isCustomModalOpen}
              closeModal={closeSuccessModal}
            />
            <CustomMessageModal
              title={""}
              icon={<GiTerror className="text-6xl text-indigo-700" />}
              msg={customModalMsg}
              isModalOpen={isCustomErrModalOpen}
              closeModal={closeErrorModal}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
