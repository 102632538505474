import React, { useEffect } from "react";
import { AiOutlineClose, AiOutlineCheckCircle } from "react-icons/ai";
import { GiTerror } from "react-icons/gi";
import CoverageDropdown from "./CoverageDropdown";
import { useState } from "react";
import { httpGetCoverageChoices } from "../../../requests/httpGetRequests";
import { httpSubmitPackages } from "../../../requests/httpPostRequests";
import CustomMessageModal from "../../../components/shared-comp/CustomMessageModal";
import SubmitFormBtn from "../../../components/shared-comp/SubmitFormBtn";
import { useNavigate } from "react-router";


export default function AddPackageModal() {
  const navigate = useNavigate();
  const [coverage, setPackageCoverage] = useState("");
  const [coverageData, setCoverageData] = useState([]);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isErrModalOpen, setErrModalOpen] = useState(false);
  const [errModalMsg, setErrModalMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);


  async function getCoverageChoices() {
    try {
      const response = await httpGetCoverageChoices();
      setCoverageData(response);
    } catch (error) {
      console.log(error, "component error");
    }
  }

  useEffect(() => {
    getCoverageChoices()
  }, []);

  async function handleOnSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    const name = data.get("name");
    const details = data.get("details");
    const time = data.get("time");
    const num_cinematographer = data.get("num_cinematographer");
    const num_photographer = data.get("num_photographer");
    const budget = data.get("budget");
    const active = true;


    try {
      setLoading(true);
      const response = await httpSubmitPackages({ name, details, num_cinematographer, num_photographer, budget, time, coverage, active });
      setResponse(response);
      if (response[1] === "success") {
        setSuccessModalOpen(true);
      }
      else {
        setErrModalMsg(response[2])
        setErrModalOpen(true);
      }
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setErrModalMsg("An error occurred while submitting the form")
      setErrModalOpen(true);
    }
  }

  const refreshPage = () => {
    navigate(0);
  };

  function closeSuccessModal() {
    setSuccessModalOpen(false);
    refreshPage();
  }

  function closeErrModal() {
    setErrModalOpen(false);
  }



  return (
    <div
      data-te-modal-init
      className={`fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
      id="addPackageModalXl"
      tabIndex={-1}
      aria-labelledby="addPackageModalXl"
      aria-modal="true"
      role="dialog"
    // ref={lgModalRef}
    >
      <div
        data-te-modal-dialog-ref
        className="flex items-center pointer-events-none relative h-[calc(100%-1rem)] w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
      >
        <form onSubmit={handleOnSubmit} className="pointer-events-auto relative flex max-h-[100%] overflow-hidden w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none ">
          <div className="shadow px-10 flex justify-between flex-shrink-0 items-center rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <h5 className="text-lg lg:text-xl font-bold leading-normal text-neutral-800" id="addPackageModalXl">
              Add New Package
            </h5>

            <button
              type="button"
              className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              data-te-modal-dismiss
              aria-label="Close"
            >
              <AiOutlineClose size={24} />
            </button>
          </div>
          <div className="relative overflow-y-auto p-4 px-10 pb-10">
            <div className="flex flex-col gap-5">
              {/* Row 1 */}
              <div className="flex flex-col lg:flex-row gap-3 justify-between items-start">
                <div className="flex flex-col lg:w-3/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="name">
                    Name
                  </label>
                  <input className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md" type="text" name="name" placeholder="Enter the venue here" />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.name?.[0]}</p>}
                </div>

                <div className="flex flex-col lg:w-3/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="venue">
                    Details
                  </label>
                  <input className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md" type="text" name="details" defaultValue={"200 4R, 30 6L, 3 8R prints"} />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.details?.[0]}</p>}
                </div>

                <div className="flex flex-col lg:w-3/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="coverage">
                    Coverage
                  </label>
                  <CoverageDropdown listData={coverageData} multiple={false} setPackageCoverage={setPackageCoverage} />
                </div>

                <div className="flex flex-col lg:w-3/12 w-full ">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="time">
                    Time
                  </label>
                  <input className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md no_spinner" type="number" name="time" defaultValue={5} />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.time?.[0]}</p>}
                </div>
              </div>

              {/* 2nd row */}
              <div className="flex flex-col lg:flex-row gap-3 justify-between items-start">
                <div className="flex flex-col lg:w-3/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="num_cinematographer">
                    Num cinematographer
                  </label>
                  <input className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md no_spinner" type="number" name="num_cinematographer" defaultValue={0} />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.num_cinematographer?.[0]}</p>}
                </div>

                <div className="flex flex-col lg:w-3/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="num_photographer">
                    Num photographer
                  </label>
                  <input className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md no_spinner" type="number" name="num_photographer" defaultValue={0} />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.num_photographer?.[0]}</p>}
                </div>

                <div className="flex flex-col lg:w-3/12 w-full">
                  <label className="text-sm text-slate-700 font-semibold leading-8" htmlFor="budget">
                    Budget
                  </label>
                  <input className="text-sm outline-none px-2 h-10 bg-slate-200 rounded-md no_spinner" type="number" name="budget" defaultValue={0} />
                  {response?.[1] === "error" && <p className="text-xs text-red-700 mt-1">{response?.[0].response.data.budget?.[0]}</p>}
                </div>

                <div className="flex gap-2 justify-center mt-6 items-center lg:w-3/12 w-full">

                </div>
              </div>
            </div>
          </div>
          <div className="shadow flex flex-shrink-0 flex-wrap gap-3 items-center justify-end px-10 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <SubmitFormBtn loading={loading} name={"Save Package"} />
            <CustomMessageModal
              title={"Confirmation"}
              icon={<AiOutlineCheckCircle className="text-6xl text-green-700" size={70} />}
              msg={"Form has been saved Successfully!"}
              isModalOpen={isSuccessModalOpen}
              closeModal={closeSuccessModal}
            />
            <CustomMessageModal
              title={""}
              icon={<GiTerror className="text-6xl text-indigo-700" />}
              msg={errModalMsg}
              isModalOpen={isErrModalOpen}
              closeModal={closeErrModal}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
