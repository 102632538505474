import axios from "axios";
import { getErrorMessage } from "../lib/ErrorHandler";

const API_URL = process.env.REACT_APP_API_URL;
async function httpGetClientsTableData() {
  try {
    const response = await axios.get(`${API_URL}/clients`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpGetEventsTableData() {
  try {
    const response = await axios.get(`${API_URL}/events`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpGetEventsData() {
  try {
    const response = await axios.get(`${API_URL}/events/`, { withCredentials: true });
    return await response.data;
  } catch (error) {
    console.error(error);
    return [error, "error"]
  }
}

async function httpGetCashInflowTableData() {
  try {
    const response = await axios.get(`${API_URL}/cashinflows`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpGetCashOutflowTableData() {
  try {
    const response = await axios.get(`${API_URL}/cashoutflows/`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpGetHistoricalBillTableData() {
  try {
    const response = await axios.get(`${API_URL}/bill-tableview`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpGetLedgersTableData() {
  try {
    const response = await axios.get(`${API_URL}/ledger-tableview`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpGetTeamBillsTableData() {
  try {
    const response = await axios.get(`${API_URL}/teammemberbills/`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpGetTeamMembersTableData() {
  try {
    const response = await axios.get(`${API_URL}/teammembers/`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}


async function httpGetDropLists() {
  try {
    const response = await axios.get(`${API_URL}/cea-registration`, { withCredentials: true });
    return await response.data;
  } catch (error) {
    console.error(error);
  }
}

async function httpGetCoverageChoices() {
  try {
    const response = await axios.get(`${API_URL}/packages/coverage_choices/`, { withCredentials: true });
    return await response.data;
  } catch (error) {
    console.log(error,"hhhh")
  }
}


async function httpGetAddOns() {
  try {
    const response = await axios.get(`${API_URL}/add-ons/`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}


async function httpGetEventPackages() {
  try {
    const response = await axios.get(`${API_URL}/package-tableview`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

async function httpGetClientPendingPayment() {
  try {
    const response = await axios.get(`${API_URL}/client-pending-payment`, { withCredentials: true });
    return [response.data, "success"];
  } catch (error) {
    const errorData = getErrorMessage(error);
    return errorData;
  }
}

export {
  httpGetClientsTableData,
  httpGetEventsTableData,
  httpGetDropLists,
  httpGetCoverageChoices,
  httpGetAddOns,
  httpGetEventPackages,
  httpGetCashInflowTableData,
  httpGetClientPendingPayment,
  httpGetHistoricalBillTableData,
  httpGetLedgersTableData,
  httpGetCashOutflowTableData,
  httpGetTeamBillsTableData,
  httpGetTeamMembersTableData,
  httpGetEventsData
};
