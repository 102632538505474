import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";

export default function SuccessContent({ message }) {
  return (
    <div className="flex flex-col justify-center items-center mt-2 gap-4">
      <AiOutlineCheckCircle className="text-green-500" size={70} />
      <p className="text-sm text-gray-500">{message}</p>
    </div>
  );
}
