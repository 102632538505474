import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiCheck, HiChevronDown } from "react-icons/hi";

export default function CoverageDropdown({ listData, multiple, setPackageCoverage }) {
  const [selected, setSelected] = useState(multiple ? [] : "");

  function handleMultipleItems(items) {
    setSelected(items);
    setPackageCoverage(items.value);
  }


  return (
    <div>
      <Listbox value={selected} onChange={handleMultipleItems} multiple={multiple}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className={`block break-all ${selected.length === 0 && "text-semibold text-slate-400"}`}>
              <p className="text-slate-500">{Object.keys(selected).length > 0 ? selected.label : "choose here"}</p>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <HiChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className="absolute mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              style={{ zIndex: "1100" }}
            >
              {listData?.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-slate-600 text-white" : "text-gray-900"}`}
                  value={item}
                  // onClick={() => handleMultipleItems(item, formType)}
                >
                  {({ selected }) => (
                    <div>
                      <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{item.label}</span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <HiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
