import React from "react";
import SidebarLayout from "../../layouts/SidebarLayout";
import TabBarLayout from "../../layouts/TabBarLayout";
import LedgersContent from "../../features/income-statements/LedgersContent";


export default function LedgerPage() {
    return (
      <SidebarLayout>
        <TabBarLayout pathRoot={'/income-statements'} pathNames={['Ledgers','Historical Bills']} paths={['/ledgers','/historical-bills']}>
        <LedgersContent />
        </TabBarLayout>
      </SidebarLayout>
    );
  }
